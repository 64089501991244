export const state = () => ({
  applicants: [],
  total_pages: 0,
  total_count: 0,
  current_page: 1,
})

let searchCancelTokenSource = null

export const actions = {
  async fetchAll({ commit }, options = {}) {
    if (searchCancelTokenSource) {
      searchCancelTokenSource?.cancel()
    }

    searchCancelTokenSource = this.$axios.CancelToken.source()
    const { search = '' } = options
    let { page = 1 } = options
    if (!page) {
      page = 1
    }

    if (page < 1) {
      page = 1
    }

    const params = new URLSearchParams({
      page,
      search,
    }).toString()

    const { data } = await this.$axios.get(`/admin/applicants?${params}`, {
      cancelToken: searchCancelTokenSource.token,
    })
    commit('setApplicants', data.data)
    commit('setTotalPages', data.meta.last_page)
    commit('setTotalCount', data.meta.total)
    commit('setCurrentPage', data.meta.current_page)
  },
}

export const mutations = {
  setApplicants(state, applicants) {
    state.applicants = applicants
  },

  setTotalPages(state, totalPages) {
    state.total_pages = totalPages
  },

  setTotalCount(state, totalCount) {
    state.total_count = totalCount
  },

  setCurrentPage(state, currentPage) {
    state.current_page = currentPage
  },
}

export const getters = {}
