export const Accordion = () => import('../../components/componentloader/elements/Accordion.vue' /* webpackChunkName: "components/accordion" */).then(c => wrapFunctional(c.default || c))
export const Document = () => import('../../components/componentloader/elements/Document.vue' /* webpackChunkName: "components/document" */).then(c => wrapFunctional(c.default || c))
export const DonationOption = () => import('../../components/componentloader/elements/DonationOption.vue' /* webpackChunkName: "components/donation-option" */).then(c => wrapFunctional(c.default || c))
export const LargeArticle = () => import('../../components/componentloader/elements/LargeArticle.vue' /* webpackChunkName: "components/large-article" */).then(c => wrapFunctional(c.default || c))
export const LargeArticleLongText = () => import('../../components/componentloader/elements/LargeArticleLongText.vue' /* webpackChunkName: "components/large-article-long-text" */).then(c => wrapFunctional(c.default || c))
export const LargePerson = () => import('../../components/componentloader/elements/LargePerson.vue' /* webpackChunkName: "components/large-person" */).then(c => wrapFunctional(c.default || c))
export const MediumArticle = () => import('../../components/componentloader/elements/MediumArticle.vue' /* webpackChunkName: "components/medium-article" */).then(c => wrapFunctional(c.default || c))
export const Name = () => import('../../components/componentloader/elements/Name.vue' /* webpackChunkName: "components/name" */).then(c => wrapFunctional(c.default || c))
export const PersonCard = () => import('../../components/componentloader/elements/PersonCard.vue' /* webpackChunkName: "components/person-card" */).then(c => wrapFunctional(c.default || c))
export const PersonDetailInfos = () => import('../../components/componentloader/elements/PersonDetailInfos.vue' /* webpackChunkName: "components/person-detail-infos" */).then(c => wrapFunctional(c.default || c))
export const PodcastCard = () => import('../../components/componentloader/elements/PodcastCard.vue' /* webpackChunkName: "components/podcast-card" */).then(c => wrapFunctional(c.default || c))
export const Quote = () => import('../../components/componentloader/elements/Quote.vue' /* webpackChunkName: "components/quote" */).then(c => wrapFunctional(c.default || c))
export const SmallArticle = () => import('../../components/componentloader/elements/SmallArticle.vue' /* webpackChunkName: "components/small-article" */).then(c => wrapFunctional(c.default || c))
export const TeaserCard = () => import('../../components/componentloader/elements/TeaserCard.vue' /* webpackChunkName: "components/teaser-card" */).then(c => wrapFunctional(c.default || c))
export const BaseInputField = () => import('../../components/ui/forms/BaseInputField.vue' /* webpackChunkName: "components/base-input-field" */).then(c => wrapFunctional(c.default || c))
export const CheckBoxGroup = () => import('../../components/ui/forms/CheckBoxGroup.vue' /* webpackChunkName: "components/check-box-group" */).then(c => wrapFunctional(c.default || c))
export const Checkbox = () => import('../../components/ui/forms/Checkbox.vue' /* webpackChunkName: "components/checkbox" */).then(c => wrapFunctional(c.default || c))
export const FileSelectField = () => import('../../components/ui/forms/FileSelectField.vue' /* webpackChunkName: "components/file-select-field" */).then(c => wrapFunctional(c.default || c))
export const RadioGroup = () => import('../../components/ui/forms/RadioGroup.vue' /* webpackChunkName: "components/radio-group" */).then(c => wrapFunctional(c.default || c))
export const SectionTitle = () => import('../../components/ui/forms/SectionTitle.vue' /* webpackChunkName: "components/section-title" */).then(c => wrapFunctional(c.default || c))
export const SelectField = () => import('../../components/ui/forms/SelectField.vue' /* webpackChunkName: "components/select-field" */).then(c => wrapFunctional(c.default || c))
export const Separator = () => import('../../components/ui/forms/Separator.vue' /* webpackChunkName: "components/separator" */).then(c => wrapFunctional(c.default || c))
export const TextArea = () => import('../../components/ui/forms/TextArea.vue' /* webpackChunkName: "components/text-area" */).then(c => wrapFunctional(c.default || c))
export const TextField = () => import('../../components/ui/forms/TextField.vue' /* webpackChunkName: "components/text-field" */).then(c => wrapFunctional(c.default || c))
export const ApplicationBudgetInputGroup = () => import('../../components/ui/forms/application/BudgetInputGroup.vue' /* webpackChunkName: "components/application-budget-input-group" */).then(c => wrapFunctional(c.default || c))
export const ApplicationCheckbox = () => import('../../components/ui/forms/application/Checkbox.vue' /* webpackChunkName: "components/application-checkbox" */).then(c => wrapFunctional(c.default || c))
export const ApplicationCheckboxGroup = () => import('../../components/ui/forms/application/CheckboxGroup.vue' /* webpackChunkName: "components/application-checkbox-group" */).then(c => wrapFunctional(c.default || c))
export const ApplicationContactInputGroup = () => import('../../components/ui/forms/application/ContactInputGroup.vue' /* webpackChunkName: "components/application-contact-input-group" */).then(c => wrapFunctional(c.default || c))
export const ApplicationDateInput = () => import('../../components/ui/forms/application/DateInput.vue' /* webpackChunkName: "components/application-date-input" */).then(c => wrapFunctional(c.default || c))
export const ApplicationDateRangeInput = () => import('../../components/ui/forms/application/DateRangeInput.vue' /* webpackChunkName: "components/application-date-range-input" */).then(c => wrapFunctional(c.default || c))
export const ApplicationFileSelectField = () => import('../../components/ui/forms/application/FileSelectField.vue' /* webpackChunkName: "components/application-file-select-field" */).then(c => wrapFunctional(c.default || c))
export const ApplicationRadio = () => import('../../components/ui/forms/application/Radio.vue' /* webpackChunkName: "components/application-radio" */).then(c => wrapFunctional(c.default || c))
export const ApplicationRadioGroup = () => import('../../components/ui/forms/application/RadioGroup.vue' /* webpackChunkName: "components/application-radio-group" */).then(c => wrapFunctional(c.default || c))
export const ApplicationSelectField = () => import('../../components/ui/forms/application/SelectField.vue' /* webpackChunkName: "components/application-select-field" */).then(c => wrapFunctional(c.default || c))
export const ApplicationTextField = () => import('../../components/ui/forms/application/TextField.vue' /* webpackChunkName: "components/application-text-field" */).then(c => wrapFunctional(c.default || c))
export const ApplicationTextareaField = () => import('../../components/ui/forms/application/TextareaField.vue' /* webpackChunkName: "components/application-textarea-field" */).then(c => wrapFunctional(c.default || c))
export const ApplicationToggleSelect = () => import('../../components/ui/forms/application/ToggleSelect.vue' /* webpackChunkName: "components/application-toggle-select" */).then(c => wrapFunctional(c.default || c))
export const ApplicationToggleSelectWithLabel = () => import('../../components/ui/forms/application/ToggleSelectWithLabel.vue' /* webpackChunkName: "components/application-toggle-select-with-label" */).then(c => wrapFunctional(c.default || c))
export const ApplicationBaseInputField = () => import('../../components/ui/forms/application/_BaseInputField.vue' /* webpackChunkName: "components/application-base-input-field" */).then(c => wrapFunctional(c.default || c))
export const CookieBanner = () => import('../../components/ui/cookie/CookieBanner.vue' /* webpackChunkName: "components/cookie-banner" */).then(c => wrapFunctional(c.default || c))
export const CookieBannerLoader = () => import('../../components/ui/cookie/CookieBannerLoader.vue' /* webpackChunkName: "components/cookie-banner-loader" */).then(c => wrapFunctional(c.default || c))
export const SimpleCookieBanner = () => import('../../components/ui/cookie/SimpleCookieBanner.vue' /* webpackChunkName: "components/simple-cookie-banner" */).then(c => wrapFunctional(c.default || c))
export const SimpleCookieBannerLoader = () => import('../../components/ui/cookie/SimpleCookieBannerLoader.vue' /* webpackChunkName: "components/simple-cookie-banner-loader" */).then(c => wrapFunctional(c.default || c))
export const AccordionContainer = () => import('../../components/componentloader/AccordionContainer.vue' /* webpackChunkName: "components/accordion-container" */).then(c => wrapFunctional(c.default || c))
export const BubblesText = () => import('../../components/componentloader/BubblesText.vue' /* webpackChunkName: "components/bubbles-text" */).then(c => wrapFunctional(c.default || c))
export const ComponentLoader = () => import('../../components/componentloader/ComponentLoader.vue' /* webpackChunkName: "components/component-loader" */).then(c => wrapFunctional(c.default || c))
export const ContentImage = () => import('../../components/componentloader/ContentImage.vue' /* webpackChunkName: "components/content-image" */).then(c => wrapFunctional(c.default || c))
export const ContentVideo = () => import('../../components/componentloader/ContentVideo.vue' /* webpackChunkName: "components/content-video" */).then(c => wrapFunctional(c.default || c))
export const DocumentStorage = () => import('../../components/componentloader/DocumentStorage.vue' /* webpackChunkName: "components/document-storage" */).then(c => wrapFunctional(c.default || c))
export const DonationBannerSimple = () => import('../../components/componentloader/DonationBannerSimple.vue' /* webpackChunkName: "components/donation-banner-simple" */).then(c => wrapFunctional(c.default || c))
export const DonationOptions = () => import('../../components/componentloader/DonationOptions.vue' /* webpackChunkName: "components/donation-options" */).then(c => wrapFunctional(c.default || c))
export const DonationStoryForm = () => import('../../components/componentloader/DonationStoryForm.vue' /* webpackChunkName: "components/donation-story-form" */).then(c => wrapFunctional(c.default || c))
export const DownloadBtn = () => import('../../components/componentloader/DownloadBtn.vue' /* webpackChunkName: "components/download-btn" */).then(c => wrapFunctional(c.default || c))
export const GenericForm = () => import('../../components/componentloader/GenericForm.vue' /* webpackChunkName: "components/generic-form" */).then(c => wrapFunctional(c.default || c))
export const GiftDonationForm = () => import('../../components/componentloader/GiftDonationForm.vue' /* webpackChunkName: "components/gift-donation-form" */).then(c => wrapFunctional(c.default || c))
export const GoogleMapsCard = () => import('../../components/componentloader/GoogleMapsCard.vue' /* webpackChunkName: "components/google-maps-card" */).then(c => wrapFunctional(c.default || c))
export const HtmlText = () => import('../../components/componentloader/HtmlText.vue' /* webpackChunkName: "components/html-text" */).then(c => wrapFunctional(c.default || c))
export const IconLinkCards = () => import('../../components/componentloader/IconLinkCards.vue' /* webpackChunkName: "components/icon-link-cards" */).then(c => wrapFunctional(c.default || c))
export const ImageContribution = () => import('../../components/componentloader/ImageContribution.vue' /* webpackChunkName: "components/image-contribution" */).then(c => wrapFunctional(c.default || c))
export const ImageModal = () => import('../../components/componentloader/ImageModal.vue' /* webpackChunkName: "components/image-modal" */).then(c => wrapFunctional(c.default || c))
export const ImageSlider = () => import('../../components/componentloader/ImageSlider.vue' /* webpackChunkName: "components/image-slider" */).then(c => wrapFunctional(c.default || c))
export const ImageText = () => import('../../components/componentloader/ImageText.vue' /* webpackChunkName: "components/image-text" */).then(c => wrapFunctional(c.default || c))
export const KeyFacts = () => import('../../components/componentloader/KeyFacts.vue' /* webpackChunkName: "components/key-facts" */).then(c => wrapFunctional(c.default || c))
export const LargeArticles = () => import('../../components/componentloader/LargeArticles.vue' /* webpackChunkName: "components/large-articles" */).then(c => wrapFunctional(c.default || c))
export const LargePersons = () => import('../../components/componentloader/LargePersons.vue' /* webpackChunkName: "components/large-persons" */).then(c => wrapFunctional(c.default || c))
export const LinkCard = () => import('../../components/componentloader/LinkCard.vue' /* webpackChunkName: "components/link-card" */).then(c => wrapFunctional(c.default || c))
export const MediaContributions = () => import('../../components/componentloader/MediaContributions.vue' /* webpackChunkName: "components/media-contributions" */).then(c => wrapFunctional(c.default || c))
export const MediumArticles = () => import('../../components/componentloader/MediumArticles.vue' /* webpackChunkName: "components/medium-articles" */).then(c => wrapFunctional(c.default || c))
export const NewsletterRegistration = () => import('../../components/componentloader/NewsletterRegistration.vue' /* webpackChunkName: "components/newsletter-registration" */).then(c => wrapFunctional(c.default || c))
export const PersonInfo = () => import('../../components/componentloader/PersonInfo.vue' /* webpackChunkName: "components/person-info" */).then(c => wrapFunctional(c.default || c))
export const Persons = () => import('../../components/componentloader/Persons.vue' /* webpackChunkName: "components/persons" */).then(c => wrapFunctional(c.default || c))
export const PodcastBanner = () => import('../../components/componentloader/PodcastBanner.vue' /* webpackChunkName: "components/podcast-banner" */).then(c => wrapFunctional(c.default || c))
export const QuoteBlock = () => import('../../components/componentloader/QuoteBlock.vue' /* webpackChunkName: "components/quote-block" */).then(c => wrapFunctional(c.default || c))
export const RaisenowForm = () => import('../../components/componentloader/RaisenowForm.vue' /* webpackChunkName: "components/raisenow-form" */).then(c => wrapFunctional(c.default || c))
export const Resources = () => import('../../components/componentloader/Resources.vue' /* webpackChunkName: "components/resources" */).then(c => wrapFunctional(c.default || c))
export const SimplePeople = () => import('../../components/componentloader/SimplePeople.vue' /* webpackChunkName: "components/simple-people" */).then(c => wrapFunctional(c.default || c))
export const SimplePerson = () => import('../../components/componentloader/SimplePerson.vue' /* webpackChunkName: "components/simple-person" */).then(c => wrapFunctional(c.default || c))
export const SmallArticles = () => import('../../components/componentloader/SmallArticles.vue' /* webpackChunkName: "components/small-articles" */).then(c => wrapFunctional(c.default || c))
export const StickyBar = () => import('../../components/componentloader/StickyBar.vue' /* webpackChunkName: "components/sticky-bar" */).then(c => wrapFunctional(c.default || c))
export const Teasers = () => import('../../components/componentloader/Teasers.vue' /* webpackChunkName: "components/teasers" */).then(c => wrapFunctional(c.default || c))
export const TextEditor = () => import('../../components/componentloader/TextEditor.vue' /* webpackChunkName: "components/text-editor" */).then(c => wrapFunctional(c.default || c))
export const ThreeToOneContent = () => import('../../components/componentloader/ThreeToOneContent.vue' /* webpackChunkName: "components/three-to-one-content" */).then(c => wrapFunctional(c.default || c))
export const VideoContribution = () => import('../../components/componentloader/VideoContribution.vue' /* webpackChunkName: "components/video-contribution" */).then(c => wrapFunctional(c.default || c))
export const YoutubeModal = () => import('../../components/componentloader/YoutubeModal.vue' /* webpackChunkName: "components/youtube-modal" */).then(c => wrapFunctional(c.default || c))
export const AccordionAnimation = () => import('../../components/ui/AccordionAnimation.vue' /* webpackChunkName: "components/accordion-animation" */).then(c => wrapFunctional(c.default || c))
export const AppLayoutBase = () => import('../../components/ui/AppLayoutBase.vue' /* webpackChunkName: "components/app-layout-base" */).then(c => wrapFunctional(c.default || c))
export const AppLink = () => import('../../components/ui/AppLink.vue' /* webpackChunkName: "components/app-link" */).then(c => wrapFunctional(c.default || c))
export const ApplicationButton = () => import('../../components/ui/ApplicationButton.vue' /* webpackChunkName: "components/application-button" */).then(c => wrapFunctional(c.default || c))
export const ArrowLink = () => import('../../components/ui/ArrowLink.vue' /* webpackChunkName: "components/arrow-link" */).then(c => wrapFunctional(c.default || c))
export const BannerWithImage = () => import('../../components/ui/BannerWithImage.vue' /* webpackChunkName: "components/banner-with-image" */).then(c => wrapFunctional(c.default || c))
export const BreadCrumbs = () => import('../../components/ui/BreadCrumbs.vue' /* webpackChunkName: "components/bread-crumbs" */).then(c => wrapFunctional(c.default || c))
export const Container = () => import('../../components/ui/Container.vue' /* webpackChunkName: "components/container" */).then(c => wrapFunctional(c.default || c))
export const FormBox = () => import('../../components/ui/FormBox.vue' /* webpackChunkName: "components/form-box" */).then(c => wrapFunctional(c.default || c))
export const FormStepper = () => import('../../components/ui/FormStepper.vue' /* webpackChunkName: "components/form-stepper" */).then(c => wrapFunctional(c.default || c))
export const GreenDots = () => import('../../components/ui/GreenDots.vue' /* webpackChunkName: "components/green-dots" */).then(c => wrapFunctional(c.default || c))
export const Loading = () => import('../../components/ui/Loading.vue' /* webpackChunkName: "components/loading" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../components/ui/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const LogoButton = () => import('../../components/ui/LogoButton.vue' /* webpackChunkName: "components/logo-button" */).then(c => wrapFunctional(c.default || c))
export const PlusButton = () => import('../../components/ui/PlusButton.vue' /* webpackChunkName: "components/plus-button" */).then(c => wrapFunctional(c.default || c))
export const SocialMediaButtons = () => import('../../components/ui/SocialMediaButtons.vue' /* webpackChunkName: "components/social-media-buttons" */).then(c => wrapFunctional(c.default || c))
export const VideoPlayer = () => import('../../components/ui/VideoPlayer.vue' /* webpackChunkName: "components/video-player" */).then(c => wrapFunctional(c.default || c))
export const ModalBackdrop = () => import('../../components/ui/modal/ModalBackdrop.vue' /* webpackChunkName: "components/modal-backdrop" */).then(c => wrapFunctional(c.default || c))
export const ModalLayout = () => import('../../components/ui/modal/ModalLayout.vue' /* webpackChunkName: "components/modal-layout" */).then(c => wrapFunctional(c.default || c))
export const NewsletterPopup = () => import('../../components/ui/newsletter-popup/NewsletterPopup.vue' /* webpackChunkName: "components/newsletter-popup" */).then(c => wrapFunctional(c.default || c))
export const BurgerButton = () => import('../../components/navigation/BurgerButton.vue' /* webpackChunkName: "components/burger-button" */).then(c => wrapFunctional(c.default || c))
export const DashboardNavigation = () => import('../../components/navigation/DashboardNavigation.vue' /* webpackChunkName: "components/dashboard-navigation" */).then(c => wrapFunctional(c.default || c))
export const MobileNavBar = () => import('../../components/navigation/MobileNavBar.vue' /* webpackChunkName: "components/mobile-nav-bar" */).then(c => wrapFunctional(c.default || c))
export const NavBar = () => import('../../components/navigation/NavBar.vue' /* webpackChunkName: "components/nav-bar" */).then(c => wrapFunctional(c.default || c))
export const QuickLinks = () => import('../../components/navigation/QuickLinks.vue' /* webpackChunkName: "components/quick-links" */).then(c => wrapFunctional(c.default || c))
export const SiteNavigation = () => import('../../components/navigation/SiteNavigation.vue' /* webpackChunkName: "components/site-navigation" */).then(c => wrapFunctional(c.default || c))
export const ArrowLong = () => import('../../components/icons/ArrowLong.vue' /* webpackChunkName: "components/arrow-long" */).then(c => wrapFunctional(c.default || c))
export const Ban = () => import('../../components/icons/Ban.vue' /* webpackChunkName: "components/ban" */).then(c => wrapFunctional(c.default || c))
export const Camera = () => import('../../components/icons/Camera.vue' /* webpackChunkName: "components/camera" */).then(c => wrapFunctional(c.default || c))
export const CaretDown = () => import('../../components/icons/CaretDown.vue' /* webpackChunkName: "components/caret-down" */).then(c => wrapFunctional(c.default || c))
export const Check = () => import('../../components/icons/Check.vue' /* webpackChunkName: "components/check" */).then(c => wrapFunctional(c.default || c))
export const Close = () => import('../../components/icons/Close.vue' /* webpackChunkName: "components/close" */).then(c => wrapFunctional(c.default || c))
export const Diagonal = () => import('../../components/icons/Diagonal.vue' /* webpackChunkName: "components/diagonal" */).then(c => wrapFunctional(c.default || c))
export const Donation = () => import('../../components/icons/Donation.vue' /* webpackChunkName: "components/donation" */).then(c => wrapFunctional(c.default || c))
export const Download = () => import('../../components/icons/Download.vue' /* webpackChunkName: "components/download" */).then(c => wrapFunctional(c.default || c))
export const DownloadIcon = () => import('../../components/icons/DownloadIcon.vue' /* webpackChunkName: "components/download-icon" */).then(c => wrapFunctional(c.default || c))
export const Facebook = () => import('../../components/icons/Facebook.vue' /* webpackChunkName: "components/facebook" */).then(c => wrapFunctional(c.default || c))
export const FacebookRound = () => import('../../components/icons/FacebookRound.vue' /* webpackChunkName: "components/facebook-round" */).then(c => wrapFunctional(c.default || c))
export const Info = () => import('../../components/icons/Info.vue' /* webpackChunkName: "components/info" */).then(c => wrapFunctional(c.default || c))
export const InformationCircle = () => import('../../components/icons/InformationCircle.vue' /* webpackChunkName: "components/information-circle" */).then(c => wrapFunctional(c.default || c))
export const Instagram = () => import('../../components/icons/Instagram.vue' /* webpackChunkName: "components/instagram" */).then(c => wrapFunctional(c.default || c))
export const Like = () => import('../../components/icons/Like.vue' /* webpackChunkName: "components/like" */).then(c => wrapFunctional(c.default || c))
export const LinkIcon = () => import('../../components/icons/LinkIcon.vue' /* webpackChunkName: "components/link-icon" */).then(c => wrapFunctional(c.default || c))
export const LinkedinRound = () => import('../../components/icons/LinkedinRound.vue' /* webpackChunkName: "components/linkedin-round" */).then(c => wrapFunctional(c.default || c))
export const Mail = () => import('../../components/icons/Mail.vue' /* webpackChunkName: "components/mail" */).then(c => wrapFunctional(c.default || c))
export const Minus = () => import('../../components/icons/Minus.vue' /* webpackChunkName: "components/minus" */).then(c => wrapFunctional(c.default || c))
export const PaperPlane = () => import('../../components/icons/PaperPlane.vue' /* webpackChunkName: "components/paper-plane" */).then(c => wrapFunctional(c.default || c))
export const Pause = () => import('../../components/icons/Pause.vue' /* webpackChunkName: "components/pause" */).then(c => wrapFunctional(c.default || c))
export const Play = () => import('../../components/icons/Play.vue' /* webpackChunkName: "components/play" */).then(c => wrapFunctional(c.default || c))
export const Plus = () => import('../../components/icons/Plus.vue' /* webpackChunkName: "components/plus" */).then(c => wrapFunctional(c.default || c))
export const Redo = () => import('../../components/icons/Redo.vue' /* webpackChunkName: "components/redo" */).then(c => wrapFunctional(c.default || c))
export const Reject = () => import('../../components/icons/Reject.vue' /* webpackChunkName: "components/reject" */).then(c => wrapFunctional(c.default || c))
export const Search = () => import('../../components/icons/Search.vue' /* webpackChunkName: "components/search" */).then(c => wrapFunctional(c.default || c))
export const Spot = () => import('../../components/icons/Spot.vue' /* webpackChunkName: "components/spot" */).then(c => wrapFunctional(c.default || c))
export const SrfLogo = () => import('../../components/icons/SrfLogo.vue' /* webpackChunkName: "components/srf-logo" */).then(c => wrapFunctional(c.default || c))
export const TagIcon = () => import('../../components/icons/TagIcon.vue' /* webpackChunkName: "components/tag-icon" */).then(c => wrapFunctional(c.default || c))
export const Tick = () => import('../../components/icons/Tick.vue' /* webpackChunkName: "components/tick" */).then(c => wrapFunctional(c.default || c))
export const Times = () => import('../../components/icons/Times.vue' /* webpackChunkName: "components/times" */).then(c => wrapFunctional(c.default || c))
export const Triangle = () => import('../../components/icons/Triangle.vue' /* webpackChunkName: "components/triangle" */).then(c => wrapFunctional(c.default || c))
export const Twint = () => import('../../components/icons/Twint.vue' /* webpackChunkName: "components/twint" */).then(c => wrapFunctional(c.default || c))
export const Twitter = () => import('../../components/icons/Twitter.vue' /* webpackChunkName: "components/twitter" */).then(c => wrapFunctional(c.default || c))
export const Upload = () => import('../../components/icons/Upload.vue' /* webpackChunkName: "components/upload" */).then(c => wrapFunctional(c.default || c))
export const VinylAnim = () => import('../../components/icons/VinylAnim.vue' /* webpackChunkName: "components/vinyl-anim" */).then(c => wrapFunctional(c.default || c))
export const Wave = () => import('../../components/icons/Wave.vue' /* webpackChunkName: "components/wave" */).then(c => wrapFunctional(c.default || c))
export const Whatsapp = () => import('../../components/icons/Whatsapp.vue' /* webpackChunkName: "components/whatsapp" */).then(c => wrapFunctional(c.default || c))
export const DonationStoriesPage = () => import('../../components/staticpages/DonationStoriesPage.vue' /* webpackChunkName: "components/donation-stories-page" */).then(c => wrapFunctional(c.default || c))
export const JournalPage = () => import('../../components/staticpages/JournalPage.vue' /* webpackChunkName: "components/journal-page" */).then(c => wrapFunctional(c.default || c))
export const LandingPage = () => import('../../components/staticpages/LandingPage.vue' /* webpackChunkName: "components/landing-page" */).then(c => wrapFunctional(c.default || c))
export const MediasPage = () => import('../../components/staticpages/MediasPage.vue' /* webpackChunkName: "components/medias-page" */).then(c => wrapFunctional(c.default || c))
export const PodcastsPage = () => import('../../components/staticpages/PodcastsPage.vue' /* webpackChunkName: "components/podcasts-page" */).then(c => wrapFunctional(c.default || c))
export const Weihnachtsaktion = () => import('../../components/staticpages/Weihnachtsaktion.vue' /* webpackChunkName: "components/weihnachtsaktion" */).then(c => wrapFunctional(c.default || c))
export const WeihnachtsaktionCompany = () => import('../../components/staticpages/WeihnachtsaktionCompany.vue' /* webpackChunkName: "components/weihnachtsaktion-company" */).then(c => wrapFunctional(c.default || c))
export const WeihnachtsaktionInfo = () => import('../../components/staticpages/WeihnachtsaktionInfo.vue' /* webpackChunkName: "components/weihnachtsaktion-info" */).then(c => wrapFunctional(c.default || c))
export const AuthorWithDate = () => import('../../components/journal/AuthorWithDate.vue' /* webpackChunkName: "components/author-with-date" */).then(c => wrapFunctional(c.default || c))
export const FilterButton = () => import('../../components/journal/FilterButton.vue' /* webpackChunkName: "components/filter-button" */).then(c => wrapFunctional(c.default || c))
export const JournalArticle = () => import('../../components/journal/JournalArticle.vue' /* webpackChunkName: "components/journal-article" */).then(c => wrapFunctional(c.default || c))
export const JournalFilter = () => import('../../components/journal/JournalFilter.vue' /* webpackChunkName: "components/journal-filter" */).then(c => wrapFunctional(c.default || c))
export const JournalPageEnding = () => import('../../components/journal/JournalPageEnding.vue' /* webpackChunkName: "components/journal-page-ending" */).then(c => wrapFunctional(c.default || c))
export const JournalTextSearch = () => import('../../components/journal/JournalTextSearch.vue' /* webpackChunkName: "components/journal-text-search" */).then(c => wrapFunctional(c.default || c))
export const Journals = () => import('../../components/journal/Journals.vue' /* webpackChunkName: "components/journals" */).then(c => wrapFunctional(c.default || c))
export const MostUsedTags = () => import('../../components/journal/MostUsedTags.vue' /* webpackChunkName: "components/most-used-tags" */).then(c => wrapFunctional(c.default || c))
export const TagsFilter = () => import('../../components/journal/TagsFilter.vue' /* webpackChunkName: "components/tags-filter" */).then(c => wrapFunctional(c.default || c))
export const HomeHero = () => import('../../components/hero/HomeHero.vue' /* webpackChunkName: "components/home-hero" */).then(c => wrapFunctional(c.default || c))
export const JournalHero = () => import('../../components/hero/JournalHero.vue' /* webpackChunkName: "components/journal-hero" */).then(c => wrapFunctional(c.default || c))
export const SlugHero = () => import('../../components/hero/SlugHero.vue' /* webpackChunkName: "components/slug-hero" */).then(c => wrapFunctional(c.default || c))
export const WeihnachtsaktionHero = () => import('../../components/hero/WeihnachtsaktionHero.vue' /* webpackChunkName: "components/weihnachtsaktion-hero" */).then(c => wrapFunctional(c.default || c))
export const ApplicationCard = () => import('../../components/application-forms/ApplicationCard.vue' /* webpackChunkName: "components/application-card" */).then(c => wrapFunctional(c.default || c))
export const ApplicationStatus = () => import('../../components/application-forms/ApplicationStatus.vue' /* webpackChunkName: "components/application-status" */).then(c => wrapFunctional(c.default || c))
export const ApplicationsList = () => import('../../components/application-forms/ApplicationsList.vue' /* webpackChunkName: "components/applications-list" */).then(c => wrapFunctional(c.default || c))
export const BaseLayout = () => import('../../components/application-forms/BaseLayout.vue' /* webpackChunkName: "components/base-layout" */).then(c => wrapFunctional(c.default || c))
export const Hint = () => import('../../components/application-forms/Hint.vue' /* webpackChunkName: "components/hint" */).then(c => wrapFunctional(c.default || c))
export const KurzcheckError = () => import('../../components/application-forms/KurzcheckError.vue' /* webpackChunkName: "components/kurzcheck-error" */).then(c => wrapFunctional(c.default || c))
export const QuestionLayout = () => import('../../components/application-forms/QuestionLayout.vue' /* webpackChunkName: "components/question-layout" */).then(c => wrapFunctional(c.default || c))
export const WizardError = () => import('../../components/application-forms/WizardError.vue' /* webpackChunkName: "components/wizard-error" */).then(c => wrapFunctional(c.default || c))
export const EinzelpersonKurzcheckEingabefrist = () => import('../../components/application-forms/Einzelperson/Kurzcheck/Eingabefrist.vue' /* webpackChunkName: "components/einzelperson-kurzcheck-eingabefrist" */).then(c => wrapFunctional(c.default || c))
export const EinzelpersonKurzcheckFinanzen = () => import('../../components/application-forms/Einzelperson/Kurzcheck/Finanzen.vue' /* webpackChunkName: "components/einzelperson-kurzcheck-finanzen" */).then(c => wrapFunctional(c.default || c))
export const EinzelpersonKurzcheckGesuchsgrund = () => import('../../components/application-forms/Einzelperson/Kurzcheck/Gesuchsgrund.vue' /* webpackChunkName: "components/einzelperson-kurzcheck-gesuchsgrund" */).then(c => wrapFunctional(c.default || c))
export const EinzelpersonKurzcheckSteuerbaresEinkommen = () => import('../../components/application-forms/Einzelperson/Kurzcheck/SteuerbaresEinkommen.vue' /* webpackChunkName: "components/einzelperson-kurzcheck-steuerbares-einkommen" */).then(c => wrapFunctional(c.default || c))
export const GruppeKurzcheckEingabefrist = () => import('../../components/application-forms/Gruppe/Kurzcheck/Eingabefrist.vue' /* webpackChunkName: "components/gruppe-kurzcheck-eingabefrist" */).then(c => wrapFunctional(c.default || c))
export const GruppeKurzcheckFinanzenErwachsene = () => import('../../components/application-forms/Gruppe/Kurzcheck/FinanzenErwachsene.vue' /* webpackChunkName: "components/gruppe-kurzcheck-finanzen-erwachsene" */).then(c => wrapFunctional(c.default || c))
export const GruppeKurzcheckFinanzenKinder = () => import('../../components/application-forms/Gruppe/Kurzcheck/FinanzenKinder.vue' /* webpackChunkName: "components/gruppe-kurzcheck-finanzen-kinder" */).then(c => wrapFunctional(c.default || c))
export const GruppeKurzcheckGesuchsgrund = () => import('../../components/application-forms/Gruppe/Kurzcheck/Gesuchsgrund.vue' /* webpackChunkName: "components/gruppe-kurzcheck-gesuchsgrund" */).then(c => wrapFunctional(c.default || c))
export const ProjekteKurzcheckEingabefrist = () => import('../../components/application-forms/Projekte/Kurzcheck/Eingabefrist.vue' /* webpackChunkName: "components/projekte-kurzcheck-eingabefrist" */).then(c => wrapFunctional(c.default || c))
export const ProjekteKurzcheckProjektbereich = () => import('../../components/application-forms/Projekte/Kurzcheck/Projektbereich.vue' /* webpackChunkName: "components/projekte-kurzcheck-projektbereich" */).then(c => wrapFunctional(c.default || c))
export const ProjekteKurzcheckProjektleitung = () => import('../../components/application-forms/Projekte/Kurzcheck/Projektleitung.vue' /* webpackChunkName: "components/projekte-kurzcheck-projektleitung" */).then(c => wrapFunctional(c.default || c))
export const ProjekteKurzcheckZielgruppe = () => import('../../components/application-forms/Projekte/Kurzcheck/Zielgruppe.vue' /* webpackChunkName: "components/projekte-kurzcheck-zielgruppe" */).then(c => wrapFunctional(c.default || c))
export const Wizard = () => import('../../components/wizard/Wizard.vue' /* webpackChunkName: "components/wizard" */).then(c => wrapFunctional(c.default || c))
export const WizardButtonNext = () => import('../../components/wizard/WizardButtonNext.vue' /* webpackChunkName: "components/wizard-button-next" */).then(c => wrapFunctional(c.default || c))
export const WizardButtonPrev = () => import('../../components/wizard/WizardButtonPrev.vue' /* webpackChunkName: "components/wizard-button-prev" */).then(c => wrapFunctional(c.default || c))
export const WizardChildComponent = () => import('../../components/wizard/WizardChildComponent.vue' /* webpackChunkName: "components/wizard-child-component" */).then(c => wrapFunctional(c.default || c))
export const WizardSteps = () => import('../../components/wizard/WizardSteps.vue' /* webpackChunkName: "components/wizard-steps" */).then(c => wrapFunctional(c.default || c))
export const BottomWaves = () => import('../../components/BottomWaves.vue' /* webpackChunkName: "components/bottom-waves" */).then(c => wrapFunctional(c.default || c))
export const Candle = () => import('../../components/Candle.vue' /* webpackChunkName: "components/candle" */).then(c => wrapFunctional(c.default || c))
export const CandleMouseFollower = () => import('../../components/CandleMouseFollower.vue' /* webpackChunkName: "components/candle-mouse-follower" */).then(c => wrapFunctional(c.default || c))
export const DonationNamesList = () => import('../../components/DonationNamesList.vue' /* webpackChunkName: "components/donation-names-list" */).then(c => wrapFunctional(c.default || c))
export const DonationStories = () => import('../../components/DonationStories.vue' /* webpackChunkName: "components/donation-stories" */).then(c => wrapFunctional(c.default || c))
export const FilterBar = () => import('../../components/FilterBar.vue' /* webpackChunkName: "components/filter-bar" */).then(c => wrapFunctional(c.default || c))
export const LanguageSwitcher = () => import('../../components/LanguageSwitcher.vue' /* webpackChunkName: "components/language-switcher" */).then(c => wrapFunctional(c.default || c))
export const LottieCandleAnimation = () => import('../../components/LottieCandleAnimation.vue' /* webpackChunkName: "components/lottie-candle-animation" */).then(c => wrapFunctional(c.default || c))
export const LottieSnowflakesAnimation = () => import('../../components/LottieSnowflakesAnimation.vue' /* webpackChunkName: "components/lottie-snowflakes-animation" */).then(c => wrapFunctional(c.default || c))
export const Masonry = () => import('../../components/Masonry.vue' /* webpackChunkName: "components/masonry" */).then(c => wrapFunctional(c.default || c))
export const PodcastLibrary = () => import('../../components/PodcastLibrary.vue' /* webpackChunkName: "components/podcast-library" */).then(c => wrapFunctional(c.default || c))
export const PodcastTrack = () => import('../../components/PodcastTrack.vue' /* webpackChunkName: "components/podcast-track" */).then(c => wrapFunctional(c.default || c))
export const ShareBlogpost = () => import('../../components/ShareBlogpost.vue' /* webpackChunkName: "components/share-blogpost" */).then(c => wrapFunctional(c.default || c))
export const SiteFooter = () => import('../../components/SiteFooter.vue' /* webpackChunkName: "components/site-footer" */).then(c => wrapFunctional(c.default || c))
export const TransitionExpand = () => import('../../components/TransitionExpand.vue' /* webpackChunkName: "components/transition-expand" */).then(c => wrapFunctional(c.default || c))
export const Waves = () => import('../../components/Waves.vue' /* webpackChunkName: "components/waves" */).then(c => wrapFunctional(c.default || c))
export const WeihnachtsaktionDonationForm = () => import('../../components/WeihnachtsaktionDonationForm.vue' /* webpackChunkName: "components/weihnachtsaktion-donation-form" */).then(c => wrapFunctional(c.default || c))
export const ContactForm = () => import('../../components/contact-form/ContactForm.vue' /* webpackChunkName: "components/contact-form" */).then(c => wrapFunctional(c.default || c))
export const DonationStory = () => import('../../components/donation-stories/DonationStory.vue' /* webpackChunkName: "components/donation-story" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
