
import { mapState, mapGetters } from 'vuex'
import {
  useDispatchStoreActionMixin,
  Popover,
  PopoverButton,
  PopoverPanel,
} from '@teamnovu/utils'

export default {
  components: {
    Popover,
    PopoverButton,
    PopoverPanel,
  },

  mixins: [
    useDispatchStoreActionMixin({
      name: 'logoutAction',
      action: 'applicationForms/user/logout',
    }),
    useDispatchStoreActionMixin({
      name: 'stopImpersonationAction',
      action: 'applicationForms/user/stopImpersonation',
    }),
  ],

  props: {
    isTransparent: {
      type: Boolean,
      default: false,
    },

    isOnHome: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      scrollY: 0,
    }
  },

  computed: {
    ...mapState('applicationForms/user', ['user']),
    ...mapGetters('applicationForms/user', ['isImpersonating']),
  },

  mounted() {
    document.addEventListener('scroll', this.handleScroll, { passive: true })
  },

  beforeDestroy() {
    document.removeEventListener('scroll', this.handleScroll)
  },

  methods: {
    async logout() {
      await this.dispatchLogoutAction()

      this.$router.push(this.localePath({ name: 'login' }))
    },

    async stopImpersonation() {
      await this.dispatchStopImpersonationAction()

      this.$router.push(this.localePath({ name: 'gesuche-admin' }))
    },

    handleScroll() {
      this.scrollY = window.scrollY
    },
  },
}
