
import { mapState, mapMutations } from 'vuex'
import Vue100vh from 'vue-100vh'

export default {
  components: { Vue100vh },

  props: {
    isTransparent: {
      type: Boolean,
      default: false,
    },

    isOnHome: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {}
  },

  computed: {
    ...mapState('navigation', ['isOpen']),
  },

  methods: {
    ...mapMutations('navigation', ['toggleNav', 'closeNav']),

    beforeEnter(el) {
      el.style.opacity = '0'
      el.style.height = '0'
    },
    enter(el) {
      el.style.height = el.scrollHeight + 'px'
    },
    afterEnter(el) {
      el.style.opacity = '1'
    },
    beforeLeave(el) {
      el.style.opacity = '1'
      el.style.height = el.scrollHeight + 'px'
    },
    leave(el) {
      el.style.opacity = '0'
      el.style.height = '0'
    },
  },
}
