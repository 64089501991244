export const state = () => ({
  cookieBanner: null,
  globalLinks: null,
})

export const actions = {
  async loadCookieBanner({ commit }) {
    const { data } = await this.$axios.$get('globals/cookies')
    commit('setCookieBanner', data)
    return data
  },
  async loadGlobalLinks({ commit }) {
    const { data } = await this.$axios.$get('globals/links')
    commit('setGlobalLinks', data)
    return data
  },
}

export const mutations = {
  setCookieBanner(state, value) {
    state.cookieBanner = value
  },
  setGlobalLinks(state, value) {
    state.globalLinks = value
  },
}
