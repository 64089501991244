import Vue from 'vue'
Vue.mixin({
  methods: {
    imageFocusUrl(image, width = 400, height = 400) {
      if (!image || typeof image !== 'object') return null
      if (!image.is_image) return image.permalink

      // Replacing is for handling difference between env variables
      return (
        `${process.env.backendUrl}/img/${
          image.url.split(process.env.backendUrl)[1]
        }?w=${width}&h=${height}`.replace(/([^:]\/)\/+/g, '$1') +
        (image.focus ? `&fit=crop-${image.focus}` : '')
      )
    },
    imageUrl(image, width = 3000) {
      if (!image || typeof image !== 'object') return null
      if (!image.is_image) return image.permalink

      // Replacing is for handling difference between env variables
      return `${process.env.backendUrl}/img/${
        image.url.split(process.env.backendUrl)[1]
      }?w=${width}&fit=max`.replace(/([^:]\/)\/+/g, '$1')
    },
    linebreaksForHtml(value) {
      if (!value || typeof value !== 'string') return value

      return value.replace(/\n/g, '<br/>')
    },
  },
})
