export default function ({ app, $fb }) {
  app.router.afterEach((to, from) => {
    if (/\/journal\//.test(to.path)) {
      $fb.fbq('track', 'ViewContent')
    }

    if (
      /\/spenden(?:\/|$)/.test(to.path) ||
      /\/spende-schenken(?:\/|$)/.test(to.path) ||
      /\/gedenkspende(?:\/|$)/.test(to.path) ||
      /\/goennerschaft(?:\/|$)/.test(to.path)
    ) {
      $fb.fbq('track', 'InitiateCheckout')
    }
  })
}
