/*
 * Source: https://github.com/robinvdvleuten/vuex-persistedstate#using-cookies-universal-client--server-side
 */

import createPersistedState from 'vuex-persistedstate'
import * as Cookies from 'js-cookie'
import cookie from 'cookie'
import { addMilliseconds } from 'date-fns'

export default ({ store, req, res }) => {
  createPersistedState({
    paths: ['applicationForms.user'],
    storage: {
      getItem: (key) => {
        // See https://nuxtjs.org/guide/plugins/#using-process-flags
        if (process.server) {
          const parsedCookies = cookie.parse(req.headers.cookie ?? '')
          return parsedCookies[key]
        } else {
          return Cookies.get(key)
        }
      },
      // Please see https://github.com/js-cookie/js-cookie#json, on how to handle JSON.
      setItem: (key, value) => {
        if (process.server) {
          if (!res.writableEnded) {
            res.setHeader('Set-Cookie', [
              cookie.serialize(key, value, {
                path: '/',
                httpOnly: false,
              }),
            ])
          }
        } else {
          Cookies.set(key, value, { expires: 365, secure: false })
        }
      },
      removeItem: (key) => {
        if (process.server) {
          if (!res.writableEnded) {
            res.setHeader('Set-Cookie', [
              cookie.serialize(key, '', {
                path: '/',
                httpOnly: false,
                expires: addMilliseconds(new Date(), 1),
              }),
            ])
          }
        } else {
          Cookies.remove(key)
        }
      },
    },
  })(store)
}
