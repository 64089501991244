
import { mapState, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapState('content', ['globalLinks']),
  },

  methods: {
    ...mapMutations('navigation', ['closeNav']),

    handleDonateClick() {
      this.closeNav()

      this.$fb.fbq('track', 'AddToCart')
    },
  },
}
