import { render, staticRenderFns } from "./dashboard.vue?vue&type=template&id=83bde478&scoped=true&"
import script from "./dashboard.vue?vue&type=script&lang=js&"
export * from "./dashboard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "83bde478",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DashboardNavigation: require('/vercel/path0/components/navigation/DashboardNavigation.vue').default,SiteFooter: require('/vercel/path0/components/SiteFooter.vue').default,AppLayoutBase: require('/vercel/path0/components/ui/AppLayoutBase.vue').default})
