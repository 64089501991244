
export default {
  inheritAttrs: false,
  props: {
    to: {
      type: [String, Object],
      default: null,
    },
    blank: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isExternalUrl() {
      return (
        typeof this.to === 'string' &&
        this.to.match(
          /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/
        )
      )
    },
    isDocumentUrl() {
      return typeof this.to === 'string' && this.to.match(/documents\/.+/)
    },
    documentUrl() {
      return process.env.apiUrl + this.to + '/download'
    },
  },
}
