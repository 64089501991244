const arrayQueries = ['rubrics', 'tags']

export const state = () => ({
  page: null,
})

export const actions = {
  async loadPage({ commit, dispatch }, { path, isPreview, query }) {
    if (path.charAt(0) !== '/') {
      path = `/${path}`
    }

    const params = Object.keys(query).reduce((queries, key) => {
      if (
        arrayQueries.includes(key) &&
        query[key] &&
        !Array.isArray(query[key])
      ) {
        queries[key] = [query[key]]
      } else {
        queries[key] = query[key]
      }

      return queries
    }, {})

    const { data } = await this.$axios.$get(`pages${path}`, {
      params: {
        preview: isPreview,
        ...params,
      },
    })

    // articles mapping
    if (data.items && data.items.length) {
      data.items = await Promise.all(
        data.items.map(async (component) => {
          if (
            [
              'large_articles',
              'large_articles_long_text',
              'medium_articles',
              'small_articles',
              'teasers',
            ].includes(component.type)
          ) {
            return await dispatch('mapArticle', { article: component })
          }
          return component
        })
      )
    }

    let page = await dispatch('mapArticle', {
      article: data,
      prefix: 'large_articles_',
    })
    page = await dispatch('mapArticle', {
      article: page,
      prefix: 'medium_articles_',
    })

    commit('setPage', { ...page, isPreview })
    return page
  },

  async mapArticle(params, { article, prefix = '' }) {
    if (!prefix) {
      prefix = ''
    }
    if (article[prefix + 'show_newest_journal_entries']) {
      const journals = await this.$axios.$get('journals', {
        params: {
          per_page: article[prefix + 'number_of_entries'] || 4,
          rubrics:
            article[prefix + 'rubrics'].map((rubric) => rubric.slug) || [],
        },
      })
      article[prefix + 'items'] = journals.data
    }

    if (article[prefix + 'select_articles']) {
      article[prefix + 'items'] = article[prefix + 'pages']
    }

    return article
  },
}

export const mutations = {
  setPage(state, page) {
    state.page = page
  },
}
