import {
  FilterBase,
  GatewayBase,
  StatusBase,
} from '~/utils/applicationForms/utils'
import { StatementStoreBase } from '~/utils/applicationForms/base'
import { formatIban } from '~/utils'

class Filter extends FilterBase {
  showBP210(data) {
    return data.BP200.realizationStatus === 'cancelled'
  }

  showBP220(data, state) {
    return data.BP200.realizationStatus !== 'cancelled'
  }

  showBP300(data, state) {
    return data.BP200.realizationStatus !== 'cancelled'
  }

  showBP310(data, state) {
    return data.BP200.realizationStatus !== 'cancelled'
  }

  showBP320(data, state) {
    return data.BP200.realizationStatus !== 'cancelled'
  }

  showBP330(data, state) {
    return (
      data.BP200.realizationStatus !== 'cancelled' &&
      data.BP320.budget?.some(
        (item) => item.type === 'institutionalDonations' && item.amount > 0
      )
    )
  }

  showBP340(data, state) {
    return data.BP200.realizationStatus !== 'cancelled'
  }

  showBP350(data, state) {
    return (
      data.BP200.realizationStatus !== 'cancelled' &&
      state.statement.deficit === 0
    )
  }

  showBP400(data, state) {
    return data.BP200.realizationStatus !== 'cancelled' && !data.BP350.confirm
  }

  showBP410(data, state) {
    return data.BP200.realizationStatus !== 'cancelled' && !data.BP350.confirm
  }

  showBP420(data, state) {
    return data.BP200.realizationStatus !== 'cancelled' && !data.BP350.confirm
  }

  showBP430(data, state) {
    return data.BP200.realizationStatus !== 'cancelled' && !data.BP350.confirm
  }

  showBP440(data, state) {
    return data.BP200.realizationStatus !== 'cancelled' && !data.BP350.confirm
  }

  showBP450(data, state) {
    return data.BP200.realizationStatus !== 'cancelled' && !data.BP350.confirm
  }

  showBP500(data, state) {
    return data.BP200.realizationStatus !== 'cancelled' && !data.BP350.confirm
  }

  showBP510(data, state) {
    return data.BP200.realizationStatus !== 'cancelled' && !data.BP350.confirm
  }

  showBP600(data, state) {
    return data.BP200.realizationStatus !== 'cancelled' && !data.BP350.confirm
  }

  showBP700(data, state) {
    return data.BP200.realizationStatus !== 'cancelled' && !data.BP350.confirm
  }

  showBP710(data, state) {
    return data.BP200.realizationStatus === 'cancelled' || data.BP350.confirm
  }
}

class Gateway extends GatewayBase {
  inBP220(data) {
    return {
      read: !!data.numberOfDisabledPersons,
    }
  }

  inBP330(data) {
    return {
      donationsList: data.donationsListDocument,
      donations: data.donations,
      uploadDocument: !!data.donationsListDocument,
    }
  }

  outBP330(data) {
    if (data.uploadDocument) {
      return {
        donationsListDocumentId: data.donationsList?.id,
      }
    } else {
      return {
        donations: data.donations,
      }
    }
  }

  inBP340(data) {
    return {
      correct: !!data.evidenceOfInclusionCriteria,
    }
  }

  inBP400(data, context) {
    return {
      evidenceOfInclusionCriteria:
        data.evidenceOfInclusionCriteria &&
        data.evidenceOfInclusionCriteria.length > 0
          ? data.evidenceOfInclusionCriteria
          : context.state.applicationForms.projekte.abrechnung.application.inclusionCriteria.map(
              (criteria) => ({
                criteria,
                value: '',
              })
            ),
    }
  }

  inBP420(data) {
    return {
      projectReport: data.projectReportDocument,
    }
  }

  outBP420(data) {
    return {
      projectReportDocumentId: data.projectReport?.id,
    }
  }

  outBP430(data) {
    return {
      imageIds: data.images.filter((image) => image?.id).map(({ id }) => id),
    }
  }

  inBP450(data) {
    return {
      organizationLogo: data.organizationLogo,
      noOrganizationLogo: data.noOrganizationLogo,
    }
  }

  outBP450(data) {
    return {
      organizationLogoId: data.organizationLogo?.id,
      noOrganizationLogo: !!data.noOrganizationLogo,
    }
  }

  inBP500(data, context) {
    return {
      contact:
        data.paymentRecipient ??
        context.state.applicationForms.projekte.abrechnung.application.contact,
    }
  }

  async outBP500(data, { $axios }) {
    if (data.contact?.id) {
      return {
        id: data.contact.id,
        type: 'paymentRecipient',
      }
    }
    if (data.contact) {
      const c = (await $axios.$post('/contacts', data.contact)).data
      return { type: 'paymentRecipient', id: c.id }
    }

    return { type: 'paymentRecipient' }
  }

  inBP510(data, { $axios }) {
    const iban = data?.paymentRecipient?.bankDetails?.iban
    return { iban: iban ? formatIban(iban) : '' }
  }

  outBP510(data) {
    return {
      iban: data.iban?.replaceAll(' ', ''),
    }
  }
}

class Status extends StatusBase {
  BP330(data) {
    return {
      isCompleted:
        !!data.donationsList || (!!data.donations && data.donations.length > 0),
    }
  }

  BP450(data, state) {
    return {
      isCompleted:
        state.statement.noOrganizationLogo || state.statement.organizationLogo,
    }
  }
}

class Store extends StatementStoreBase {
  filter = new Filter()
  gateway = new Gateway()
  status = new Status()

  buildUrl(id, path = '') {
    if (path === '/contacts' || path === '/cancel') {
      return `applications/${id}${path}`
    }
    return `/applications/${id}/statement${path}`
  }

  stateGenerator() {
    return {
      ...super.stateGenerator(),

      steps: [
        {
          titleKey: 'project.confirm-realization',
          questions: [
            {
              id: 'BP200',
              titleKey: 'project.BP200.title',
              route: {
                name: 'gesuche-projekte-abrechnung-id-bestaetigung-realisierung-projektabschluss',
              },
              setQuestionDataEndpoint: '/realization-status',
            },
            {
              id: 'BP210',
              titleKey: 'project.BP210.title',
              route: {
                name: 'gesuche-projekte-abrechnung-id-bestaetigung-realisierung-gesuch-absagen',
              },
            },
            {
              id: 'BP220',
              titleKey: 'project.BP220.title',
              route: {
                name: 'gesuche-projekte-abrechnung-id-bestaetigung-realisierung-unterlagen-abrechnung',
              },
            },
          ],
        },
        {
          titleKey: 'project.statement-projects',
          questions: [
            {
              id: 'BP300',
              titleKey: 'project.BP300.title',
              route: {
                name: 'gesuche-projekte-abrechnung-id-abrechnung-projekte-reichweite',
              },
              setQuestionDataEndpoint: '/number-of-disabled-persons',
            },
            {
              id: 'BP310',
              titleKey: 'project.BP310.title',
              route: {
                name: 'gesuche-projekte-abrechnung-id-abrechnung-projekte-effektiver-aufwand',
              },
              setQuestionDataEndpoint: '/expenses',
            },
            {
              id: 'BP320',
              titleKey: 'project.BP320.title',
              route: {
                name: 'gesuche-projekte-abrechnung-id-abrechnung-projekte-effektiver-ertrag',
              },
              setQuestionDataEndpoint: '/budget',
            },
            {
              id: 'BP330',
              titleKey: 'project.BP330.title',
              route: {
                name: 'gesuche-projekte-abrechnung-id-abrechnung-projekte-institutionelle-spenden',
              },
              setQuestionDataEndpoint: '/donations',
            },
            {
              id: 'BP340',
              titleKey: 'project.BP340.title',
              route: {
                name: 'gesuche-projekte-abrechnung-id-abrechnung-projekte-effektives-defizit',
              },
            },
            {
              id: 'BP350',
              titleKey: 'project.BP350.title',
              route: {
                name: 'gesuche-projekte-abrechnung-id-abrechnung-projekte-kein-defizit',
              },
            },
          ],
        },
        {
          titleKey: 'project.dam-conditions',
          questions: [
            {
              id: 'BP400',
              titleKey: 'project.BP400.title',
              route: {
                name: 'gesuche-projekte-abrechnung-id-dam-bedingungen-inklusionsnachweis',
              },
              setQuestionDataEndpoint: '/evidence-of-inclusion-criteria',
            },
            {
              id: 'BP410',
              titleKey: 'project.BP410.title',
              route: {
                name: 'gesuche-projekte-abrechnung-id-dam-bedingungen-nachweis-der-unterstuetzung',
              },
              setQuestionDataEndpoint: '/evidence-of-visibility',
            },
            {
              id: 'BP420',
              titleKey: 'project.BP420.title',
              route: {
                name: 'gesuche-projekte-abrechnung-id-dam-bedingungen-abschlussbericht',
              },
              setQuestionDataEndpoint: '/project-report-document',
            },
            {
              id: 'BP430',
              titleKey: 'project.BP430.title',
              route: {
                name: 'gesuche-projekte-abrechnung-id-dam-bedingungen-bilder-hochladen',
              },
              setQuestionDataEndpoint: '/images',
            },
            {
              id: 'BP440',
              titleKey: 'project.BP440.title',
              route: {
                name: 'gesuche-projekte-abrechnung-id-dam-bedingungen-bildfreigabe',
              },
              setQuestionDataEndpoint: '/allow-image-use',
            },
            {
              id: 'BP450',
              titleKey: 'project.BP450.title',
              route: {
                name: 'gesuche-projekte-abrechnung-id-dam-bedingungen-logo-antragsteller',
              },
              setQuestionDataEndpoint: '/organization-logo',
            },
          ],
        },
        {
          titleKey: 'project.contact',
          questions: [
            {
              id: 'BP500',
              titleKey: 'project.BP500.title',
              route: {
                name: 'gesuche-projekte-abrechnung-id-kontaktdaten-kontoinhaber',
              },
              setQuestionDataEndpoint: '/contacts',
            },
            {
              id: 'BP510',
              titleKey: 'project.BP510.title',
              route: {
                name: 'gesuche-projekte-abrechnung-id-kontaktdaten-kontodaten',
              },
              setQuestionDataEndpoint: '/bank-details',
            },
            {
              id: 'BP600',
              titleKey: 'project.BP600.title',
              route: {
                name: 'gesuche-projekte-abrechnung-id-kontaktdaten-bemerkungen',
              },
              setQuestionDataEndpoint: '/comments',
            },
          ],
        },
        {
          titleKey: 'project.conclusion',
          questions: [
            {
              id: 'BP610',
              titleKey: 'project.BP610.title',
              route: {
                name: 'gesuche-projekte-abrechnung-id-abschluss-verifikation',
              },
            },
            {
              id: 'BP700',
              titleKey: 'project.BP700.title',
              route: {
                name: 'gesuche-projekte-abrechnung-id-abschluss-vielen-dank',
              },
              ignoreInQuestionCount: true,
            },
            {
              id: 'BP710',
              titleKey: 'project.BP710.title',
              route: {
                name: 'gesuche-projekte-abrechnung-id-abschluss-absage',
              },
              ignoreInQuestionCount: true,
            },
          ],
        },
      ],

      data: {
        BP200: {
          realizationStatus: null,
        },
        BP220: {
          read: null,
        },
        BP300: {
          numberOfDisabledPersons: null,
        },
        BP310: {
          expenses: null,
        },
        BP320: {
          budget: null,
        },
        BP330: {
          uploadDocument: null,
          donations: null,
          donationsList: null,
        },
        BP340: {
          correct: null,
        },
        BP350: {
          confirm: null,
        },
        BP400: {
          evidenceOfInclusionCriteria: [],
        },
        BP410: {
          evidenceOfVisibility: [],
        },
        BP420: {
          projectReport: null,
        },
        BP430: {
          images: [],
        },
        BP440: {
          allowImageUse: null,
        },
        BP450: {
          organizationLogo: null,
          noOrganizationLogo: null,
        },
        BP500: {
          contact: null,
        },
        BP510: {
          iban: null,
        },
        BP600: {
          comments: null,
        },
      },
    }
  }
}

export default new Store()
