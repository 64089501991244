
import { mapState, mapMutations } from 'vuex'

export default {
  components: {},

  props: {},

  data() {
    return {
      openSubmenu: false,
    }
  },

  computed: {
    ...mapState('navigation', ['isOpen', 'pages', 'selectedTab']),
    ...mapState('content', ['globalLinks']),
  },
  watch: {
    isOpen(newVal, oldVal) {
      this.openSubnav()
    },
  },
  methods: {
    ...mapMutations('navigation', ['toggleNav', 'closeNav']),

    beforeEnter(el) {
      el.style.opacity = '0'
      el.style.height = '0'
    },
    enter(el) {
      el.style.height = el.scrollHeight + 'px'
    },
    afterEnter(el) {
      el.style.opacity = '1'
    },
    beforeLeave(el) {
      el.style.opacity = '1'
      el.style.height = el.scrollHeight + 'px'
    },
    leave(el) {
      el.style.opacity = '0'
      el.style.height = '0'
    },

    toggleOpen(i) {
      if (this.openSubmenu === i) {
        this.openSubmenu = null
      } else if (this.openSubmenu === null) {
        this.openSubmenu = i
      } else if (this.openSubmenu !== null) {
        this.openSubmenu = i
      } else {
        this.openSubmenu = null
      }
    },

    openSubnav() {
      if (this.isOpen === false) {
        this.openSubmenu = null
      } else if (this.selectedTab) {
        this.pages.forEach((page, index) => {
          if (page.title === this.selectedTab) {
            this.openSubmenu = index
          }
        })
      }
    },
  },
}
