import {
  FilterBase,
  GatewayBase,
  StatusBase,
} from '~/utils/applicationForms/utils'
import { StatementStoreBase } from '~/utils/applicationForms/base'
import { formatIban } from '~/utils'

class Filter extends FilterBase {
  showBG200(data, state) {
    return state.application.status !== 'partial_statement_submitted'
  }

  showBG210(data, state) {
    return (
      data.BG200.realizationStatus === 'cancelled' &&
      state.application.status !== 'partial_statement_submitted'
    )
  }

  showBG220(data, state) {
    return (
      !data.BG210.confirmCancellation &&
      state.application.status !== 'partial_statement_submitted'
    )
  }

  showBG230(data, state) {
    return (
      !data.BG210.confirmCancellation &&
      state.application.status !== 'partial_statement_submitted'
    )
  }

  showBG300(data, state) {
    return (
      !data.BG210.confirmCancellation &&
      state.application.subtype === 'single-group' &&
      state.application.status !== 'partial_statement_submitted'
    )
  }

  showBG310(data, state) {
    return (
      !data.BG210.confirmCancellation &&
      state.application.subtype === 'single-group' &&
      state.application.status !== 'partial_statement_submitted'
    )
  }

  showBG320(data, state) {
    return (
      !data.BG210.confirmCancellation &&
      state.application.subtype === 'single-group' &&
      state.application.status !== 'partial_statement_submitted'
    )
  }

  showBG330(data, state) {
    return (
      !data.BG210.confirmCancellation &&
      state.application.subtype === 'single-group' &&
      state.application.status !== 'partial_statement_submitted'
    )
  }

  showBG340(data, state) {
    return (
      !data.BG210.confirmCancellation &&
      state.application.subtype === 'single-group' &&
      state.application.status !== 'partial_statement_submitted'
    )
  }

  showBG350(data, state) {
    return (
      !data.BG210.confirmCancellation &&
      state.application.subtype === 'single-group' &&
      state.application.status !== 'partial_statement_submitted'
    )
  }

  showBG360(data, state) {
    return (
      !data.BG210.confirmCancellation &&
      state.application.subtype === 'single-group' &&
      state.application.status !== 'partial_statement_submitted'
    )
  }

  showBG400(data, state) {
    return (
      !data.BG210.confirmCancellation &&
      state.application.subtype === 'group-collection'
    )
  }

  showBG410(data, state) {
    return (
      !data.BG210.confirmCancellation &&
      state.application.subtype === 'group-collection' &&
      data.BG400.partialBilling
    )
  }

  showBG420(data, state) {
    return (
      !data.BG210.confirmCancellation &&
      state.application.subtype === 'group-collection' &&
      data.BG400.partialBilling
    )
  }

  showBG430(data, state) {
    return (
      !data.BG210.confirmCancellation &&
      state.application.subtype === 'group-collection' &&
      data.BG400.partialBilling
    )
  }

  showBG440(data, state) {
    return (
      !data.BG210.confirmCancellation &&
      state.application.subtype === 'group-collection' &&
      data.BG400.partialBilling
    )
  }

  showBG450(data, state) {
    return (
      !data.BG210.confirmCancellation &&
      state.application.subtype === 'group-collection' &&
      !data.BG400.partialBilling
    )
  }

  showBG460(data, state) {
    return (
      !data.BG210.confirmCancellation &&
      state.application.subtype === 'group-collection' &&
      !data.BG400.partialBilling
    )
  }

  showBG500(data, state) {
    return !data.BG210.confirmCancellation
  }

  showBG510(data, state) {
    return !data.BG210.confirmCancellation
  }

  showBG520(data, state) {
    return !data.BG210.confirmCancellation
  }

  showBG530(data, state) {
    return !data.BG210.confirmCancellation
  }

  showBG540(data, state) {
    return (
      !data.BG210.confirmCancellation &&
      state.application.subtype === 'single-group'
    )
  }

  showBG550(data, state) {
    return (
      !data.BG210.confirmCancellation &&
      state.application.subtype === 'group-collection'
    )
  }

  showBG560(data, state) {
    return !data.BG210.confirmCancellation
  }

  showBG570(data, state) {
    return !data.BG210.confirmCancellation
  }

  showBG600(data, state) {
    return !data.BG210.confirmCancellation
  }

  showBG610(data, state) {
    return !data.BG210.confirmCancellation
  }

  showBG700(data, state) {
    return !data.BG210.confirmCancellation
  }

  showBG710(data, state) {
    return !data.BG210.confirmCancellation
  }

  showBG720(data) {
    return !!data.BG210.confirmCancellation
  }
}

class Gateway extends GatewayBase {
  inBG220(data) {
    return {
      read: !!data.numberOfDisabledPersons,
    }
  }

  inBG230(data) {
    return {
      accept: !!data.numberOfDisabledPersons,
    }
  }

  outBG330(data) {
    return {
      documentIds: (data.receipts ?? [])
        .filter((receipt) => receipt?.id)
        .map(({ id }) => id),
    }
  }

  inBG350(data) {
    return { correct: !!data.participantListDocument }
  }

  inBG360(data) {
    return {
      participantList: data.participantListDocument,
    }
  }

  outBG360(data) {
    return {
      participantListDocumentId: data.participantList?.id,
    }
  }

  inBG420(data) {
    return {
      partialBillingDocument: data.partialBillingDocument,
    }
  }

  outBG420(data) {
    return {
      partialBillingDocumentId: data.partialBillingDocument?.id,
    }
  }

  inBG450(data) {
    return {
      finalBillingDocument: data.finalBillingDocument,
    }
  }

  outBG450(data) {
    return {
      finalBillingDocumentId: data.finalBillingDocument?.id,
    }
  }

  inBG510(data, context) {
    return {
      evidenceOfInclusionCriteria:
        data.evidenceOfInclusionCriteria &&
        data.evidenceOfInclusionCriteria.length > 0
          ? data.evidenceOfInclusionCriteria
          : context.state.applicationForms.gruppe.abrechnung.application.inclusionCriteria.map(
              (criteria) => ({
                criteria,
                value: '',
              })
            ),
    }
  }

  outBG540(data) {
    return {
      imageIds: data.communicationImages
        .filter((image) => image?.id)
        .map(({ id }) => id),
    }
  }

  outBG550(data) {
    return {
      imageIds: data.images.filter((image) => image?.id).map(({ id }) => id),
    }
  }

  inBG570(data) {
    return {
      organizationLogo: data.organizationLogo,
      noOrganizationLogo: data.noOrganizationLogo,
    }
  }

  outBG570(data) {
    return {
      organizationLogoId: data.organizationLogo?.id,
      noOrganizationLogo: !!data.noOrganizationLogo,
    }
  }

  inBG600(data, context) {
    return {
      contact:
        data.paymentRecipient ??
        context.state.applicationForms.gruppe.abrechnung.application.contact,
    }
  }

  async outBG600(data, { $axios }) {
    if (data.contact?.id) {
      return {
        id: data.contact.id,
        type: 'paymentRecipient',
      }
    }
    if (data.contact) {
      const c = (await $axios.$post('/contacts', data.contact)).data
      return { type: 'paymentRecipient', id: c.id }
    }

    return { type: 'paymentRecipient' }
  }

  inBG610(data, { $axios }) {
    const iban = data?.paymentRecipient?.bankDetails?.iban
    return { iban: iban ? formatIban(iban) : '' }
  }

  outBG610(data) {
    return {
      iban: data.iban?.replaceAll(' ', ''),
    }
  }
}

class Status extends StatusBase {
  BG200(data, state) {
    return {
      isLocked: state.application.status === 'partial_statement_submitted',
    }
  }

  BG210(data, state) {
    return {
      isLocked: state.application.status === 'partial_statement_submitted',
    }
  }

  BG220(data, state) {
    return {
      isLocked: state.application.status === 'partial_statement_submitted',
    }
  }

  BG230(data, state) {
    return {
      isLocked: state.application.status === 'partial_statement_submitted',
    }
  }

  BG300(data, state) {
    return {
      isLocked: state.application.status === 'partial_statement_submitted',
    }
  }

  BG310(data, state) {
    return {
      isLocked: state.application.status === 'partial_statement_submitted',
    }
  }

  BG320(data, state) {
    return {
      isLocked: state.application.status === 'partial_statement_submitted',
    }
  }

  BG330(data, state) {
    return {
      isLocked: state.application.status === 'partial_statement_submitted',
    }
  }

  BG340(data, state) {
    return {
      isLocked: state.application.status === 'partial_statement_submitted',
    }
  }

  BG350(data, state) {
    return {
      isLocked: state.application.status === 'partial_statement_submitted',
    }
  }

  BG360(data, state) {
    return {
      isLocked: state.application.status === 'partial_statement_submitted',
    }
  }

  BG570(data, state) {
    return {
      isCompleted:
        state.statement.noOrganizationLogo || state.statement.organizationLogo,
    }
  }
}

class Store extends StatementStoreBase {
  filter = new Filter()
  gateway = new Gateway()
  status = new Status()

  buildUrl(id, path = '') {
    if (path === '/contacts' || path === '/cancel') {
      return `applications/${id}${path}`
    }
    return `/applications/${id}/statement${path}`
  }

  stateGenerator() {
    return {
      ...super.stateGenerator(),

      steps: [
        {
          titleKey: 'group.confirm-realization',
          questions: [
            {
              id: 'BG200',
              titleKey: 'group.BG200.title',
              route: {
                name: 'gesuche-gruppe-abrechnung-id-bestaetigung-realisierung-bestaetigung-realisierung',
              },
              setQuestionDataEndpoint: '/realization-status',
            },
            {
              id: 'BG210',
              titleKey: 'group.BG210.title',
              route: {
                name: 'gesuche-gruppe-abrechnung-id-bestaetigung-realisierung-gesuch-absagen',
              },
            },
            {
              id: 'BG220',
              titleKey: 'group.BG220.title',
              route: {
                name: 'gesuche-gruppe-abrechnung-id-bestaetigung-realisierung-unterlagen-abrechnung',
              },
            },
            {
              id: 'BG230',
              titleKey: 'group.BG230.title',
              route: {
                name: 'gesuche-gruppe-abrechnung-id-bestaetigung-realisierung-zielgruppe',
              },
            },
          ],
        },
        {
          titleKey: 'group.statement-single-group',
          questions: [
            {
              id: 'BG300',
              titleKey: 'group.BG300.title',
              route: {
                name: 'gesuche-gruppe-abrechnung-id-abrechnung-einzelgruppen-anzahl-teilnehmer',
              },
              setQuestionDataEndpoint: '/number-of-disabled-persons',
            },
            {
              id: 'BG310',
              titleKey: 'group.BG310.title',
              route: {
                name: 'gesuche-gruppe-abrechnung-id-abrechnung-einzelgruppen-anzahl-tage',
              },
              setQuestionDataEndpoint: '/number-of-days',
            },
            {
              id: 'BG320',
              titleKey: 'group.BG320.title',
              route: {
                name: 'gesuche-gruppe-abrechnung-id-abrechnung-einzelgruppen-ausgaben',
              },
              setQuestionDataEndpoint: '/expenses',
            },
            {
              id: 'BG330',
              titleKey: 'group.BG330.title',
              route: {
                name: 'gesuche-gruppe-abrechnung-id-abrechnung-einzelgruppen-belege-hochladen',
              },
              setQuestionDataEndpoint: '/receipts',
            },
            {
              id: 'BG340',
              titleKey: 'group.BG340.title',
              route: {
                name: 'gesuche-gruppe-abrechnung-id-abrechnung-einzelgruppen-einnahmen',
              },
              setQuestionDataEndpoint: '/budget',
            },
            {
              id: 'BG350',
              titleKey: 'group.BG350.title',
              route: {
                name: 'gesuche-gruppe-abrechnung-id-abrechnung-einzelgruppen-defizit',
              },
            },
            {
              id: 'BG360',
              titleKey: 'group.BG360.title',
              route: {
                name: 'gesuche-gruppe-abrechnung-id-abrechnung-einzelgruppen-teilnehmerliste',
              },
              setQuestionDataEndpoint: '/participant-list-document',
            },
          ],
        },
        {
          titleKey: 'group.statement-group-collection',
          questions: [
            {
              id: 'BG400',
              titleKey: 'group.BG400.title',
              route: {
                name: 'gesuche-gruppe-abrechnung-id-abrechnung-sammelgesuch-abrechnung',
              },
              setQuestionDataEndpoint: '/partial-billing',
            },
            {
              id: 'BG410',
              titleKey: 'group.BG410.title',

              route: {
                name: 'gesuche-gruppe-abrechnung-id-abrechnung-sammelgesuch-teilabrechnung',
              },
            },
            {
              id: 'BG420',
              titleKey: 'group.BG420.title',
              route: {
                name: 'gesuche-gruppe-abrechnung-id-abrechnung-sammelgesuch-teilabrechnung-upload',
              },
              setQuestionDataEndpoint: '/partial-billing-document',
            },
            {
              id: 'BG430',
              titleKey: 'group.BG430.title',
              route: {
                name: 'gesuche-gruppe-abrechnung-id-abrechnung-sammelgesuch-defizit-teilabrechnung',
              },
              setQuestionDataEndpoint: '/partial-billing-deficit',
            },
            {
              id: 'BG440',
              titleKey: 'group.BG440.title',
              route: {
                name: 'gesuche-gruppe-abrechnung-id-abrechnung-sammelgesuch-teilabrechnung-beenden',
              },
              setQuestionDataEndpoint: '/remaining-deficit-estimate',
            },
            {
              id: 'BG450',
              titleKey: 'group.BG450.title',
              route: {
                name: 'gesuche-gruppe-abrechnung-id-abrechnung-sammelgesuch-schlussabrechnung',
              },
              setQuestionDataEndpoint: '/final-billing-document',
            },
            {
              id: 'BG460',
              titleKey: 'group.BG460.title',
              route: {
                name: 'gesuche-gruppe-abrechnung-id-abrechnung-sammelgesuch-defizit-schlussabrechnung',
              },
              setQuestionDataEndpoint: '/deficit',
            },
          ],
        },
        {
          titleKey: 'group.dam-conditions',
          questions: [
            {
              id: 'BG500',
              titleKey: 'group.BG500.title',
              route: {
                name: 'gesuche-gruppe-abrechnung-id-dam-bedingungen-inklusionskriterien',
              },
            },
            {
              id: 'BG510',
              titleKey: 'group.BG510.title',
              route: {
                name: 'gesuche-gruppe-abrechnung-id-dam-bedingungen-inklusionsnachweis',
              },
              setQuestionDataEndpoint: '/evidence-of-inclusion-criteria',
            },
            {
              id: 'BG520',
              titleKey: 'group.BG520.title',
              route: {
                name: 'gesuche-gruppe-abrechnung-id-dam-bedingungen-nachweis-unterstuetzung',
              },
              setQuestionDataEndpoint: '/evidence-of-visibility',
            },
            {
              id: 'BG530',
              titleKey: 'group.BG530.title',
              route: {
                name: 'gesuche-gruppe-abrechnung-id-dam-bedingungen-wirkung',
              },
              setQuestionDataEndpoint: '/review',
            },
            {
              id: 'BG540',
              titleKey: 'group.BG540.title',
              route: {
                name: 'gesuche-gruppe-abrechnung-id-dam-bedingungen-bild-hochladen',
              },
              setQuestionDataEndpoint: '/communication-images',
            },
            {
              id: 'BG550',
              titleKey: 'group.BG550.title',
              route: {
                name: 'gesuche-gruppe-abrechnung-id-dam-bedingungen-bilder-hochladen',
              },
              setQuestionDataEndpoint: '/images',
            },
            {
              id: 'BG560',
              titleKey: 'group.BG560.title',
              route: {
                name: 'gesuche-gruppe-abrechnung-id-dam-bedingungen-bildfreigabe',
              },
              setQuestionDataEndpoint: '/allow-image-use',
            },
            {
              id: 'BG570',
              titleKey: 'group.BG570.title',
              route: {
                name: 'gesuche-gruppe-abrechnung-id-dam-bedingungen-logo-antragsteller',
              },
              setQuestionDataEndpoint: '/organization-logo',
            },
          ],
        },
        {
          titleKey: 'group.contact',
          questions: [
            {
              id: 'BG600',
              titleKey: 'group.BG600.title',
              route: {
                name: 'gesuche-gruppe-abrechnung-id-kontaktdaten-kontaktdaten-kontoinhaber',
              },
              setQuestionDataEndpoint: '/contacts',
            },
            {
              id: 'BG610',
              titleKey: 'group.BG610.title',
              route: {
                name: 'gesuche-gruppe-abrechnung-id-kontaktdaten-kontodaten',
              },
              setQuestionDataEndpoint: '/bank-details',
            },
          ],
        },
        {
          titleKey: 'group.conclusion',
          questions: [
            {
              id: 'BG700',
              titleKey: 'group.BG700.title',
              route: {
                name: 'gesuche-gruppe-abrechnung-id-abschluss-verifikation',
              },
              ignoreInQuestionCount: true,
            },
            {
              id: 'BG710',
              titleKey: 'group.BG710.title',
              route: {
                name: 'gesuche-gruppe-abrechnung-id-abschluss-vielen-dank',
              },
              ignoreInQuestionCount: true,
            },
            {
              id: 'BG720',
              titleKey: 'group.BG720.title',
              route: {
                name: 'gesuche-gruppe-abrechnung-id-absage',
              },
              ignoreInQuestionCount: true,
            },
          ],
        },
      ],

      data: {
        BG200: {
          realizationStatus: null,
        },
        BG210: {
          confirmCancellation: null,
        },
        BG220: {
          read: null,
        },
        BG230: {
          accept: null,
        },
        BG300: {
          numberOfDisabledPersons: null,
        },
        BG310: {
          numberOfDays: null,
        },
        BG320: {
          expenses: null,
        },
        BG330: {
          receipts: [],
        },
        BG340: {
          budget: [],
        },
        BG350: {
          correct: null,
        },
        BG360: {
          participantList: null,
        },
        BG400: {
          partialBilling: null,
        },
        BG410: {
          accept: null,
        },
        BG420: {
          partialBillingDocument: null,
        },
        BG430: {
          partialBillingDeficit: null,
        },
        BG440: {
          remainingDeficitEstimate: null,
        },
        BG450: {
          finalBillingDocument: null,
        },
        BG460: {
          deficit: null,
        },
        BG510: {
          evidenceOfInclusionCriteria: [],
        },
        BG520: {
          evidenceOfVisibility: [],
        },
        BG530: {
          review: null,
        },
        BG540: {
          communicationImages: [],
        },
        BG550: {
          images: [],
        },
        BG560: {
          allowImageUse: null,
        },
        BG570: {
          organizationLogo: null,
          noOrganizationLogo: null,
        },
        BG600: {
          contact: null,
        },
        BG610: {
          iban: null,
        },
        BG700: {
          accept: null,
        },
      },
    }
  }
}

export default new Store()
