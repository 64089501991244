
export default {
  components: {},

  props: {},

  data() {
    return {
      offset: '-1px',
      observer: null,
    }
  },

  computed: {},

  mounted() {
    const banner = document.querySelector('.simple-cookie-banner')

    if (banner) {
      this.calculateOffset(banner)
      this.initObserver(banner)
    }
  },

  beforeDestroy() {
    if (this.observer) this.observer.disconnect()
  },

  methods: {
    calculateOffset(element) {
      if (element) {
        this.offset = element.getBoundingClientRect().height + 'px'
      }
    },

    initObserver(target) {
      const isResizeObserverSupported = 'ResizeObserver' in window
      if (!isResizeObserverSupported) {
        return
      }

      this.observer = new ResizeObserver((entries) => {
        entries.forEach((entry) => {
          this.calculateOffset(entry.target)
        })
      })

      this.observer.observe(target)
    },
  },
}
