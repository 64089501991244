// import Vue from 'vue'
import {
  FilterBase,
  GatewayBase,
  StatusBase,
} from '~/utils/applicationForms/utils'
import { StoreBase } from '~/utils/applicationForms/base'

class Filter extends FilterBase {
  showFG100(data, state, globalState) {
    return !globalState.applicationForms.user.user
  }

  showFG110(data, state, globalState) {
    return !globalState.applicationForms.user.user
  }

  showFG230(data, state) {
    return data.FG220.subtype === 'group-collection'
  }

  showFG235(data, state) {
    return data.FG220.subtype === 'group-collection'
  }

  showFG240(data, state) {
    return data.FG220.subtype === 'group-collection'
  }

  showFG245(data, state) {
    return data.FG220.subtype === 'group-collection'
  }

  showFG260(data, state) {
    return data.FG220.subtype === 'group-collection'
  }

  showFG280(data) {
    return data.FG220.subtype === 'single-group'
  }

  showFG290(data) {
    return data.FG220.subtype === 'group-collection'
  }

  showFG300(data) {
    return data.FG220.subtype === 'single-group'
  }

  showFG310(data) {
    return data.FG220.subtype === 'single-group'
  }

  showFG320(data) {
    return data.FG220.subtype === 'single-group'
  }

  showFG330(data) {
    return data.FG220.subtype === 'single-group'
  }

  showFG340(data) {
    return data.FG220.subtype === 'single-group'
  }

  showFG400(data) {
    return data.FG220.subtype === 'single-group'
  }

  showFG410(data) {
    return data.FG220.subtype === 'single-group'
  }

  showFG420(data) {
    return data.FG220.subtype === 'single-group'
  }

  showFG430(data) {
    return data.FG220.subtype === 'group-collection'
  }
}

class Gateway extends GatewayBase {
  inFG200(data) {
    return { read: data.subtype ? true : null }
  }

  inFG210(data) {
    return { read: data.subtype ? true : null }
  }

  inFG235(data, context) {
    return {
      start: data.timePeriod?.start,
      end: data.timePeriod?.end,
    }
  }

  inFG245(data) {
    return {
      applicationSheet: data.groupCollectionListDocument,
    }
  }

  outFG245(data) {
    return {
      groupCollectionListDocumentId: data.applicationSheet?.id,
    }
  }

  inFG250(data) {
    return {
      composition: data.groupComposition,
    }
  }

  inFG340(data, context) {
    return {
      start: data.timePeriod?.start,
      end: data.timePeriod?.end,
    }
  }

  inFG420(data) {
    return { correct: data?.inclusionCriteria ? true : null }
  }

  inFG530(data) {
    return { accept: data?.contact ? true : null }
  }

  inFG600(data) {
    return data.contact
  }

  async outFG600(data, { $axios, $router }) {
    if (Object.keys(data).length <= 0) {
      return {
        type: 'individual',
      }
    }

    if (!data.id) {
      data = (await $axios.$post('/contacts', data)).data
    }

    return {
      id: data.id,
      type: 'individual',
    }
  }
}

class Status extends StatusBase {
  FG430(data, state) {
    return {
      isCompleted: data.deficit,
    }
  }
}

class Store extends StoreBase {
  filter = new Filter()
  gateway = new Gateway()
  status = new Status()

  stateGenerator() {
    return {
      ...super.stateGenerator(),

      steps: [
        {
          titleKey: 'group.input',
          questions: [
            {
              id: 'FG000',
              title: '',
              route: { name: 'gesuche-gruppe' },
              ignoreInQuestionCount: true,
            },
            {
              id: 'FG100',
              titleKey: 'group.FG100.title',
              route: { name: 'gesuche-gruppe-email' },
            },
            {
              id: 'FG110',
              titleKey: 'group.FG110.title',
              route: { name: 'gesuche-gruppe-verifikation-email' },
            },
            {
              id: 'FG120',
              titleKey: 'group.FG120.title',
              route: { name: 'gesuche-gruppe-id' },
            },
          ],
        },
        {
          titleKey: 'group.guidelines',
          questions: [
            {
              id: 'FG200',
              titleKey: 'group.FG200.title',
              route: {
                name: 'gesuche-gruppe-id-richtlinien-und-bedarf-richtlinien',
              },
            },
            {
              id: 'FG210',
              titleKey: 'group.FG210.title',
              route: {
                name: 'gesuche-gruppe-id-richtlinien-und-bedarf-zielgruppe',
              },
            },
            {
              id: 'FG220',
              titleKey: 'group.FG220.title',
              route: {
                name: 'gesuche-gruppe-id-richtlinien-und-bedarf-gesuchsart',
              },
              setQuestionDataEndpoint: '/subtype',
            },
            {
              id: 'FG230',
              titleKey: 'group.FG230.title',
              route: {
                name: 'gesuche-gruppe-id-richtlinien-und-bedarf-abrechnung-von-sammelgesuchen',
              },
            },
            {
              id: 'FG235',
              titleKey: 'group.FG235.title',
              route: {
                name: 'gesuche-gruppe-id-richtlinien-und-bedarf-sammelgesuche-zeitraum',
              },
              setQuestionDataEndpoint: '/time-period',
            },
            {
              id: 'FG240',
              titleKey: 'group.FG240.title',
              route: {
                name: 'gesuche-gruppe-id-richtlinien-und-bedarf-sammelgesuche-download',
              },
            },
            {
              id: 'FG245',
              titleKey: 'group.FG245.title',
              route: {
                name: 'gesuche-gruppe-id-richtlinien-und-bedarf-sammelgesuche-upload',
              },
              setQuestionDataEndpoint: '/group-collection-list-document',
            },
            {
              id: 'FG250',
              titleKey: 'group.FG250.title',
              route: {
                name: 'gesuche-gruppe-id-richtlinien-und-bedarf-gruppenzusammensetzung',
              },
              setQuestionDataEndpoint: '/group-composition',
            },
            {
              id: 'FG260',
              titleKey: 'group.FG260.title',
              route: {
                name: 'gesuche-gruppe-id-richtlinien-und-bedarf-anzahl-gruppen',
              },
              setQuestionDataEndpoint: '/number-of-groups',
            },
            {
              id: 'FG270',
              titleKey: 'group.FG270.title',
              route: {
                name: 'gesuche-gruppe-id-richtlinien-und-bedarf-anzahl-teilnehmende',
              },
              setQuestionDataEndpoint: '/number-of-disabled-persons',
            },
            {
              id: 'FG280',
              titleKey: 'group.FG280.title',
              route: {
                name: 'gesuche-gruppe-id-richtlinien-und-bedarf-anzahl-tage',
              },
              setQuestionDataEndpoint: '/number-of-days',
            },
            {
              id: 'FG290',
              titleKey: 'group.FG290.title',
              route: {
                name: 'gesuche-gruppe-id-richtlinien-und-bedarf-anzahl-teilnehmertage',
              },
              setQuestionDataEndpoint: '/average-participant-days',
            },
          ],
        },
        {
          titleKey: 'group.description',
          questions: [
            {
              id: 'FG300',
              titleKey: 'group.FG300.title',
              route: {
                name: 'gesuche-gruppe-id-beschreibung-angebot-thema-gruppenaktivitaet',
              },
              setQuestionDataEndpoint: '/topic',
            },
            {
              id: 'FG310',
              titleKey: 'group.FG310.title',
              route: {
                name: 'gesuche-gruppe-id-beschreibung-angebot-zielsetzung',
              },
              setQuestionDataEndpoint: '/objective',
            },
            {
              id: 'FG320',
              titleKey: 'group.FG320.title',
              route: {
                name: 'gesuche-gruppe-id-beschreibung-angebot-land',
              },
              setQuestionDataEndpoint: '/destination-country-code',
            },
            {
              id: 'FG330',
              titleKey: 'group.FG330.title',
              route: {
                name: 'gesuche-gruppe-id-beschreibung-angebot-ort',
              },
              setQuestionDataEndpoint: '/destination-location',
            },
            {
              id: 'FG340',
              titleKey: 'group.FG340.title',
              route: {
                name: 'gesuche-gruppe-id-beschreibung-angebot-zeitraum',
              },
              setQuestionDataEndpoint: '/time-period',
            },
          ],
        },
        {
          titleKey: 'group.budget',
          questions: [
            {
              id: 'FG400',
              titleKey: 'group.FG400.title',
              route: {
                name: 'gesuche-gruppe-id-budget-ausgaben',
              },
              setQuestionDataEndpoint: '/expenses',
            },
            {
              id: 'FG410',
              titleKey: 'group.FG410.title',
              route: {
                name: 'gesuche-gruppe-id-budget-einnahmen',
              },
              setQuestionDataEndpoint: '/budget-available',
            },
            {
              id: 'FG420',
              titleKey: 'group.FG420.title',
              route: {
                name: 'gesuche-gruppe-id-budget-defizit-gruppenaktivitaet',
              },
            },
            {
              id: 'FG430',
              titleKey: 'group.FG430.title',
              route: {
                name: 'gesuche-gruppe-id-budget-defizit-sammelgesuch',
              },
              setQuestionDataEndpoint: '/deficit',
            },
          ],
        },
        {
          titleKey: 'group.dam-conditions',
          questions: [
            {
              id: 'FG500',
              titleKey: 'group.FG500.title',
              route: {
                name: 'gesuche-gruppe-id-dam-bedingungen-inklusionskriterien',
              },
              setQuestionDataEndpoint: '/inclusion-criteria',
            },
            {
              id: 'FG510',
              titleKey: 'group.FG510.title',
              route: {
                name: 'gesuche-gruppe-id-dam-bedingungen-wirkung',
              },
              setQuestionDataEndpoint: '/appeal',
            },
            {
              id: 'FG520',
              titleKey: 'group.FG520.title',
              route: {
                name: 'gesuche-gruppe-id-dam-bedingungen-hinweis-auf-unterstuetzung',
              },
              setQuestionDataEndpoint: '/visibility',
            },
            {
              id: 'FG530',
              titleKey: 'group.FG530.title',
              route: {
                name: 'gesuche-gruppe-id-dam-bedingungen-bildnachweis',
              },
            },
          ],
        },
        {
          titleKey: 'group.contact',
          questions: [
            {
              id: 'FG600',
              titleKey: 'group.FG600.title',
              route: {
                name: 'gesuche-gruppe-id-kontaktdaten-kontaktdaten',
              },
              setQuestionDataEndpoint: '/contacts',
            },
            {
              id: 'FG620',
              titleKey: 'group.FG620.title',
              route: {
                name: 'gesuche-gruppe-id-kontaktdaten-bemerkungen',
              },
              setQuestionDataEndpoint: '/comments',
            },
            {
              id: 'FG610',
              titleKey: 'group.FG610.title',
              route: {
                name: 'gesuche-gruppe-id-datenpruefung',
              },
              ignoreInQuestionCount: true,
            },
            {
              id: 'FG700',
              titleKey: 'group.FG700.title',
              route: {
                name: 'gesuche-gruppe-id-abschluss',
              },
              ignoreInQuestionCount: true,
            },
          ],
        },
      ],

      data: {
        FG200: { read: null },
        FG210: { read: null },
        FG220: { subtype: null },
        FG230: { accept: null },
        FG335: { start: null, end: null },
        FG245: { applicationSheet: null },
        FG250: { composition: null },
        FG260: { numberOfGroups: null },
        FG270: { numberOfDisabledPersons: null },
        FG280: { numberOfDays: null },
        FG290: { averageParticipantDays: null },
        FG300: { topic: null },
        FG310: { objective: null },
        FG320: { destinationCountryCode: null },
        FG330: { destinationLocation: null },
        FG340: { start: null, end: null },
        FG400: { expenses: [] },
        FG410: { budgetAvailable: [] },
        FG420: { correct: null },
        FG430: { deficit: null },
        FG500: { inclusionCriteria: [] },
        FG510: { appeal: null },
        FG520: { visibility: [] },
        FG530: { accept: null },
        FG600: {
          id: null,
          salutCode: null,
          firstname: null,
          lastname: null,
          street: null,
          houseNumber: null,
          zipCode: null,
          location: null,
          phone: null,
          email: null,
        },
        FG620: {
          comments: null,
        },
      },
    }
  }
}

export default new Store()
