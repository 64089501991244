
export default {
  computed: {
    fields() {
      return this.$refs.observer.fields
    },
  },

  methods: {
    async onSubmit() {
      const success = await this.$refs.observer.validate()
      if (success) {
        this.$emit('submit')
        this.$nextTick(this.$refs.observer?.reset)

        return
      }

      this.$nextTick(() => {
        const errors = Object.entries(this.$refs.observer.errors)
          .map(([key, value]) => ({ key, value }))
          .filter((error) => error.value.length)
        this.$refs.observer.refs[errors[0].key].$el.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      })
    },
    setErrors(...args) {
      this.$refs.observer.setErrors(...args)
    },
  },
}
