
export default {
  components: {},

  props: {
    isDiscrete: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {}
  },

  computed: {},

  methods: {},
}
