function loadAnalytics({ $config, $gtm, $fb }) {
  $gtm.init($config.gtmId)
  $fb.enable()
  initActiveCampaign()
}

function checkAnalytics(context) {
  const cookie = localStorage.getItem('analyticsAccepted')

  return {
    shown: cookie !== null,
    accepted: cookie === true || cookie === 'true',
  }
}

function removeAnalytics(context) {
  localStorage.removeItem('analyticsAccepted')

  window.location.reload()
}

function setAnalyticsAccepted(context, accepted) {
  localStorage.setItem('analyticsAccepted', accepted)
}

function initActiveCampaign() {
  (function (e, t, o, n, p, r, i) { e.visitorGlobalObjectAlias = n; e[e.visitorGlobalObjectAlias] = e[e.visitorGlobalObjectAlias] || function () { (e[e.visitorGlobalObjectAlias].q = e[e.visitorGlobalObjectAlias].q || []).push(arguments) }; e[e.visitorGlobalObjectAlias].l = (new Date).getTime(); r = t.createElement("script"); r.src = o; r.async = true; i = t.getElementsByTagName("script")[0]; i.parentNode.insertBefore(r, i) })(window, document, "https://diffuser-cdn.app-us1.com/diffuser/diffuser.js", "vgo"); // eslint-disable-line
  window.vgo('setAccount', '610447126')
  window.vgo('setTrackByDefault', true)

  window.vgo('process')
}

export default (context, inject) => {
  // we disabled opt-in for now
  loadAnalytics(context)

  const status = checkAnalytics(context)
  if (status.accepted) {
    // we disabled opt-in for now
    // loadAnalytics(context)
    inject('loadAnalytics', () => console.warn('Analytics already loaded'))
  } else {
    inject('loadAnalytics', (...args) => loadAnalytics(context, ...args))
  }

  inject('checkAnalytics', (...args) => checkAnalytics(context, ...args))
  inject('removeAnalytics', (...args) => removeAnalytics(context, ...args))
  inject('setAnalyticsAccepted', (...args) =>
    setAnalyticsAccepted(context, ...args)
  )
}
