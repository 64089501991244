
import CaretDown from '~/components/icons/CaretDown'
import Times from '~/components/icons/Times'

export default {
  inheritAttrs: false,
  props: {
    rules: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    border: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      OpenIndicator: {
        render: (h) =>
          h(CaretDown, {
            class: 'mr-1 mb-1 origin-center h-3 w-3',
          }),
      },
      Deselect: {
        render: (h) =>
          h(Times, {
            class: 'ml-1 h-3 w-3',
          }),
      },
    }
  },

  methods: {
    reduce(option) {
      return option.value
    },
  },
}
