
import SimpleCookieBanner from './SimpleCookieBanner'

export default {
  render(createElement) {
    if (this.$isServer) {
      return
    }

    const analyticsStatus = this.$checkAnalytics()
    if (analyticsStatus.shown) {
      return
    }

    if (['/cookies', '/datenschutz', '/impressum'].includes(this.$route.path)) {
      return
    }

    return createElement(SimpleCookieBanner, {
      on: {
        closed: (event) => {
          this.$setAnalyticsAccepted(true)
          this.$forceUpdate()
        },
      },
    })
  },
}
