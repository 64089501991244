// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/svg/cloud.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/images/svg/border-b.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../assets/images/png/border-b.png");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../assets/images/png/cloud.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".menu-anim[data-v-2dd291d5]{overflow:hidden;transition:all .25s ease}.logo-button[data-v-2dd291d5]:after{content:\"\";top:50%;left:50%;transform:translate(-50%,-50%);width:15rem;height:10.75rem;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");transition:all .6s ease;display:block;position:absolute;z-index:40;background-position:50%;background-repeat:no-repeat;background-size:contain;opacity:0}@media (min-width:1024px){.logo-button[data-v-2dd291d5]:after{width:22.5rem;height:14rem}}.logo-button.is-not-discrete[data-v-2dd291d5]:after{opacity:1}.bg-border-b[data-v-2dd291d5]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-position:bottom}.bg-gradient[data-v-2dd291d5]{height:3.75rem;background:linear-gradient(0deg,rgba(0,115,122,0),#00737a 20%)}@media (-ms-high-contrast:active),(-ms-high-contrast:none){.bg-border-b[data-v-2dd291d5]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")!important}.logo-button.is-not-discrete[data-v-2dd291d5]:after{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ")!important}.ie-logo[data-v-2dd291d5]{height:65px}}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
