import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _121b4776 = () => interopDefault(import('../pages/downloads.vue' /* webpackChunkName: "pages/downloads" */))
const _a56c8d86 = () => interopDefault(import('../pages/gesuche/index.vue' /* webpackChunkName: "pages/gesuche/index" */))
const _5e96a5f4 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _1e1d7f86 = () => interopDefault(import('../pages/gesuche/admin/index.vue' /* webpackChunkName: "pages/gesuche/admin/index" */))
const _6123c291 = () => interopDefault(import('../pages/gesuche/einzelperson.vue' /* webpackChunkName: "pages/gesuche/einzelperson" */))
const _26f9f3c4 = () => interopDefault(import('../pages/gesuche/einzelperson/email.vue' /* webpackChunkName: "pages/gesuche/einzelperson/email" */))
const _b2bbd0da = () => interopDefault(import('../pages/gesuche/einzelperson/gesuch.vue' /* webpackChunkName: "pages/gesuche/einzelperson/gesuch" */))
const _6b820850 = () => interopDefault(import('../pages/gesuche/einzelperson/verifikation-email.vue' /* webpackChunkName: "pages/gesuche/einzelperson/verifikation-email" */))
const _177c116d = () => interopDefault(import('../pages/gesuche/einzelperson/_id/abschluss.vue' /* webpackChunkName: "pages/gesuche/einzelperson/_id/abschluss" */))
const _59aa4bc7 = () => interopDefault(import('../pages/gesuche/einzelperson/_id/datenpruefung.vue' /* webpackChunkName: "pages/gesuche/einzelperson/_id/datenpruefung" */))
const _9e730b18 = () => interopDefault(import('../pages/gesuche/einzelperson/_id/budget/ausgaben-anschaffung.vue' /* webpackChunkName: "pages/gesuche/einzelperson/_id/budget/ausgaben-anschaffung" */))
const _fa277936 = () => interopDefault(import('../pages/gesuche/einzelperson/_id/budget/ausgaben-ferienreise.vue' /* webpackChunkName: "pages/gesuche/einzelperson/_id/budget/ausgaben-ferienreise" */))
const _2891e3a8 = () => interopDefault(import('../pages/gesuche/einzelperson/_id/budget/ausgaben-freizeitaktivitaet.vue' /* webpackChunkName: "pages/gesuche/einzelperson/_id/budget/ausgaben-freizeitaktivitaet" */))
const _7f68aa8c = () => interopDefault(import('../pages/gesuche/einzelperson/_id/budget/defizit.vue' /* webpackChunkName: "pages/gesuche/einzelperson/_id/budget/defizit" */))
const _5e587f44 = () => interopDefault(import('../pages/gesuche/einzelperson/_id/budget/einnahmen.vue' /* webpackChunkName: "pages/gesuche/einzelperson/_id/budget/einnahmen" */))
const _0c88be8b = () => interopDefault(import('../pages/gesuche/einzelperson/_id/eingabe/einstieg.vue' /* webpackChunkName: "pages/gesuche/einzelperson/_id/eingabe/einstieg" */))
const _5bb519f1 = () => interopDefault(import('../pages/gesuche/einzelperson/_id/eingabe/identifikation.vue' /* webpackChunkName: "pages/gesuche/einzelperson/_id/eingabe/identifikation" */))
const _06e617d8 = () => interopDefault(import('../pages/gesuche/einzelperson/_id/eingabe/information-fremdausfueller.vue' /* webpackChunkName: "pages/gesuche/einzelperson/_id/eingabe/information-fremdausfueller" */))
const _731f63b3 = () => interopDefault(import('../pages/gesuche/einzelperson/_id/kontaktdaten/fremdeingabe.vue' /* webpackChunkName: "pages/gesuche/einzelperson/_id/kontaktdaten/fremdeingabe" */))
const _20cde5dc = () => interopDefault(import('../pages/gesuche/einzelperson/_id/kontaktdaten/selbsteingabe.vue' /* webpackChunkName: "pages/gesuche/einzelperson/_id/kontaktdaten/selbsteingabe" */))
const _b660aa4c = () => interopDefault(import('../pages/gesuche/einzelperson/_id/kontaktdaten/vertretung.vue' /* webpackChunkName: "pages/gesuche/einzelperson/_id/kontaktdaten/vertretung" */))
const _5d6b1190 = () => interopDefault(import('../pages/gesuche/einzelperson/_id/qualifikation/beleg-steuerbares-einkommen.vue' /* webpackChunkName: "pages/gesuche/einzelperson/_id/qualifikation/beleg-steuerbares-einkommen" */))
const _4dee3887 = () => interopDefault(import('../pages/gesuche/einzelperson/_id/qualifikation/belege-unterstuetzung.vue' /* webpackChunkName: "pages/gesuche/einzelperson/_id/qualifikation/belege-unterstuetzung" */))
const _858cd9b2 = () => interopDefault(import('../pages/gesuche/einzelperson/_id/qualifikation/einkommensbasis.vue' /* webpackChunkName: "pages/gesuche/einzelperson/_id/qualifikation/einkommensbasis" */))
const _268662da = () => interopDefault(import('../pages/gesuche/einzelperson/_id/qualifikation/geburtsdatum.vue' /* webpackChunkName: "pages/gesuche/einzelperson/_id/qualifikation/geburtsdatum" */))
const _d19ea09c = () => interopDefault(import('../pages/gesuche/einzelperson/_id/qualifikation/steuerbares-einkommen.vue' /* webpackChunkName: "pages/gesuche/einzelperson/_id/qualifikation/steuerbares-einkommen" */))
const _210eb2ac = () => interopDefault(import('../pages/gesuche/einzelperson/_id/verwendungszweck/anschaffungsdatum.vue' /* webpackChunkName: "pages/gesuche/einzelperson/_id/verwendungszweck/anschaffungsdatum" */))
const _11d24412 = () => interopDefault(import('../pages/gesuche/einzelperson/_id/verwendungszweck/anschaffungsgegenstand.vue' /* webpackChunkName: "pages/gesuche/einzelperson/_id/verwendungszweck/anschaffungsgegenstand" */))
const _3b16212b = () => interopDefault(import('../pages/gesuche/einzelperson/_id/verwendungszweck/anzahl-personen.vue' /* webpackChunkName: "pages/gesuche/einzelperson/_id/verwendungszweck/anzahl-personen" */))
const _579e8b95 = () => interopDefault(import('../pages/gesuche/einzelperson/_id/verwendungszweck/begleitperson.vue' /* webpackChunkName: "pages/gesuche/einzelperson/_id/verwendungszweck/begleitperson" */))
const _4773d07e = () => interopDefault(import('../pages/gesuche/einzelperson/_id/verwendungszweck/begleitpersonen.vue' /* webpackChunkName: "pages/gesuche/einzelperson/_id/verwendungszweck/begleitpersonen" */))
const _3f198342 = () => interopDefault(import('../pages/gesuche/einzelperson/_id/verwendungszweck/begruendung-begleitperson.vue' /* webpackChunkName: "pages/gesuche/einzelperson/_id/verwendungszweck/begruendung-begleitperson" */))
const _44adcb22 = () => interopDefault(import('../pages/gesuche/einzelperson/_id/verwendungszweck/datum-reise-freizeitaktivitaet.vue' /* webpackChunkName: "pages/gesuche/einzelperson/_id/verwendungszweck/datum-reise-freizeitaktivitaet" */))
const _4f8eae4a = () => interopDefault(import('../pages/gesuche/einzelperson/_id/verwendungszweck/gesuchsgrund.vue' /* webpackChunkName: "pages/gesuche/einzelperson/_id/verwendungszweck/gesuchsgrund" */))
const _2bcadc76 = () => interopDefault(import('../pages/gesuche/einzelperson/_id/verwendungszweck/inland-ausland.vue' /* webpackChunkName: "pages/gesuche/einzelperson/_id/verwendungszweck/inland-ausland" */))
const _5c8fc832 = () => interopDefault(import('../pages/gesuche/einzelperson/_id/verwendungszweck/land-ort.vue' /* webpackChunkName: "pages/gesuche/einzelperson/_id/verwendungszweck/land-ort" */))
const _6594ea24 = () => interopDefault(import('../pages/gesuche/einzelperson/_id/verwendungszweck/motivation.vue' /* webpackChunkName: "pages/gesuche/einzelperson/_id/verwendungszweck/motivation" */))
const _5e9af316 = () => interopDefault(import('../pages/gesuche/einzelperson/_id/verwendungszweck/organisationsform.vue' /* webpackChunkName: "pages/gesuche/einzelperson/_id/verwendungszweck/organisationsform" */))
const _4fb5c849 = () => interopDefault(import('../pages/gesuche/einzelperson/_id/verwendungszweck/ort.vue' /* webpackChunkName: "pages/gesuche/einzelperson/_id/verwendungszweck/ort" */))
const _628b293f = () => interopDefault(import('../pages/gesuche/einzelperson/_id/index.vue' /* webpackChunkName: "pages/gesuche/einzelperson/_id/index" */))
const _f4f73c40 = () => interopDefault(import('../pages/gesuche/gruppe.vue' /* webpackChunkName: "pages/gesuche/gruppe" */))
const _278fff3a = () => interopDefault(import('../pages/gesuche/gruppe/index.vue' /* webpackChunkName: "pages/gesuche/gruppe/index" */))
const _ca86a3a6 = () => interopDefault(import('../pages/gesuche/gruppe/email.vue' /* webpackChunkName: "pages/gesuche/gruppe/email" */))
const _2a3830a1 = () => interopDefault(import('../pages/gesuche/gruppe/verifikation-email.vue' /* webpackChunkName: "pages/gesuche/gruppe/verifikation-email" */))
const _e6f79c64 = () => interopDefault(import('../pages/gesuche/gruppe/_id/index.vue' /* webpackChunkName: "pages/gesuche/gruppe/_id/index" */))
const _15903a7c = () => interopDefault(import('../pages/gesuche/gruppe/_id/abschluss.vue' /* webpackChunkName: "pages/gesuche/gruppe/_id/abschluss" */))
const _70555556 = () => interopDefault(import('../pages/gesuche/gruppe/_id/datenpruefung.vue' /* webpackChunkName: "pages/gesuche/gruppe/_id/datenpruefung" */))
const _31e6967a = () => interopDefault(import('../pages/gesuche/gruppe/_id/beschreibung-angebot/land.vue' /* webpackChunkName: "pages/gesuche/gruppe/_id/beschreibung-angebot/land" */))
const _c6f4129c = () => interopDefault(import('../pages/gesuche/gruppe/_id/beschreibung-angebot/ort.vue' /* webpackChunkName: "pages/gesuche/gruppe/_id/beschreibung-angebot/ort" */))
const _7d7843fa = () => interopDefault(import('../pages/gesuche/gruppe/_id/beschreibung-angebot/thema-gruppenaktivitaet.vue' /* webpackChunkName: "pages/gesuche/gruppe/_id/beschreibung-angebot/thema-gruppenaktivitaet" */))
const _d20e50a8 = () => interopDefault(import('../pages/gesuche/gruppe/_id/beschreibung-angebot/zeitraum.vue' /* webpackChunkName: "pages/gesuche/gruppe/_id/beschreibung-angebot/zeitraum" */))
const _0d7c5401 = () => interopDefault(import('../pages/gesuche/gruppe/_id/beschreibung-angebot/zielsetzung.vue' /* webpackChunkName: "pages/gesuche/gruppe/_id/beschreibung-angebot/zielsetzung" */))
const _2a242498 = () => interopDefault(import('../pages/gesuche/gruppe/_id/budget/ausgaben.vue' /* webpackChunkName: "pages/gesuche/gruppe/_id/budget/ausgaben" */))
const _38fea27f = () => interopDefault(import('../pages/gesuche/gruppe/_id/budget/defizit-gruppenaktivitaet.vue' /* webpackChunkName: "pages/gesuche/gruppe/_id/budget/defizit-gruppenaktivitaet" */))
const _127187a8 = () => interopDefault(import('../pages/gesuche/gruppe/_id/budget/defizit-sammelgesuch.vue' /* webpackChunkName: "pages/gesuche/gruppe/_id/budget/defizit-sammelgesuch" */))
const _4825d755 = () => interopDefault(import('../pages/gesuche/gruppe/_id/budget/einnahmen.vue' /* webpackChunkName: "pages/gesuche/gruppe/_id/budget/einnahmen" */))
const _73411519 = () => interopDefault(import('../pages/gesuche/gruppe/_id/dam-bedingungen/bildnachweis.vue' /* webpackChunkName: "pages/gesuche/gruppe/_id/dam-bedingungen/bildnachweis" */))
const _78ab0fbc = () => interopDefault(import('../pages/gesuche/gruppe/_id/dam-bedingungen/hinweis-auf-unterstuetzung.vue' /* webpackChunkName: "pages/gesuche/gruppe/_id/dam-bedingungen/hinweis-auf-unterstuetzung" */))
const _5e9101aa = () => interopDefault(import('../pages/gesuche/gruppe/_id/dam-bedingungen/inklusionskriterien.vue' /* webpackChunkName: "pages/gesuche/gruppe/_id/dam-bedingungen/inklusionskriterien" */))
const _3499c729 = () => interopDefault(import('../pages/gesuche/gruppe/_id/dam-bedingungen/wirkung.vue' /* webpackChunkName: "pages/gesuche/gruppe/_id/dam-bedingungen/wirkung" */))
const _040f0088 = () => interopDefault(import('../pages/gesuche/gruppe/_id/kontaktdaten/bemerkungen.vue' /* webpackChunkName: "pages/gesuche/gruppe/_id/kontaktdaten/bemerkungen" */))
const _268d662b = () => interopDefault(import('../pages/gesuche/gruppe/_id/kontaktdaten/kontaktdaten.vue' /* webpackChunkName: "pages/gesuche/gruppe/_id/kontaktdaten/kontaktdaten" */))
const _1906c404 = () => interopDefault(import('../pages/gesuche/gruppe/_id/richtlinien-und-bedarf/abrechnung-von-sammelgesuchen.vue' /* webpackChunkName: "pages/gesuche/gruppe/_id/richtlinien-und-bedarf/abrechnung-von-sammelgesuchen" */))
const _442f3f1f = () => interopDefault(import('../pages/gesuche/gruppe/_id/richtlinien-und-bedarf/anzahl-gruppen.vue' /* webpackChunkName: "pages/gesuche/gruppe/_id/richtlinien-und-bedarf/anzahl-gruppen" */))
const _264853a2 = () => interopDefault(import('../pages/gesuche/gruppe/_id/richtlinien-und-bedarf/anzahl-tage.vue' /* webpackChunkName: "pages/gesuche/gruppe/_id/richtlinien-und-bedarf/anzahl-tage" */))
const _aab4d844 = () => interopDefault(import('../pages/gesuche/gruppe/_id/richtlinien-und-bedarf/anzahl-teilnehmende.vue' /* webpackChunkName: "pages/gesuche/gruppe/_id/richtlinien-und-bedarf/anzahl-teilnehmende" */))
const _195f35a8 = () => interopDefault(import('../pages/gesuche/gruppe/_id/richtlinien-und-bedarf/anzahl-teilnehmertage.vue' /* webpackChunkName: "pages/gesuche/gruppe/_id/richtlinien-und-bedarf/anzahl-teilnehmertage" */))
const _f9f86e14 = () => interopDefault(import('../pages/gesuche/gruppe/_id/richtlinien-und-bedarf/gesuchsart.vue' /* webpackChunkName: "pages/gesuche/gruppe/_id/richtlinien-und-bedarf/gesuchsart" */))
const _05fbf492 = () => interopDefault(import('../pages/gesuche/gruppe/_id/richtlinien-und-bedarf/gruppenzusammensetzung.vue' /* webpackChunkName: "pages/gesuche/gruppe/_id/richtlinien-und-bedarf/gruppenzusammensetzung" */))
const _32f138c8 = () => interopDefault(import('../pages/gesuche/gruppe/_id/richtlinien-und-bedarf/richtlinien.vue' /* webpackChunkName: "pages/gesuche/gruppe/_id/richtlinien-und-bedarf/richtlinien" */))
const _3c63a6aa = () => interopDefault(import('../pages/gesuche/gruppe/_id/richtlinien-und-bedarf/sammelgesuche-download.vue' /* webpackChunkName: "pages/gesuche/gruppe/_id/richtlinien-und-bedarf/sammelgesuche-download" */))
const _c1681738 = () => interopDefault(import('../pages/gesuche/gruppe/_id/richtlinien-und-bedarf/sammelgesuche-upload.vue' /* webpackChunkName: "pages/gesuche/gruppe/_id/richtlinien-und-bedarf/sammelgesuche-upload" */))
const _f5ed3d80 = () => interopDefault(import('../pages/gesuche/gruppe/_id/richtlinien-und-bedarf/sammelgesuche-zeitraum.vue' /* webpackChunkName: "pages/gesuche/gruppe/_id/richtlinien-und-bedarf/sammelgesuche-zeitraum" */))
const _4ccd3ddc = () => interopDefault(import('../pages/gesuche/gruppe/_id/richtlinien-und-bedarf/zielgruppe.vue' /* webpackChunkName: "pages/gesuche/gruppe/_id/richtlinien-und-bedarf/zielgruppe" */))
const _5299fcac = () => interopDefault(import('../pages/gesuche/kontakte.vue' /* webpackChunkName: "pages/gesuche/kontakte" */))
const _f609b94e = () => interopDefault(import('../pages/gesuche/projekte.vue' /* webpackChunkName: "pages/gesuche/projekte" */))
const _e2a7f9c8 = () => interopDefault(import('../pages/gesuche/projekte/index.vue' /* webpackChunkName: "pages/gesuche/projekte/index" */))
const _3d30b0e6 = () => interopDefault(import('../pages/gesuche/projekte/email.vue' /* webpackChunkName: "pages/gesuche/projekte/email" */))
const _191e2288 = () => interopDefault(import('../pages/gesuche/projekte/verifikation-email.vue' /* webpackChunkName: "pages/gesuche/projekte/verifikation-email" */))
const _09539ff2 = () => interopDefault(import('../pages/gesuche/projekte/_id/index.vue' /* webpackChunkName: "pages/gesuche/projekte/_id/index" */))
const _9b531796 = () => interopDefault(import('../pages/gesuche/projekte/_id/abschluss.vue' /* webpackChunkName: "pages/gesuche/projekte/_id/abschluss" */))
const _0dcefd60 = () => interopDefault(import('../pages/gesuche/projekte/_id/budget/absage-institutionen.vue' /* webpackChunkName: "pages/gesuche/projekte/_id/budget/absage-institutionen" */))
const _04ffa5c1 = () => interopDefault(import('../pages/gesuche/projekte/_id/budget/angefragte-institutionen.vue' /* webpackChunkName: "pages/gesuche/projekte/_id/budget/angefragte-institutionen" */))
const _7f4c2c83 = () => interopDefault(import('../pages/gesuche/projekte/_id/budget/aufwand.vue' /* webpackChunkName: "pages/gesuche/projekte/_id/budget/aufwand" */))
const _6fffb516 = () => interopDefault(import('../pages/gesuche/projekte/_id/budget/beitrag-stiftung-denk-an-mich.vue' /* webpackChunkName: "pages/gesuche/projekte/_id/budget/beitrag-stiftung-denk-an-mich" */))
const _6de7c4f2 = () => interopDefault(import('../pages/gesuche/projekte/_id/budget/defizit.vue' /* webpackChunkName: "pages/gesuche/projekte/_id/budget/defizit" */))
const _e7830de0 = () => interopDefault(import('../pages/gesuche/projekte/_id/budget/ertrag.vue' /* webpackChunkName: "pages/gesuche/projekte/_id/budget/ertrag" */))
const _97e792ba = () => interopDefault(import('../pages/gesuche/projekte/_id/budget/institutionelle-spenden.vue' /* webpackChunkName: "pages/gesuche/projekte/_id/budget/institutionelle-spenden" */))
const _a149d030 = () => interopDefault(import('../pages/gesuche/projekte/_id/dam-bedingungen/abschlussbericht-und-bildnachweis.vue' /* webpackChunkName: "pages/gesuche/projekte/_id/dam-bedingungen/abschlussbericht-und-bildnachweis" */))
const _4b44b123 = () => interopDefault(import('../pages/gesuche/projekte/_id/dam-bedingungen/hinweis-auf-unterstuetzung.vue' /* webpackChunkName: "pages/gesuche/projekte/_id/dam-bedingungen/hinweis-auf-unterstuetzung" */))
const _014e04a2 = () => interopDefault(import('../pages/gesuche/projekte/_id/kontaktdaten/bemerkungen.vue' /* webpackChunkName: "pages/gesuche/projekte/_id/kontaktdaten/bemerkungen" */))
const _d27950f4 = () => interopDefault(import('../pages/gesuche/projekte/_id/kontaktdaten/datenpruefung.vue' /* webpackChunkName: "pages/gesuche/projekte/_id/kontaktdaten/datenpruefung" */))
const _d6f9e438 = () => interopDefault(import('../pages/gesuche/projekte/_id/kontaktdaten/kontaktdaten.vue' /* webpackChunkName: "pages/gesuche/projekte/_id/kontaktdaten/kontaktdaten" */))
const _61e086ae = () => interopDefault(import('../pages/gesuche/projekte/_id/projektdaten/anzahl-personen.vue' /* webpackChunkName: "pages/gesuche/projekte/_id/projektdaten/anzahl-personen" */))
const _405c63ec = () => interopDefault(import('../pages/gesuche/projekte/_id/projektdaten/eingabefrist.vue' /* webpackChunkName: "pages/gesuche/projekte/_id/projektdaten/eingabefrist" */))
const _36712282 = () => interopDefault(import('../pages/gesuche/projekte/_id/projektdaten/inklusion.vue' /* webpackChunkName: "pages/gesuche/projekte/_id/projektdaten/inklusion" */))
const _2f48d258 = () => interopDefault(import('../pages/gesuche/projekte/_id/projektdaten/konzept.vue' /* webpackChunkName: "pages/gesuche/projekte/_id/projektdaten/konzept" */))
const _60d3c48e = () => interopDefault(import('../pages/gesuche/projekte/_id/projektdaten/projektbeschreibung.vue' /* webpackChunkName: "pages/gesuche/projekte/_id/projektdaten/projektbeschreibung" */))
const _2337b783 = () => interopDefault(import('../pages/gesuche/projekte/_id/projektdaten/projektkategorie.vue' /* webpackChunkName: "pages/gesuche/projekte/_id/projektdaten/projektkategorie" */))
const _98b62d70 = () => interopDefault(import('../pages/gesuche/projekte/_id/projektdaten/projektlaufzeit.vue' /* webpackChunkName: "pages/gesuche/projekte/_id/projektdaten/projektlaufzeit" */))
const _15407a4a = () => interopDefault(import('../pages/gesuche/projekte/_id/projektdaten/projekttitel.vue' /* webpackChunkName: "pages/gesuche/projekte/_id/projektdaten/projekttitel" */))
const _a3038544 = () => interopDefault(import('../pages/gesuche/projekte/_id/projektdaten/wirkung.vue' /* webpackChunkName: "pages/gesuche/projekte/_id/projektdaten/wirkung" */))
const _fd86681c = () => interopDefault(import('../pages/gesuche/projekte/_id/richtlinien-und-zielgruppe/gesuchsart.vue' /* webpackChunkName: "pages/gesuche/projekte/_id/richtlinien-und-zielgruppe/gesuchsart" */))
const _1cce2ba4 = () => interopDefault(import('../pages/gesuche/projekte/_id/richtlinien-und-zielgruppe/kommerzieller-hintergrund.vue' /* webpackChunkName: "pages/gesuche/projekte/_id/richtlinien-und-zielgruppe/kommerzieller-hintergrund" */))
const _8d6dacd4 = () => interopDefault(import('../pages/gesuche/projekte/_id/richtlinien-und-zielgruppe/projektleitung.vue' /* webpackChunkName: "pages/gesuche/projekte/_id/richtlinien-und-zielgruppe/projektleitung" */))
const _084ed568 = () => interopDefault(import('../pages/gesuche/projekte/_id/richtlinien-und-zielgruppe/richtlinien.vue' /* webpackChunkName: "pages/gesuche/projekte/_id/richtlinien-und-zielgruppe/richtlinien" */))
const _505b37e4 = () => interopDefault(import('../pages/gesuche/projekte/_id/richtlinien-und-zielgruppe/zielgruppe.vue' /* webpackChunkName: "pages/gesuche/projekte/_id/richtlinien-und-zielgruppe/zielgruppe" */))
const _27fbcc2a = () => interopDefault(import('../pages/gesuche/kurzcheck/einzelperson.vue' /* webpackChunkName: "pages/gesuche/kurzcheck/einzelperson" */))
const _a9b45b8e = () => interopDefault(import('../pages/gesuche/kurzcheck/gruppe.vue' /* webpackChunkName: "pages/gesuche/kurzcheck/gruppe" */))
const _6ffc3d1c = () => interopDefault(import('../pages/gesuche/kurzcheck/projekte.vue' /* webpackChunkName: "pages/gesuche/kurzcheck/projekte" */))
const _770ff060 = () => interopDefault(import('../pages/gesuche/einzelperson-abrechnung/_id.vue' /* webpackChunkName: "pages/gesuche/einzelperson-abrechnung/_id" */))
const _4c76c49c = () => interopDefault(import('../pages/gesuche/einzelperson-abrechnung/_id/absage.vue' /* webpackChunkName: "pages/gesuche/einzelperson-abrechnung/_id/absage" */))
const _504c5988 = () => interopDefault(import('../pages/gesuche/einzelperson-abrechnung/_id/kontaktdaten/adressat-auszahlung.vue' /* webpackChunkName: "pages/gesuche/einzelperson-abrechnung/_id/kontaktdaten/adressat-auszahlung" */))
const _2b16bcba = () => interopDefault(import('../pages/gesuche/einzelperson-abrechnung/_id/kontaktdaten/datenpruefung.vue' /* webpackChunkName: "pages/gesuche/einzelperson-abrechnung/_id/kontaktdaten/datenpruefung" */))
const _279463d0 = () => interopDefault(import('../pages/gesuche/einzelperson-abrechnung/_id/kontaktdaten/drittperson.vue' /* webpackChunkName: "pages/gesuche/einzelperson-abrechnung/_id/kontaktdaten/drittperson" */))
const _17810d6b = () => interopDefault(import('../pages/gesuche/einzelperson-abrechnung/_id/kontaktdaten/kontodaten.vue' /* webpackChunkName: "pages/gesuche/einzelperson-abrechnung/_id/kontaktdaten/kontodaten" */))
const _22daf87e = () => interopDefault(import('../pages/gesuche/einzelperson-abrechnung/_id/kontaktdaten/selbsteingabe.vue' /* webpackChunkName: "pages/gesuche/einzelperson-abrechnung/_id/kontaktdaten/selbsteingabe" */))
const _046756ee = () => interopDefault(import('../pages/gesuche/einzelperson-abrechnung/_id/kontaktdaten/vertretung.vue' /* webpackChunkName: "pages/gesuche/einzelperson-abrechnung/_id/kontaktdaten/vertretung" */))
const _6ced91a2 = () => interopDefault(import('../pages/gesuche/einzelperson-abrechnung/_id/kosten-einnahmen/abrechnungsbereich.vue' /* webpackChunkName: "pages/gesuche/einzelperson-abrechnung/_id/kosten-einnahmen/abrechnungsbereich" */))
const _51e07f3d = () => interopDefault(import('../pages/gesuche/einzelperson-abrechnung/_id/kosten-einnahmen/belege-hochladen.vue' /* webpackChunkName: "pages/gesuche/einzelperson-abrechnung/_id/kosten-einnahmen/belege-hochladen" */))
const _2ed3ce35 = () => interopDefault(import('../pages/gesuche/einzelperson-abrechnung/_id/kosten-einnahmen/bestaetigung-realisierung.vue' /* webpackChunkName: "pages/gesuche/einzelperson-abrechnung/_id/kosten-einnahmen/bestaetigung-realisierung" */))
const _212702bc = () => interopDefault(import('../pages/gesuche/einzelperson-abrechnung/_id/kosten-einnahmen/bild-hochladen.vue' /* webpackChunkName: "pages/gesuche/einzelperson-abrechnung/_id/kosten-einnahmen/bild-hochladen" */))
const _30aab2a0 = () => interopDefault(import('../pages/gesuche/einzelperson-abrechnung/_id/kosten-einnahmen/bildfreigabe.vue' /* webpackChunkName: "pages/gesuche/einzelperson-abrechnung/_id/kosten-einnahmen/bildfreigabe" */))
const _0066b22d = () => interopDefault(import('../pages/gesuche/einzelperson-abrechnung/_id/kosten-einnahmen/defizit.vue' /* webpackChunkName: "pages/gesuche/einzelperson-abrechnung/_id/kosten-einnahmen/defizit" */))
const _03cf75f7 = () => interopDefault(import('../pages/gesuche/einzelperson-abrechnung/_id/kosten-einnahmen/einnahmen.vue' /* webpackChunkName: "pages/gesuche/einzelperson-abrechnung/_id/kosten-einnahmen/einnahmen" */))
const _6c2ed606 = () => interopDefault(import('../pages/gesuche/einzelperson-abrechnung/_id/kosten-einnahmen/kosten-anschaffung.vue' /* webpackChunkName: "pages/gesuche/einzelperson-abrechnung/_id/kosten-einnahmen/kosten-anschaffung" */))
const _c7e34424 = () => interopDefault(import('../pages/gesuche/einzelperson-abrechnung/_id/kosten-einnahmen/kosten-ferienreise.vue' /* webpackChunkName: "pages/gesuche/einzelperson-abrechnung/_id/kosten-einnahmen/kosten-ferienreise" */))
const _5b3f8cff = () => interopDefault(import('../pages/gesuche/einzelperson-abrechnung/_id/kosten-einnahmen/kosten-freizeitaktivitaet.vue' /* webpackChunkName: "pages/gesuche/einzelperson-abrechnung/_id/kosten-einnahmen/kosten-freizeitaktivitaet" */))
const _967f265a = () => interopDefault(import('../pages/gesuche/einzelperson-abrechnung/_id/kosten-einnahmen/verifikation-absage.vue' /* webpackChunkName: "pages/gesuche/einzelperson-abrechnung/_id/kosten-einnahmen/verifikation-absage" */))
const _64b1a8ba = () => interopDefault(import('../pages/gesuche/einzelperson-abrechnung/_id/kosten-einnahmen/wirkung-anschaffung.vue' /* webpackChunkName: "pages/gesuche/einzelperson-abrechnung/_id/kosten-einnahmen/wirkung-anschaffung" */))
const _6498ac76 = () => interopDefault(import('../pages/gesuche/einzelperson-abrechnung/_id/kosten-einnahmen/wirkung-ferien-freizeit.vue' /* webpackChunkName: "pages/gesuche/einzelperson-abrechnung/_id/kosten-einnahmen/wirkung-ferien-freizeit" */))
const _ee2dfebe = () => interopDefault(import('../pages/gesuche/gruppe-abrechnung/_id.vue' /* webpackChunkName: "pages/gesuche/gruppe-abrechnung/_id" */))
const _c75722aa = () => interopDefault(import('../pages/gesuche/gruppe-abrechnung/_id/absage.vue' /* webpackChunkName: "pages/gesuche/gruppe-abrechnung/_id/absage" */))
const _d22c1712 = () => interopDefault(import('../pages/gesuche/gruppe-abrechnung/_id/abrechnung-einzelgruppen/anzahl-tage.vue' /* webpackChunkName: "pages/gesuche/gruppe-abrechnung/_id/abrechnung-einzelgruppen/anzahl-tage" */))
const _34a2e369 = () => interopDefault(import('../pages/gesuche/gruppe-abrechnung/_id/abrechnung-einzelgruppen/anzahl-teilnehmer.vue' /* webpackChunkName: "pages/gesuche/gruppe-abrechnung/_id/abrechnung-einzelgruppen/anzahl-teilnehmer" */))
const _7f9a426a = () => interopDefault(import('../pages/gesuche/gruppe-abrechnung/_id/abrechnung-einzelgruppen/ausgaben.vue' /* webpackChunkName: "pages/gesuche/gruppe-abrechnung/_id/abrechnung-einzelgruppen/ausgaben" */))
const _01d04f52 = () => interopDefault(import('../pages/gesuche/gruppe-abrechnung/_id/abrechnung-einzelgruppen/belege-hochladen.vue' /* webpackChunkName: "pages/gesuche/gruppe-abrechnung/_id/abrechnung-einzelgruppen/belege-hochladen" */))
const _742cf310 = () => interopDefault(import('../pages/gesuche/gruppe-abrechnung/_id/abrechnung-einzelgruppen/defizit.vue' /* webpackChunkName: "pages/gesuche/gruppe-abrechnung/_id/abrechnung-einzelgruppen/defizit" */))
const _73ee6382 = () => interopDefault(import('../pages/gesuche/gruppe-abrechnung/_id/abrechnung-einzelgruppen/einnahmen.vue' /* webpackChunkName: "pages/gesuche/gruppe-abrechnung/_id/abrechnung-einzelgruppen/einnahmen" */))
const _01771e01 = () => interopDefault(import('../pages/gesuche/gruppe-abrechnung/_id/abrechnung-einzelgruppen/teilnehmerliste.vue' /* webpackChunkName: "pages/gesuche/gruppe-abrechnung/_id/abrechnung-einzelgruppen/teilnehmerliste" */))
const _2dd6fab8 = () => interopDefault(import('../pages/gesuche/gruppe-abrechnung/_id/abrechnung-sammelgesuch/abrechnung.vue' /* webpackChunkName: "pages/gesuche/gruppe-abrechnung/_id/abrechnung-sammelgesuch/abrechnung" */))
const _3be336df = () => interopDefault(import('../pages/gesuche/gruppe-abrechnung/_id/abrechnung-sammelgesuch/defizit-schlussabrechnung.vue' /* webpackChunkName: "pages/gesuche/gruppe-abrechnung/_id/abrechnung-sammelgesuch/defizit-schlussabrechnung" */))
const _d2286390 = () => interopDefault(import('../pages/gesuche/gruppe-abrechnung/_id/abrechnung-sammelgesuch/defizit-teilabrechnung.vue' /* webpackChunkName: "pages/gesuche/gruppe-abrechnung/_id/abrechnung-sammelgesuch/defizit-teilabrechnung" */))
const _1f73f5ea = () => interopDefault(import('../pages/gesuche/gruppe-abrechnung/_id/abrechnung-sammelgesuch/schlussabrechnung.vue' /* webpackChunkName: "pages/gesuche/gruppe-abrechnung/_id/abrechnung-sammelgesuch/schlussabrechnung" */))
const _720b8f8c = () => interopDefault(import('../pages/gesuche/gruppe-abrechnung/_id/abrechnung-sammelgesuch/teilabrechnung.vue' /* webpackChunkName: "pages/gesuche/gruppe-abrechnung/_id/abrechnung-sammelgesuch/teilabrechnung" */))
const _d0fd75c0 = () => interopDefault(import('../pages/gesuche/gruppe-abrechnung/_id/abrechnung-sammelgesuch/teilabrechnung-beenden.vue' /* webpackChunkName: "pages/gesuche/gruppe-abrechnung/_id/abrechnung-sammelgesuch/teilabrechnung-beenden" */))
const _5340a6dc = () => interopDefault(import('../pages/gesuche/gruppe-abrechnung/_id/abrechnung-sammelgesuch/teilabrechnung-upload.vue' /* webpackChunkName: "pages/gesuche/gruppe-abrechnung/_id/abrechnung-sammelgesuch/teilabrechnung-upload" */))
const _5288c1c0 = () => interopDefault(import('../pages/gesuche/gruppe-abrechnung/_id/abschluss/verifikation.vue' /* webpackChunkName: "pages/gesuche/gruppe-abrechnung/_id/abschluss/verifikation" */))
const _e2351672 = () => interopDefault(import('../pages/gesuche/gruppe-abrechnung/_id/abschluss/vielen-dank.vue' /* webpackChunkName: "pages/gesuche/gruppe-abrechnung/_id/abschluss/vielen-dank" */))
const _44922356 = () => interopDefault(import('../pages/gesuche/gruppe-abrechnung/_id/bestaetigung-realisierung/bestaetigung-realisierung.vue' /* webpackChunkName: "pages/gesuche/gruppe-abrechnung/_id/bestaetigung-realisierung/bestaetigung-realisierung" */))
const _3408d81d = () => interopDefault(import('../pages/gesuche/gruppe-abrechnung/_id/bestaetigung-realisierung/gesuch-absagen.vue' /* webpackChunkName: "pages/gesuche/gruppe-abrechnung/_id/bestaetigung-realisierung/gesuch-absagen" */))
const _66d1079f = () => interopDefault(import('../pages/gesuche/gruppe-abrechnung/_id/bestaetigung-realisierung/unterlagen-abrechnung.vue' /* webpackChunkName: "pages/gesuche/gruppe-abrechnung/_id/bestaetigung-realisierung/unterlagen-abrechnung" */))
const _d31f8c56 = () => interopDefault(import('../pages/gesuche/gruppe-abrechnung/_id/bestaetigung-realisierung/zielgruppe.vue' /* webpackChunkName: "pages/gesuche/gruppe-abrechnung/_id/bestaetigung-realisierung/zielgruppe" */))
const _bc8a271c = () => interopDefault(import('../pages/gesuche/gruppe-abrechnung/_id/dam-bedingungen/bild-hochladen.vue' /* webpackChunkName: "pages/gesuche/gruppe-abrechnung/_id/dam-bedingungen/bild-hochladen" */))
const _5d4b0b9f = () => interopDefault(import('../pages/gesuche/gruppe-abrechnung/_id/dam-bedingungen/bilder-hochladen.vue' /* webpackChunkName: "pages/gesuche/gruppe-abrechnung/_id/dam-bedingungen/bilder-hochladen" */))
const _714b6f00 = () => interopDefault(import('../pages/gesuche/gruppe-abrechnung/_id/dam-bedingungen/bildfreigabe.vue' /* webpackChunkName: "pages/gesuche/gruppe-abrechnung/_id/dam-bedingungen/bildfreigabe" */))
const _7472d493 = () => interopDefault(import('../pages/gesuche/gruppe-abrechnung/_id/dam-bedingungen/inklusionsnachweis.vue' /* webpackChunkName: "pages/gesuche/gruppe-abrechnung/_id/dam-bedingungen/inklusionsnachweis" */))
const _f1ce2080 = () => interopDefault(import('../pages/gesuche/gruppe-abrechnung/_id/dam-bedingungen/inklusionskriterien.vue' /* webpackChunkName: "pages/gesuche/gruppe-abrechnung/_id/dam-bedingungen/inklusionskriterien" */))
const _eeefb79c = () => interopDefault(import('../pages/gesuche/gruppe-abrechnung/_id/dam-bedingungen/logo-antragsteller.vue' /* webpackChunkName: "pages/gesuche/gruppe-abrechnung/_id/dam-bedingungen/logo-antragsteller" */))
const _3a269788 = () => interopDefault(import('../pages/gesuche/gruppe-abrechnung/_id/dam-bedingungen/nachweis-unterstuetzung.vue' /* webpackChunkName: "pages/gesuche/gruppe-abrechnung/_id/dam-bedingungen/nachweis-unterstuetzung" */))
const _e894b382 = () => interopDefault(import('../pages/gesuche/gruppe-abrechnung/_id/dam-bedingungen/wirkung.vue' /* webpackChunkName: "pages/gesuche/gruppe-abrechnung/_id/dam-bedingungen/wirkung" */))
const _68efb808 = () => interopDefault(import('../pages/gesuche/gruppe-abrechnung/_id/kontaktdaten/kontaktdaten-kontoinhaber.vue' /* webpackChunkName: "pages/gesuche/gruppe-abrechnung/_id/kontaktdaten/kontaktdaten-kontoinhaber" */))
const _41f8e33c = () => interopDefault(import('../pages/gesuche/gruppe-abrechnung/_id/kontaktdaten/kontodaten.vue' /* webpackChunkName: "pages/gesuche/gruppe-abrechnung/_id/kontaktdaten/kontodaten" */))
const _f62c03f0 = () => interopDefault(import('../pages/gesuche/projekte-abrechnung/_id.vue' /* webpackChunkName: "pages/gesuche/projekte-abrechnung/_id" */))
const _1c94ce06 = () => interopDefault(import('../pages/gesuche/projekte-abrechnung/_id/abrechnung-projekte/effektiver-aufwand.vue' /* webpackChunkName: "pages/gesuche/projekte-abrechnung/_id/abrechnung-projekte/effektiver-aufwand" */))
const _172bc9a6 = () => interopDefault(import('../pages/gesuche/projekte-abrechnung/_id/abrechnung-projekte/effektiver-ertrag.vue' /* webpackChunkName: "pages/gesuche/projekte-abrechnung/_id/abrechnung-projekte/effektiver-ertrag" */))
const _0c992614 = () => interopDefault(import('../pages/gesuche/projekte-abrechnung/_id/abrechnung-projekte/effektives-defizit.vue' /* webpackChunkName: "pages/gesuche/projekte-abrechnung/_id/abrechnung-projekte/effektives-defizit" */))
const _04928e44 = () => interopDefault(import('../pages/gesuche/projekte-abrechnung/_id/abrechnung-projekte/institutionelle-spenden.vue' /* webpackChunkName: "pages/gesuche/projekte-abrechnung/_id/abrechnung-projekte/institutionelle-spenden" */))
const _66723c51 = () => interopDefault(import('../pages/gesuche/projekte-abrechnung/_id/abrechnung-projekte/kein-defizit.vue' /* webpackChunkName: "pages/gesuche/projekte-abrechnung/_id/abrechnung-projekte/kein-defizit" */))
const _d32fc122 = () => interopDefault(import('../pages/gesuche/projekte-abrechnung/_id/abrechnung-projekte/reichweite.vue' /* webpackChunkName: "pages/gesuche/projekte-abrechnung/_id/abrechnung-projekte/reichweite" */))
const _4bbb38da = () => interopDefault(import('../pages/gesuche/projekte-abrechnung/_id/abschluss/absage.vue' /* webpackChunkName: "pages/gesuche/projekte-abrechnung/_id/abschluss/absage" */))
const _498e7dd9 = () => interopDefault(import('../pages/gesuche/projekte-abrechnung/_id/abschluss/verifikation.vue' /* webpackChunkName: "pages/gesuche/projekte-abrechnung/_id/abschluss/verifikation" */))
const _694f5aee = () => interopDefault(import('../pages/gesuche/projekte-abrechnung/_id/abschluss/vielen-dank.vue' /* webpackChunkName: "pages/gesuche/projekte-abrechnung/_id/abschluss/vielen-dank" */))
const _917e4ad4 = () => interopDefault(import('../pages/gesuche/projekte-abrechnung/_id/bestaetigung-realisierung/gesuch-absagen.vue' /* webpackChunkName: "pages/gesuche/projekte-abrechnung/_id/bestaetigung-realisierung/gesuch-absagen" */))
const _af9fa268 = () => interopDefault(import('../pages/gesuche/projekte-abrechnung/_id/bestaetigung-realisierung/projektabschluss.vue' /* webpackChunkName: "pages/gesuche/projekte-abrechnung/_id/bestaetigung-realisierung/projektabschluss" */))
const _3060fb06 = () => interopDefault(import('../pages/gesuche/projekte-abrechnung/_id/bestaetigung-realisierung/unterlagen-abrechnung.vue' /* webpackChunkName: "pages/gesuche/projekte-abrechnung/_id/bestaetigung-realisierung/unterlagen-abrechnung" */))
const _28902cbc = () => interopDefault(import('../pages/gesuche/projekte-abrechnung/_id/dam-bedingungen/abschlussbericht.vue' /* webpackChunkName: "pages/gesuche/projekte-abrechnung/_id/dam-bedingungen/abschlussbericht" */))
const _4434d1d0 = () => interopDefault(import('../pages/gesuche/projekte-abrechnung/_id/dam-bedingungen/bilder-hochladen.vue' /* webpackChunkName: "pages/gesuche/projekte-abrechnung/_id/dam-bedingungen/bilder-hochladen" */))
const _366f7879 = () => interopDefault(import('../pages/gesuche/projekte-abrechnung/_id/dam-bedingungen/bildfreigabe.vue' /* webpackChunkName: "pages/gesuche/projekte-abrechnung/_id/dam-bedingungen/bildfreigabe" */))
const _389865cc = () => interopDefault(import('../pages/gesuche/projekte-abrechnung/_id/dam-bedingungen/inklusionsnachweis.vue' /* webpackChunkName: "pages/gesuche/projekte-abrechnung/_id/dam-bedingungen/inklusionsnachweis" */))
const _4cadb56b = () => interopDefault(import('../pages/gesuche/projekte-abrechnung/_id/dam-bedingungen/logo-antragsteller.vue' /* webpackChunkName: "pages/gesuche/projekte-abrechnung/_id/dam-bedingungen/logo-antragsteller" */))
const _180e15aa = () => interopDefault(import('../pages/gesuche/projekte-abrechnung/_id/dam-bedingungen/nachweis-der-unterstuetzung.vue' /* webpackChunkName: "pages/gesuche/projekte-abrechnung/_id/dam-bedingungen/nachweis-der-unterstuetzung" */))
const _cbf5ceaa = () => interopDefault(import('../pages/gesuche/projekte-abrechnung/_id/kontaktdaten/bemerkungen.vue' /* webpackChunkName: "pages/gesuche/projekte-abrechnung/_id/kontaktdaten/bemerkungen" */))
const _2981dba3 = () => interopDefault(import('../pages/gesuche/projekte-abrechnung/_id/kontaktdaten/kontodaten.vue' /* webpackChunkName: "pages/gesuche/projekte-abrechnung/_id/kontaktdaten/kontodaten" */))
const _70a940d4 = () => interopDefault(import('../pages/gesuche/projekte-abrechnung/_id/kontaktdaten/kontoinhaber.vue' /* webpackChunkName: "pages/gesuche/projekte-abrechnung/_id/kontaktdaten/kontoinhaber" */))
const _5b014400 = () => interopDefault(import('../pages/login/_code.vue' /* webpackChunkName: "pages/login/_code" */))
const _5022c88e = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/downloads",
    component: _121b4776,
    name: "downloads___de"
  }, {
    path: "/gesuche",
    component: _a56c8d86,
    name: "gesuche___de"
  }, {
    path: "/login",
    component: _5e96a5f4,
    name: "login___de"
  }, {
    path: "/fr/downloads",
    component: _121b4776,
    name: "downloads___fr"
  }, {
    path: "/fr/gesuche",
    component: _a56c8d86,
    name: "gesuche___fr"
  }, {
    path: "/fr/login",
    component: _5e96a5f4,
    name: "login___fr"
  }, {
    path: "/gesuche/admin",
    component: _1e1d7f86,
    name: "gesuche-admin___de"
  }, {
    path: "/gesuche/einzelperson",
    component: _6123c291,
    name: "gesuche-einzelperson___de",
    children: [{
      path: "/gesuche/einzelperson/email",
      component: _26f9f3c4,
      name: "gesuche-einzelperson-email___de"
    }, {
      path: "/gesuche/einzelperson/gesuch",
      component: _b2bbd0da,
      name: "gesuche-einzelperson-gesuch___de"
    }, {
      path: "/gesuche/einzelperson/verifikation-email",
      component: _6b820850,
      name: "gesuche-einzelperson-verifikation-email___de"
    }, {
      path: "/gesuche/einzelperson/:id/abschluss",
      component: _177c116d,
      name: "gesuche-einzelperson-id-abschluss___de"
    }, {
      path: "/gesuche/einzelperson/:id/datenpruefung",
      component: _59aa4bc7,
      name: "gesuche-einzelperson-id-datenpruefung___de"
    }, {
      path: "/gesuche/einzelperson/:id/budget/ausgaben-anschaffung",
      component: _9e730b18,
      name: "gesuche-einzelperson-id-budget-ausgaben-anschaffung___de"
    }, {
      path: "/gesuche/einzelperson/:id/budget/ausgaben-ferienreise",
      component: _fa277936,
      name: "gesuche-einzelperson-id-budget-ausgaben-ferienreise___de"
    }, {
      path: "/gesuche/einzelperson/:id/budget/ausgaben-freizeitaktivitaet",
      component: _2891e3a8,
      name: "gesuche-einzelperson-id-budget-ausgaben-freizeitaktivitaet___de"
    }, {
      path: "/gesuche/einzelperson/:id/budget/defizit",
      component: _7f68aa8c,
      name: "gesuche-einzelperson-id-budget-defizit___de"
    }, {
      path: "/gesuche/einzelperson/:id/budget/einnahmen",
      component: _5e587f44,
      name: "gesuche-einzelperson-id-budget-einnahmen___de"
    }, {
      path: "/gesuche/einzelperson/:id/eingabe/einstieg",
      component: _0c88be8b,
      name: "gesuche-einzelperson-id-eingabe-einstieg___de"
    }, {
      path: "/gesuche/einzelperson/:id/eingabe/identifikation",
      component: _5bb519f1,
      name: "gesuche-einzelperson-id-eingabe-identifikation___de"
    }, {
      path: "/gesuche/einzelperson/:id/eingabe/information-fremdausfueller",
      component: _06e617d8,
      name: "gesuche-einzelperson-id-eingabe-information-fremdausfueller___de"
    }, {
      path: "/gesuche/einzelperson/:id/kontaktdaten/fremdeingabe",
      component: _731f63b3,
      name: "gesuche-einzelperson-id-kontaktdaten-fremdeingabe___de"
    }, {
      path: "/gesuche/einzelperson/:id/kontaktdaten/selbsteingabe",
      component: _20cde5dc,
      name: "gesuche-einzelperson-id-kontaktdaten-selbsteingabe___de"
    }, {
      path: "/gesuche/einzelperson/:id/kontaktdaten/vertretung",
      component: _b660aa4c,
      name: "gesuche-einzelperson-id-kontaktdaten-vertretung___de"
    }, {
      path: "/gesuche/einzelperson/:id/qualifikation/beleg-steuerbares-einkommen",
      component: _5d6b1190,
      name: "gesuche-einzelperson-id-qualifikation-beleg-steuerbares-einkommen___de"
    }, {
      path: "/gesuche/einzelperson/:id/qualifikation/belege-unterstuetzung",
      component: _4dee3887,
      name: "gesuche-einzelperson-id-qualifikation-belege-unterstuetzung___de"
    }, {
      path: "/gesuche/einzelperson/:id/qualifikation/einkommensbasis",
      component: _858cd9b2,
      name: "gesuche-einzelperson-id-qualifikation-einkommensbasis___de"
    }, {
      path: "/gesuche/einzelperson/:id/qualifikation/geburtsdatum",
      component: _268662da,
      name: "gesuche-einzelperson-id-qualifikation-geburtsdatum___de"
    }, {
      path: "/gesuche/einzelperson/:id/qualifikation/steuerbares-einkommen",
      component: _d19ea09c,
      name: "gesuche-einzelperson-id-qualifikation-steuerbares-einkommen___de"
    }, {
      path: "/gesuche/einzelperson/:id/verwendungszweck/anschaffungsdatum",
      component: _210eb2ac,
      name: "gesuche-einzelperson-id-verwendungszweck-anschaffungsdatum___de"
    }, {
      path: "/gesuche/einzelperson/:id/verwendungszweck/anschaffungsgegenstand",
      component: _11d24412,
      name: "gesuche-einzelperson-id-verwendungszweck-anschaffungsgegenstand___de"
    }, {
      path: "/gesuche/einzelperson/:id/verwendungszweck/anzahl-personen",
      component: _3b16212b,
      name: "gesuche-einzelperson-id-verwendungszweck-anzahl-personen___de"
    }, {
      path: "/gesuche/einzelperson/:id/verwendungszweck/begleitperson",
      component: _579e8b95,
      name: "gesuche-einzelperson-id-verwendungszweck-begleitperson___de"
    }, {
      path: "/gesuche/einzelperson/:id/verwendungszweck/begleitpersonen",
      component: _4773d07e,
      name: "gesuche-einzelperson-id-verwendungszweck-begleitpersonen___de"
    }, {
      path: "/gesuche/einzelperson/:id/verwendungszweck/begruendung-begleitperson",
      component: _3f198342,
      name: "gesuche-einzelperson-id-verwendungszweck-begruendung-begleitperson___de"
    }, {
      path: "/gesuche/einzelperson/:id/verwendungszweck/datum-reise-freizeitaktivitaet",
      component: _44adcb22,
      name: "gesuche-einzelperson-id-verwendungszweck-datum-reise-freizeitaktivitaet___de"
    }, {
      path: "/gesuche/einzelperson/:id/verwendungszweck/gesuchsgrund",
      component: _4f8eae4a,
      name: "gesuche-einzelperson-id-verwendungszweck-gesuchsgrund___de"
    }, {
      path: "/gesuche/einzelperson/:id/verwendungszweck/inland-ausland",
      component: _2bcadc76,
      name: "gesuche-einzelperson-id-verwendungszweck-inland-ausland___de"
    }, {
      path: "/gesuche/einzelperson/:id/verwendungszweck/land-ort",
      component: _5c8fc832,
      name: "gesuche-einzelperson-id-verwendungszweck-land-ort___de"
    }, {
      path: "/gesuche/einzelperson/:id/verwendungszweck/motivation",
      component: _6594ea24,
      name: "gesuche-einzelperson-id-verwendungszweck-motivation___de"
    }, {
      path: "/gesuche/einzelperson/:id/verwendungszweck/organisationsform",
      component: _5e9af316,
      name: "gesuche-einzelperson-id-verwendungszweck-organisationsform___de"
    }, {
      path: "/gesuche/einzelperson/:id/verwendungszweck/ort",
      component: _4fb5c849,
      name: "gesuche-einzelperson-id-verwendungszweck-ort___de"
    }, {
      path: ":id",
      component: _628b293f,
      name: "gesuche-einzelperson-id___de"
    }]
  }, {
    path: "/gesuche/gruppe",
    component: _f4f73c40,
    children: [{
      path: "/gesuche/gruppe",
      component: _278fff3a,
      name: "gesuche-gruppe___de"
    }, {
      path: "/gesuche/gruppe/email",
      component: _ca86a3a6,
      name: "gesuche-gruppe-email___de"
    }, {
      path: "/gesuche/gruppe/verifikation-email",
      component: _2a3830a1,
      name: "gesuche-gruppe-verifikation-email___de"
    }, {
      path: "/gesuche/gruppe/:id",
      component: _e6f79c64,
      name: "gesuche-gruppe-id___de"
    }, {
      path: "/gesuche/gruppe/:id/abschluss",
      component: _15903a7c,
      name: "gesuche-gruppe-id-abschluss___de"
    }, {
      path: "/gesuche/gruppe/:id/datenpruefung",
      component: _70555556,
      name: "gesuche-gruppe-id-datenpruefung___de"
    }, {
      path: "/gesuche/gruppe/:id/beschreibung-angebot/land",
      component: _31e6967a,
      name: "gesuche-gruppe-id-beschreibung-angebot-land___de"
    }, {
      path: "/gesuche/gruppe/:id/beschreibung-angebot/ort",
      component: _c6f4129c,
      name: "gesuche-gruppe-id-beschreibung-angebot-ort___de"
    }, {
      path: "/gesuche/gruppe/:id/beschreibung-angebot/thema-gruppenaktivitaet",
      component: _7d7843fa,
      name: "gesuche-gruppe-id-beschreibung-angebot-thema-gruppenaktivitaet___de"
    }, {
      path: "/gesuche/gruppe/:id/beschreibung-angebot/zeitraum",
      component: _d20e50a8,
      name: "gesuche-gruppe-id-beschreibung-angebot-zeitraum___de"
    }, {
      path: "/gesuche/gruppe/:id/beschreibung-angebot/zielsetzung",
      component: _0d7c5401,
      name: "gesuche-gruppe-id-beschreibung-angebot-zielsetzung___de"
    }, {
      path: "/gesuche/gruppe/:id/budget/ausgaben",
      component: _2a242498,
      name: "gesuche-gruppe-id-budget-ausgaben___de"
    }, {
      path: "/gesuche/gruppe/:id/budget/defizit-gruppenaktivitaet",
      component: _38fea27f,
      name: "gesuche-gruppe-id-budget-defizit-gruppenaktivitaet___de"
    }, {
      path: "/gesuche/gruppe/:id/budget/defizit-sammelgesuch",
      component: _127187a8,
      name: "gesuche-gruppe-id-budget-defizit-sammelgesuch___de"
    }, {
      path: "/gesuche/gruppe/:id/budget/einnahmen",
      component: _4825d755,
      name: "gesuche-gruppe-id-budget-einnahmen___de"
    }, {
      path: "/gesuche/gruppe/:id/dam-bedingungen/bildnachweis",
      component: _73411519,
      name: "gesuche-gruppe-id-dam-bedingungen-bildnachweis___de"
    }, {
      path: "/gesuche/gruppe/:id/dam-bedingungen/hinweis-auf-unterstuetzung",
      component: _78ab0fbc,
      name: "gesuche-gruppe-id-dam-bedingungen-hinweis-auf-unterstuetzung___de"
    }, {
      path: "/gesuche/gruppe/:id/dam-bedingungen/inklusionskriterien",
      component: _5e9101aa,
      name: "gesuche-gruppe-id-dam-bedingungen-inklusionskriterien___de"
    }, {
      path: "/gesuche/gruppe/:id/dam-bedingungen/wirkung",
      component: _3499c729,
      name: "gesuche-gruppe-id-dam-bedingungen-wirkung___de"
    }, {
      path: "/gesuche/gruppe/:id/kontaktdaten/bemerkungen",
      component: _040f0088,
      name: "gesuche-gruppe-id-kontaktdaten-bemerkungen___de"
    }, {
      path: "/gesuche/gruppe/:id/kontaktdaten/kontaktdaten",
      component: _268d662b,
      name: "gesuche-gruppe-id-kontaktdaten-kontaktdaten___de"
    }, {
      path: "/gesuche/gruppe/:id/richtlinien-und-bedarf/abrechnung-von-sammelgesuchen",
      component: _1906c404,
      name: "gesuche-gruppe-id-richtlinien-und-bedarf-abrechnung-von-sammelgesuchen___de"
    }, {
      path: "/gesuche/gruppe/:id/richtlinien-und-bedarf/anzahl-gruppen",
      component: _442f3f1f,
      name: "gesuche-gruppe-id-richtlinien-und-bedarf-anzahl-gruppen___de"
    }, {
      path: "/gesuche/gruppe/:id/richtlinien-und-bedarf/anzahl-tage",
      component: _264853a2,
      name: "gesuche-gruppe-id-richtlinien-und-bedarf-anzahl-tage___de"
    }, {
      path: "/gesuche/gruppe/:id/richtlinien-und-bedarf/anzahl-teilnehmende",
      component: _aab4d844,
      name: "gesuche-gruppe-id-richtlinien-und-bedarf-anzahl-teilnehmende___de"
    }, {
      path: "/gesuche/gruppe/:id/richtlinien-und-bedarf/anzahl-teilnehmertage",
      component: _195f35a8,
      name: "gesuche-gruppe-id-richtlinien-und-bedarf-anzahl-teilnehmertage___de"
    }, {
      path: "/gesuche/gruppe/:id/richtlinien-und-bedarf/gesuchsart",
      component: _f9f86e14,
      name: "gesuche-gruppe-id-richtlinien-und-bedarf-gesuchsart___de"
    }, {
      path: "/gesuche/gruppe/:id/richtlinien-und-bedarf/gruppenzusammensetzung",
      component: _05fbf492,
      name: "gesuche-gruppe-id-richtlinien-und-bedarf-gruppenzusammensetzung___de"
    }, {
      path: "/gesuche/gruppe/:id/richtlinien-und-bedarf/richtlinien",
      component: _32f138c8,
      name: "gesuche-gruppe-id-richtlinien-und-bedarf-richtlinien___de"
    }, {
      path: "/gesuche/gruppe/:id/richtlinien-und-bedarf/sammelgesuche-download",
      component: _3c63a6aa,
      name: "gesuche-gruppe-id-richtlinien-und-bedarf-sammelgesuche-download___de"
    }, {
      path: "/gesuche/gruppe/:id/richtlinien-und-bedarf/sammelgesuche-upload",
      component: _c1681738,
      name: "gesuche-gruppe-id-richtlinien-und-bedarf-sammelgesuche-upload___de"
    }, {
      path: "/gesuche/gruppe/:id/richtlinien-und-bedarf/sammelgesuche-zeitraum",
      component: _f5ed3d80,
      name: "gesuche-gruppe-id-richtlinien-und-bedarf-sammelgesuche-zeitraum___de"
    }, {
      path: "/gesuche/gruppe/:id/richtlinien-und-bedarf/zielgruppe",
      component: _4ccd3ddc,
      name: "gesuche-gruppe-id-richtlinien-und-bedarf-zielgruppe___de"
    }]
  }, {
    path: "/gesuche/kontakte",
    component: _5299fcac,
    name: "gesuche-kontakte___de"
  }, {
    path: "/gesuche/projekte",
    component: _f609b94e,
    children: [{
      path: "/gesuche/projekte",
      component: _e2a7f9c8,
      name: "gesuche-projekte___de"
    }, {
      path: "/gesuche/projekte/email",
      component: _3d30b0e6,
      name: "gesuche-projekte-email___de"
    }, {
      path: "/gesuche/projekte/verifikation-email",
      component: _191e2288,
      name: "gesuche-projekte-verifikation-email___de"
    }, {
      path: "/gesuche/projekte/:id",
      component: _09539ff2,
      name: "gesuche-projekte-id___de"
    }, {
      path: "/gesuche/projekte/:id/abschluss",
      component: _9b531796,
      name: "gesuche-projekte-id-abschluss___de"
    }, {
      path: "/gesuche/projekte/:id/budget/absage-institutionen",
      component: _0dcefd60,
      name: "gesuche-projekte-id-budget-absage-institutionen___de"
    }, {
      path: "/gesuche/projekte/:id/budget/angefragte-institutionen",
      component: _04ffa5c1,
      name: "gesuche-projekte-id-budget-angefragte-institutionen___de"
    }, {
      path: "/gesuche/projekte/:id/budget/aufwand",
      component: _7f4c2c83,
      name: "gesuche-projekte-id-budget-aufwand___de"
    }, {
      path: "/gesuche/projekte/:id/budget/beitrag-stiftung-denk-an-mich",
      component: _6fffb516,
      name: "gesuche-projekte-id-budget-beitrag-stiftung-denk-an-mich___de"
    }, {
      path: "/gesuche/projekte/:id/budget/defizit",
      component: _6de7c4f2,
      name: "gesuche-projekte-id-budget-defizit___de"
    }, {
      path: "/gesuche/projekte/:id/budget/ertrag",
      component: _e7830de0,
      name: "gesuche-projekte-id-budget-ertrag___de"
    }, {
      path: "/gesuche/projekte/:id/budget/institutionelle-spenden",
      component: _97e792ba,
      name: "gesuche-projekte-id-budget-institutionelle-spenden___de"
    }, {
      path: "/gesuche/projekte/:id/dam-bedingungen/abschlussbericht-und-bildnachweis",
      component: _a149d030,
      name: "gesuche-projekte-id-dam-bedingungen-abschlussbericht-und-bildnachweis___de"
    }, {
      path: "/gesuche/projekte/:id/dam-bedingungen/hinweis-auf-unterstuetzung",
      component: _4b44b123,
      name: "gesuche-projekte-id-dam-bedingungen-hinweis-auf-unterstuetzung___de"
    }, {
      path: "/gesuche/projekte/:id/kontaktdaten/bemerkungen",
      component: _014e04a2,
      name: "gesuche-projekte-id-kontaktdaten-bemerkungen___de"
    }, {
      path: "/gesuche/projekte/:id/kontaktdaten/datenpruefung",
      component: _d27950f4,
      name: "gesuche-projekte-id-kontaktdaten-datenpruefung___de"
    }, {
      path: "/gesuche/projekte/:id/kontaktdaten/kontaktdaten",
      component: _d6f9e438,
      name: "gesuche-projekte-id-kontaktdaten-kontaktdaten___de"
    }, {
      path: "/gesuche/projekte/:id/projektdaten/anzahl-personen",
      component: _61e086ae,
      name: "gesuche-projekte-id-projektdaten-anzahl-personen___de"
    }, {
      path: "/gesuche/projekte/:id/projektdaten/eingabefrist",
      component: _405c63ec,
      name: "gesuche-projekte-id-projektdaten-eingabefrist___de"
    }, {
      path: "/gesuche/projekte/:id/projektdaten/inklusion",
      component: _36712282,
      name: "gesuche-projekte-id-projektdaten-inklusion___de"
    }, {
      path: "/gesuche/projekte/:id/projektdaten/konzept",
      component: _2f48d258,
      name: "gesuche-projekte-id-projektdaten-konzept___de"
    }, {
      path: "/gesuche/projekte/:id/projektdaten/projektbeschreibung",
      component: _60d3c48e,
      name: "gesuche-projekte-id-projektdaten-projektbeschreibung___de"
    }, {
      path: "/gesuche/projekte/:id/projektdaten/projektkategorie",
      component: _2337b783,
      name: "gesuche-projekte-id-projektdaten-projektkategorie___de"
    }, {
      path: "/gesuche/projekte/:id/projektdaten/projektlaufzeit",
      component: _98b62d70,
      name: "gesuche-projekte-id-projektdaten-projektlaufzeit___de"
    }, {
      path: "/gesuche/projekte/:id/projektdaten/projekttitel",
      component: _15407a4a,
      name: "gesuche-projekte-id-projektdaten-projekttitel___de"
    }, {
      path: "/gesuche/projekte/:id/projektdaten/wirkung",
      component: _a3038544,
      name: "gesuche-projekte-id-projektdaten-wirkung___de"
    }, {
      path: "/gesuche/projekte/:id/richtlinien-und-zielgruppe/gesuchsart",
      component: _fd86681c,
      name: "gesuche-projekte-id-richtlinien-und-zielgruppe-gesuchsart___de"
    }, {
      path: "/gesuche/projekte/:id/richtlinien-und-zielgruppe/kommerzieller-hintergrund",
      component: _1cce2ba4,
      name: "gesuche-projekte-id-richtlinien-und-zielgruppe-kommerzieller-hintergrund___de"
    }, {
      path: "/gesuche/projekte/:id/richtlinien-und-zielgruppe/projektleitung",
      component: _8d6dacd4,
      name: "gesuche-projekte-id-richtlinien-und-zielgruppe-projektleitung___de"
    }, {
      path: "/gesuche/projekte/:id/richtlinien-und-zielgruppe/richtlinien",
      component: _084ed568,
      name: "gesuche-projekte-id-richtlinien-und-zielgruppe-richtlinien___de"
    }, {
      path: "/gesuche/projekte/:id/richtlinien-und-zielgruppe/zielgruppe",
      component: _505b37e4,
      name: "gesuche-projekte-id-richtlinien-und-zielgruppe-zielgruppe___de"
    }]
  }, {
    path: "/it/downloads",
    component: _121b4776,
    name: "downloads___it"
  }, {
    path: "/it/gesuche",
    component: _a56c8d86,
    name: "gesuche___it"
  }, {
    path: "/it/login",
    component: _5e96a5f4,
    name: "login___it"
  }, {
    path: "/fr/gesuche/admin",
    component: _1e1d7f86,
    name: "gesuche-admin___fr"
  }, {
    path: "/fr/gesuche/einzelperson",
    component: _6123c291,
    name: "gesuche-einzelperson___fr",
    children: [{
      path: "/fr/gesuche/einzelperson/email",
      component: _26f9f3c4,
      name: "gesuche-einzelperson-email___fr"
    }, {
      path: "/fr/gesuche/einzelperson/gesuch",
      component: _b2bbd0da,
      name: "gesuche-einzelperson-gesuch___fr"
    }, {
      path: "/fr/gesuche/einzelperson/verifikation-email",
      component: _6b820850,
      name: "gesuche-einzelperson-verifikation-email___fr"
    }, {
      path: "/fr/gesuche/einzelperson/:id/abschluss",
      component: _177c116d,
      name: "gesuche-einzelperson-id-abschluss___fr"
    }, {
      path: "/fr/gesuche/einzelperson/:id/datenpruefung",
      component: _59aa4bc7,
      name: "gesuche-einzelperson-id-datenpruefung___fr"
    }, {
      path: "/fr/gesuche/einzelperson/:id/budget/ausgaben-anschaffung",
      component: _9e730b18,
      name: "gesuche-einzelperson-id-budget-ausgaben-anschaffung___fr"
    }, {
      path: "/fr/gesuche/einzelperson/:id/budget/ausgaben-ferienreise",
      component: _fa277936,
      name: "gesuche-einzelperson-id-budget-ausgaben-ferienreise___fr"
    }, {
      path: "/fr/gesuche/einzelperson/:id/budget/ausgaben-freizeitaktivitaet",
      component: _2891e3a8,
      name: "gesuche-einzelperson-id-budget-ausgaben-freizeitaktivitaet___fr"
    }, {
      path: "/fr/gesuche/einzelperson/:id/budget/defizit",
      component: _7f68aa8c,
      name: "gesuche-einzelperson-id-budget-defizit___fr"
    }, {
      path: "/fr/gesuche/einzelperson/:id/budget/einnahmen",
      component: _5e587f44,
      name: "gesuche-einzelperson-id-budget-einnahmen___fr"
    }, {
      path: "/fr/gesuche/einzelperson/:id/eingabe/einstieg",
      component: _0c88be8b,
      name: "gesuche-einzelperson-id-eingabe-einstieg___fr"
    }, {
      path: "/fr/gesuche/einzelperson/:id/eingabe/identifikation",
      component: _5bb519f1,
      name: "gesuche-einzelperson-id-eingabe-identifikation___fr"
    }, {
      path: "/fr/gesuche/einzelperson/:id/eingabe/information-fremdausfueller",
      component: _06e617d8,
      name: "gesuche-einzelperson-id-eingabe-information-fremdausfueller___fr"
    }, {
      path: "/fr/gesuche/einzelperson/:id/kontaktdaten/fremdeingabe",
      component: _731f63b3,
      name: "gesuche-einzelperson-id-kontaktdaten-fremdeingabe___fr"
    }, {
      path: "/fr/gesuche/einzelperson/:id/kontaktdaten/selbsteingabe",
      component: _20cde5dc,
      name: "gesuche-einzelperson-id-kontaktdaten-selbsteingabe___fr"
    }, {
      path: "/fr/gesuche/einzelperson/:id/kontaktdaten/vertretung",
      component: _b660aa4c,
      name: "gesuche-einzelperson-id-kontaktdaten-vertretung___fr"
    }, {
      path: "/fr/gesuche/einzelperson/:id/qualifikation/beleg-steuerbares-einkommen",
      component: _5d6b1190,
      name: "gesuche-einzelperson-id-qualifikation-beleg-steuerbares-einkommen___fr"
    }, {
      path: "/fr/gesuche/einzelperson/:id/qualifikation/belege-unterstuetzung",
      component: _4dee3887,
      name: "gesuche-einzelperson-id-qualifikation-belege-unterstuetzung___fr"
    }, {
      path: "/fr/gesuche/einzelperson/:id/qualifikation/einkommensbasis",
      component: _858cd9b2,
      name: "gesuche-einzelperson-id-qualifikation-einkommensbasis___fr"
    }, {
      path: "/fr/gesuche/einzelperson/:id/qualifikation/geburtsdatum",
      component: _268662da,
      name: "gesuche-einzelperson-id-qualifikation-geburtsdatum___fr"
    }, {
      path: "/fr/gesuche/einzelperson/:id/qualifikation/steuerbares-einkommen",
      component: _d19ea09c,
      name: "gesuche-einzelperson-id-qualifikation-steuerbares-einkommen___fr"
    }, {
      path: "/fr/gesuche/einzelperson/:id/verwendungszweck/anschaffungsdatum",
      component: _210eb2ac,
      name: "gesuche-einzelperson-id-verwendungszweck-anschaffungsdatum___fr"
    }, {
      path: "/fr/gesuche/einzelperson/:id/verwendungszweck/anschaffungsgegenstand",
      component: _11d24412,
      name: "gesuche-einzelperson-id-verwendungszweck-anschaffungsgegenstand___fr"
    }, {
      path: "/fr/gesuche/einzelperson/:id/verwendungszweck/anzahl-personen",
      component: _3b16212b,
      name: "gesuche-einzelperson-id-verwendungszweck-anzahl-personen___fr"
    }, {
      path: "/fr/gesuche/einzelperson/:id/verwendungszweck/begleitperson",
      component: _579e8b95,
      name: "gesuche-einzelperson-id-verwendungszweck-begleitperson___fr"
    }, {
      path: "/fr/gesuche/einzelperson/:id/verwendungszweck/begleitpersonen",
      component: _4773d07e,
      name: "gesuche-einzelperson-id-verwendungszweck-begleitpersonen___fr"
    }, {
      path: "/fr/gesuche/einzelperson/:id/verwendungszweck/begruendung-begleitperson",
      component: _3f198342,
      name: "gesuche-einzelperson-id-verwendungszweck-begruendung-begleitperson___fr"
    }, {
      path: "/fr/gesuche/einzelperson/:id/verwendungszweck/datum-reise-freizeitaktivitaet",
      component: _44adcb22,
      name: "gesuche-einzelperson-id-verwendungszweck-datum-reise-freizeitaktivitaet___fr"
    }, {
      path: "/fr/gesuche/einzelperson/:id/verwendungszweck/gesuchsgrund",
      component: _4f8eae4a,
      name: "gesuche-einzelperson-id-verwendungszweck-gesuchsgrund___fr"
    }, {
      path: "/fr/gesuche/einzelperson/:id/verwendungszweck/inland-ausland",
      component: _2bcadc76,
      name: "gesuche-einzelperson-id-verwendungszweck-inland-ausland___fr"
    }, {
      path: "/fr/gesuche/einzelperson/:id/verwendungszweck/land-ort",
      component: _5c8fc832,
      name: "gesuche-einzelperson-id-verwendungszweck-land-ort___fr"
    }, {
      path: "/fr/gesuche/einzelperson/:id/verwendungszweck/motivation",
      component: _6594ea24,
      name: "gesuche-einzelperson-id-verwendungszweck-motivation___fr"
    }, {
      path: "/fr/gesuche/einzelperson/:id/verwendungszweck/organisationsform",
      component: _5e9af316,
      name: "gesuche-einzelperson-id-verwendungszweck-organisationsform___fr"
    }, {
      path: "/fr/gesuche/einzelperson/:id/verwendungszweck/ort",
      component: _4fb5c849,
      name: "gesuche-einzelperson-id-verwendungszweck-ort___fr"
    }, {
      path: ":id",
      component: _628b293f,
      name: "gesuche-einzelperson-id___fr"
    }]
  }, {
    path: "/fr/gesuche/gruppe",
    component: _f4f73c40,
    children: [{
      path: "/fr/gesuche/gruppe",
      component: _278fff3a,
      name: "gesuche-gruppe___fr"
    }, {
      path: "/fr/gesuche/gruppe/email",
      component: _ca86a3a6,
      name: "gesuche-gruppe-email___fr"
    }, {
      path: "/fr/gesuche/gruppe/verifikation-email",
      component: _2a3830a1,
      name: "gesuche-gruppe-verifikation-email___fr"
    }, {
      path: "/fr/gesuche/gruppe/:id",
      component: _e6f79c64,
      name: "gesuche-gruppe-id___fr"
    }, {
      path: "/fr/gesuche/gruppe/:id/abschluss",
      component: _15903a7c,
      name: "gesuche-gruppe-id-abschluss___fr"
    }, {
      path: "/fr/gesuche/gruppe/:id/datenpruefung",
      component: _70555556,
      name: "gesuche-gruppe-id-datenpruefung___fr"
    }, {
      path: "/fr/gesuche/gruppe/:id/beschreibung-angebot/land",
      component: _31e6967a,
      name: "gesuche-gruppe-id-beschreibung-angebot-land___fr"
    }, {
      path: "/fr/gesuche/gruppe/:id/beschreibung-angebot/ort",
      component: _c6f4129c,
      name: "gesuche-gruppe-id-beschreibung-angebot-ort___fr"
    }, {
      path: "/fr/gesuche/gruppe/:id/beschreibung-angebot/thema-gruppenaktivitaet",
      component: _7d7843fa,
      name: "gesuche-gruppe-id-beschreibung-angebot-thema-gruppenaktivitaet___fr"
    }, {
      path: "/fr/gesuche/gruppe/:id/beschreibung-angebot/zeitraum",
      component: _d20e50a8,
      name: "gesuche-gruppe-id-beschreibung-angebot-zeitraum___fr"
    }, {
      path: "/fr/gesuche/gruppe/:id/beschreibung-angebot/zielsetzung",
      component: _0d7c5401,
      name: "gesuche-gruppe-id-beschreibung-angebot-zielsetzung___fr"
    }, {
      path: "/fr/gesuche/gruppe/:id/budget/ausgaben",
      component: _2a242498,
      name: "gesuche-gruppe-id-budget-ausgaben___fr"
    }, {
      path: "/fr/gesuche/gruppe/:id/budget/defizit-gruppenaktivitaet",
      component: _38fea27f,
      name: "gesuche-gruppe-id-budget-defizit-gruppenaktivitaet___fr"
    }, {
      path: "/fr/gesuche/gruppe/:id/budget/defizit-sammelgesuch",
      component: _127187a8,
      name: "gesuche-gruppe-id-budget-defizit-sammelgesuch___fr"
    }, {
      path: "/fr/gesuche/gruppe/:id/budget/einnahmen",
      component: _4825d755,
      name: "gesuche-gruppe-id-budget-einnahmen___fr"
    }, {
      path: "/fr/gesuche/gruppe/:id/dam-bedingungen/bildnachweis",
      component: _73411519,
      name: "gesuche-gruppe-id-dam-bedingungen-bildnachweis___fr"
    }, {
      path: "/fr/gesuche/gruppe/:id/dam-bedingungen/hinweis-auf-unterstuetzung",
      component: _78ab0fbc,
      name: "gesuche-gruppe-id-dam-bedingungen-hinweis-auf-unterstuetzung___fr"
    }, {
      path: "/fr/gesuche/gruppe/:id/dam-bedingungen/inklusionskriterien",
      component: _5e9101aa,
      name: "gesuche-gruppe-id-dam-bedingungen-inklusionskriterien___fr"
    }, {
      path: "/fr/gesuche/gruppe/:id/dam-bedingungen/wirkung",
      component: _3499c729,
      name: "gesuche-gruppe-id-dam-bedingungen-wirkung___fr"
    }, {
      path: "/fr/gesuche/gruppe/:id/kontaktdaten/bemerkungen",
      component: _040f0088,
      name: "gesuche-gruppe-id-kontaktdaten-bemerkungen___fr"
    }, {
      path: "/fr/gesuche/gruppe/:id/kontaktdaten/kontaktdaten",
      component: _268d662b,
      name: "gesuche-gruppe-id-kontaktdaten-kontaktdaten___fr"
    }, {
      path: "/fr/gesuche/gruppe/:id/richtlinien-und-bedarf/abrechnung-von-sammelgesuchen",
      component: _1906c404,
      name: "gesuche-gruppe-id-richtlinien-und-bedarf-abrechnung-von-sammelgesuchen___fr"
    }, {
      path: "/fr/gesuche/gruppe/:id/richtlinien-und-bedarf/anzahl-gruppen",
      component: _442f3f1f,
      name: "gesuche-gruppe-id-richtlinien-und-bedarf-anzahl-gruppen___fr"
    }, {
      path: "/fr/gesuche/gruppe/:id/richtlinien-und-bedarf/anzahl-tage",
      component: _264853a2,
      name: "gesuche-gruppe-id-richtlinien-und-bedarf-anzahl-tage___fr"
    }, {
      path: "/fr/gesuche/gruppe/:id/richtlinien-und-bedarf/anzahl-teilnehmende",
      component: _aab4d844,
      name: "gesuche-gruppe-id-richtlinien-und-bedarf-anzahl-teilnehmende___fr"
    }, {
      path: "/fr/gesuche/gruppe/:id/richtlinien-und-bedarf/anzahl-teilnehmertage",
      component: _195f35a8,
      name: "gesuche-gruppe-id-richtlinien-und-bedarf-anzahl-teilnehmertage___fr"
    }, {
      path: "/fr/gesuche/gruppe/:id/richtlinien-und-bedarf/gesuchsart",
      component: _f9f86e14,
      name: "gesuche-gruppe-id-richtlinien-und-bedarf-gesuchsart___fr"
    }, {
      path: "/fr/gesuche/gruppe/:id/richtlinien-und-bedarf/gruppenzusammensetzung",
      component: _05fbf492,
      name: "gesuche-gruppe-id-richtlinien-und-bedarf-gruppenzusammensetzung___fr"
    }, {
      path: "/fr/gesuche/gruppe/:id/richtlinien-und-bedarf/richtlinien",
      component: _32f138c8,
      name: "gesuche-gruppe-id-richtlinien-und-bedarf-richtlinien___fr"
    }, {
      path: "/fr/gesuche/gruppe/:id/richtlinien-und-bedarf/sammelgesuche-download",
      component: _3c63a6aa,
      name: "gesuche-gruppe-id-richtlinien-und-bedarf-sammelgesuche-download___fr"
    }, {
      path: "/fr/gesuche/gruppe/:id/richtlinien-und-bedarf/sammelgesuche-upload",
      component: _c1681738,
      name: "gesuche-gruppe-id-richtlinien-und-bedarf-sammelgesuche-upload___fr"
    }, {
      path: "/fr/gesuche/gruppe/:id/richtlinien-und-bedarf/sammelgesuche-zeitraum",
      component: _f5ed3d80,
      name: "gesuche-gruppe-id-richtlinien-und-bedarf-sammelgesuche-zeitraum___fr"
    }, {
      path: "/fr/gesuche/gruppe/:id/richtlinien-und-bedarf/zielgruppe",
      component: _4ccd3ddc,
      name: "gesuche-gruppe-id-richtlinien-und-bedarf-zielgruppe___fr"
    }]
  }, {
    path: "/fr/gesuche/kontakte",
    component: _5299fcac,
    name: "gesuche-kontakte___fr"
  }, {
    path: "/fr/gesuche/projekte",
    component: _f609b94e,
    children: [{
      path: "/fr/gesuche/projekte",
      component: _e2a7f9c8,
      name: "gesuche-projekte___fr"
    }, {
      path: "/fr/gesuche/projekte/email",
      component: _3d30b0e6,
      name: "gesuche-projekte-email___fr"
    }, {
      path: "/fr/gesuche/projekte/verifikation-email",
      component: _191e2288,
      name: "gesuche-projekte-verifikation-email___fr"
    }, {
      path: "/fr/gesuche/projekte/:id",
      component: _09539ff2,
      name: "gesuche-projekte-id___fr"
    }, {
      path: "/fr/gesuche/projekte/:id/abschluss",
      component: _9b531796,
      name: "gesuche-projekte-id-abschluss___fr"
    }, {
      path: "/fr/gesuche/projekte/:id/budget/absage-institutionen",
      component: _0dcefd60,
      name: "gesuche-projekte-id-budget-absage-institutionen___fr"
    }, {
      path: "/fr/gesuche/projekte/:id/budget/angefragte-institutionen",
      component: _04ffa5c1,
      name: "gesuche-projekte-id-budget-angefragte-institutionen___fr"
    }, {
      path: "/fr/gesuche/projekte/:id/budget/aufwand",
      component: _7f4c2c83,
      name: "gesuche-projekte-id-budget-aufwand___fr"
    }, {
      path: "/fr/gesuche/projekte/:id/budget/beitrag-stiftung-denk-an-mich",
      component: _6fffb516,
      name: "gesuche-projekte-id-budget-beitrag-stiftung-denk-an-mich___fr"
    }, {
      path: "/fr/gesuche/projekte/:id/budget/defizit",
      component: _6de7c4f2,
      name: "gesuche-projekte-id-budget-defizit___fr"
    }, {
      path: "/fr/gesuche/projekte/:id/budget/ertrag",
      component: _e7830de0,
      name: "gesuche-projekte-id-budget-ertrag___fr"
    }, {
      path: "/fr/gesuche/projekte/:id/budget/institutionelle-spenden",
      component: _97e792ba,
      name: "gesuche-projekte-id-budget-institutionelle-spenden___fr"
    }, {
      path: "/fr/gesuche/projekte/:id/dam-bedingungen/abschlussbericht-und-bildnachweis",
      component: _a149d030,
      name: "gesuche-projekte-id-dam-bedingungen-abschlussbericht-und-bildnachweis___fr"
    }, {
      path: "/fr/gesuche/projekte/:id/dam-bedingungen/hinweis-auf-unterstuetzung",
      component: _4b44b123,
      name: "gesuche-projekte-id-dam-bedingungen-hinweis-auf-unterstuetzung___fr"
    }, {
      path: "/fr/gesuche/projekte/:id/kontaktdaten/bemerkungen",
      component: _014e04a2,
      name: "gesuche-projekte-id-kontaktdaten-bemerkungen___fr"
    }, {
      path: "/fr/gesuche/projekte/:id/kontaktdaten/datenpruefung",
      component: _d27950f4,
      name: "gesuche-projekte-id-kontaktdaten-datenpruefung___fr"
    }, {
      path: "/fr/gesuche/projekte/:id/kontaktdaten/kontaktdaten",
      component: _d6f9e438,
      name: "gesuche-projekte-id-kontaktdaten-kontaktdaten___fr"
    }, {
      path: "/fr/gesuche/projekte/:id/projektdaten/anzahl-personen",
      component: _61e086ae,
      name: "gesuche-projekte-id-projektdaten-anzahl-personen___fr"
    }, {
      path: "/fr/gesuche/projekte/:id/projektdaten/eingabefrist",
      component: _405c63ec,
      name: "gesuche-projekte-id-projektdaten-eingabefrist___fr"
    }, {
      path: "/fr/gesuche/projekte/:id/projektdaten/inklusion",
      component: _36712282,
      name: "gesuche-projekte-id-projektdaten-inklusion___fr"
    }, {
      path: "/fr/gesuche/projekte/:id/projektdaten/konzept",
      component: _2f48d258,
      name: "gesuche-projekte-id-projektdaten-konzept___fr"
    }, {
      path: "/fr/gesuche/projekte/:id/projektdaten/projektbeschreibung",
      component: _60d3c48e,
      name: "gesuche-projekte-id-projektdaten-projektbeschreibung___fr"
    }, {
      path: "/fr/gesuche/projekte/:id/projektdaten/projektkategorie",
      component: _2337b783,
      name: "gesuche-projekte-id-projektdaten-projektkategorie___fr"
    }, {
      path: "/fr/gesuche/projekte/:id/projektdaten/projektlaufzeit",
      component: _98b62d70,
      name: "gesuche-projekte-id-projektdaten-projektlaufzeit___fr"
    }, {
      path: "/fr/gesuche/projekte/:id/projektdaten/projekttitel",
      component: _15407a4a,
      name: "gesuche-projekte-id-projektdaten-projekttitel___fr"
    }, {
      path: "/fr/gesuche/projekte/:id/projektdaten/wirkung",
      component: _a3038544,
      name: "gesuche-projekte-id-projektdaten-wirkung___fr"
    }, {
      path: "/fr/gesuche/projekte/:id/richtlinien-und-zielgruppe/gesuchsart",
      component: _fd86681c,
      name: "gesuche-projekte-id-richtlinien-und-zielgruppe-gesuchsart___fr"
    }, {
      path: "/fr/gesuche/projekte/:id/richtlinien-und-zielgruppe/kommerzieller-hintergrund",
      component: _1cce2ba4,
      name: "gesuche-projekte-id-richtlinien-und-zielgruppe-kommerzieller-hintergrund___fr"
    }, {
      path: "/fr/gesuche/projekte/:id/richtlinien-und-zielgruppe/projektleitung",
      component: _8d6dacd4,
      name: "gesuche-projekte-id-richtlinien-und-zielgruppe-projektleitung___fr"
    }, {
      path: "/fr/gesuche/projekte/:id/richtlinien-und-zielgruppe/richtlinien",
      component: _084ed568,
      name: "gesuche-projekte-id-richtlinien-und-zielgruppe-richtlinien___fr"
    }, {
      path: "/fr/gesuche/projekte/:id/richtlinien-und-zielgruppe/zielgruppe",
      component: _505b37e4,
      name: "gesuche-projekte-id-richtlinien-und-zielgruppe-zielgruppe___fr"
    }]
  }, {
    path: "/gesuche/kurzcheck/einzelperson",
    component: _27fbcc2a,
    name: "gesuche-kurzcheck-einzelperson___de"
  }, {
    path: "/gesuche/kurzcheck/gruppe",
    component: _a9b45b8e,
    name: "gesuche-kurzcheck-gruppe___de"
  }, {
    path: "/gesuche/kurzcheck/projekte",
    component: _6ffc3d1c,
    name: "gesuche-kurzcheck-projekte___de"
  }, {
    path: "/it/gesuche/admin",
    component: _1e1d7f86,
    name: "gesuche-admin___it"
  }, {
    path: "/it/gesuche/einzelperson",
    component: _6123c291,
    name: "gesuche-einzelperson___it",
    children: [{
      path: "/it/gesuche/einzelperson/email",
      component: _26f9f3c4,
      name: "gesuche-einzelperson-email___it"
    }, {
      path: "/it/gesuche/einzelperson/gesuch",
      component: _b2bbd0da,
      name: "gesuche-einzelperson-gesuch___it"
    }, {
      path: "/it/gesuche/einzelperson/verifikation-email",
      component: _6b820850,
      name: "gesuche-einzelperson-verifikation-email___it"
    }, {
      path: "/it/gesuche/einzelperson/:id/abschluss",
      component: _177c116d,
      name: "gesuche-einzelperson-id-abschluss___it"
    }, {
      path: "/it/gesuche/einzelperson/:id/datenpruefung",
      component: _59aa4bc7,
      name: "gesuche-einzelperson-id-datenpruefung___it"
    }, {
      path: "/it/gesuche/einzelperson/:id/budget/ausgaben-anschaffung",
      component: _9e730b18,
      name: "gesuche-einzelperson-id-budget-ausgaben-anschaffung___it"
    }, {
      path: "/it/gesuche/einzelperson/:id/budget/ausgaben-ferienreise",
      component: _fa277936,
      name: "gesuche-einzelperson-id-budget-ausgaben-ferienreise___it"
    }, {
      path: "/it/gesuche/einzelperson/:id/budget/ausgaben-freizeitaktivitaet",
      component: _2891e3a8,
      name: "gesuche-einzelperson-id-budget-ausgaben-freizeitaktivitaet___it"
    }, {
      path: "/it/gesuche/einzelperson/:id/budget/defizit",
      component: _7f68aa8c,
      name: "gesuche-einzelperson-id-budget-defizit___it"
    }, {
      path: "/it/gesuche/einzelperson/:id/budget/einnahmen",
      component: _5e587f44,
      name: "gesuche-einzelperson-id-budget-einnahmen___it"
    }, {
      path: "/it/gesuche/einzelperson/:id/eingabe/einstieg",
      component: _0c88be8b,
      name: "gesuche-einzelperson-id-eingabe-einstieg___it"
    }, {
      path: "/it/gesuche/einzelperson/:id/eingabe/identifikation",
      component: _5bb519f1,
      name: "gesuche-einzelperson-id-eingabe-identifikation___it"
    }, {
      path: "/it/gesuche/einzelperson/:id/eingabe/information-fremdausfueller",
      component: _06e617d8,
      name: "gesuche-einzelperson-id-eingabe-information-fremdausfueller___it"
    }, {
      path: "/it/gesuche/einzelperson/:id/kontaktdaten/fremdeingabe",
      component: _731f63b3,
      name: "gesuche-einzelperson-id-kontaktdaten-fremdeingabe___it"
    }, {
      path: "/it/gesuche/einzelperson/:id/kontaktdaten/selbsteingabe",
      component: _20cde5dc,
      name: "gesuche-einzelperson-id-kontaktdaten-selbsteingabe___it"
    }, {
      path: "/it/gesuche/einzelperson/:id/kontaktdaten/vertretung",
      component: _b660aa4c,
      name: "gesuche-einzelperson-id-kontaktdaten-vertretung___it"
    }, {
      path: "/it/gesuche/einzelperson/:id/qualifikation/beleg-steuerbares-einkommen",
      component: _5d6b1190,
      name: "gesuche-einzelperson-id-qualifikation-beleg-steuerbares-einkommen___it"
    }, {
      path: "/it/gesuche/einzelperson/:id/qualifikation/belege-unterstuetzung",
      component: _4dee3887,
      name: "gesuche-einzelperson-id-qualifikation-belege-unterstuetzung___it"
    }, {
      path: "/it/gesuche/einzelperson/:id/qualifikation/einkommensbasis",
      component: _858cd9b2,
      name: "gesuche-einzelperson-id-qualifikation-einkommensbasis___it"
    }, {
      path: "/it/gesuche/einzelperson/:id/qualifikation/geburtsdatum",
      component: _268662da,
      name: "gesuche-einzelperson-id-qualifikation-geburtsdatum___it"
    }, {
      path: "/it/gesuche/einzelperson/:id/qualifikation/steuerbares-einkommen",
      component: _d19ea09c,
      name: "gesuche-einzelperson-id-qualifikation-steuerbares-einkommen___it"
    }, {
      path: "/it/gesuche/einzelperson/:id/verwendungszweck/anschaffungsdatum",
      component: _210eb2ac,
      name: "gesuche-einzelperson-id-verwendungszweck-anschaffungsdatum___it"
    }, {
      path: "/it/gesuche/einzelperson/:id/verwendungszweck/anschaffungsgegenstand",
      component: _11d24412,
      name: "gesuche-einzelperson-id-verwendungszweck-anschaffungsgegenstand___it"
    }, {
      path: "/it/gesuche/einzelperson/:id/verwendungszweck/anzahl-personen",
      component: _3b16212b,
      name: "gesuche-einzelperson-id-verwendungszweck-anzahl-personen___it"
    }, {
      path: "/it/gesuche/einzelperson/:id/verwendungszweck/begleitperson",
      component: _579e8b95,
      name: "gesuche-einzelperson-id-verwendungszweck-begleitperson___it"
    }, {
      path: "/it/gesuche/einzelperson/:id/verwendungszweck/begleitpersonen",
      component: _4773d07e,
      name: "gesuche-einzelperson-id-verwendungszweck-begleitpersonen___it"
    }, {
      path: "/it/gesuche/einzelperson/:id/verwendungszweck/begruendung-begleitperson",
      component: _3f198342,
      name: "gesuche-einzelperson-id-verwendungszweck-begruendung-begleitperson___it"
    }, {
      path: "/it/gesuche/einzelperson/:id/verwendungszweck/datum-reise-freizeitaktivitaet",
      component: _44adcb22,
      name: "gesuche-einzelperson-id-verwendungszweck-datum-reise-freizeitaktivitaet___it"
    }, {
      path: "/it/gesuche/einzelperson/:id/verwendungszweck/gesuchsgrund",
      component: _4f8eae4a,
      name: "gesuche-einzelperson-id-verwendungszweck-gesuchsgrund___it"
    }, {
      path: "/it/gesuche/einzelperson/:id/verwendungszweck/inland-ausland",
      component: _2bcadc76,
      name: "gesuche-einzelperson-id-verwendungszweck-inland-ausland___it"
    }, {
      path: "/it/gesuche/einzelperson/:id/verwendungszweck/land-ort",
      component: _5c8fc832,
      name: "gesuche-einzelperson-id-verwendungszweck-land-ort___it"
    }, {
      path: "/it/gesuche/einzelperson/:id/verwendungszweck/motivation",
      component: _6594ea24,
      name: "gesuche-einzelperson-id-verwendungszweck-motivation___it"
    }, {
      path: "/it/gesuche/einzelperson/:id/verwendungszweck/organisationsform",
      component: _5e9af316,
      name: "gesuche-einzelperson-id-verwendungszweck-organisationsform___it"
    }, {
      path: "/it/gesuche/einzelperson/:id/verwendungszweck/ort",
      component: _4fb5c849,
      name: "gesuche-einzelperson-id-verwendungszweck-ort___it"
    }, {
      path: ":id",
      component: _628b293f,
      name: "gesuche-einzelperson-id___it"
    }]
  }, {
    path: "/it/gesuche/gruppe",
    component: _f4f73c40,
    children: [{
      path: "/it/gesuche/gruppe",
      component: _278fff3a,
      name: "gesuche-gruppe___it"
    }, {
      path: "/it/gesuche/gruppe/email",
      component: _ca86a3a6,
      name: "gesuche-gruppe-email___it"
    }, {
      path: "/it/gesuche/gruppe/verifikation-email",
      component: _2a3830a1,
      name: "gesuche-gruppe-verifikation-email___it"
    }, {
      path: "/it/gesuche/gruppe/:id",
      component: _e6f79c64,
      name: "gesuche-gruppe-id___it"
    }, {
      path: "/it/gesuche/gruppe/:id/abschluss",
      component: _15903a7c,
      name: "gesuche-gruppe-id-abschluss___it"
    }, {
      path: "/it/gesuche/gruppe/:id/datenpruefung",
      component: _70555556,
      name: "gesuche-gruppe-id-datenpruefung___it"
    }, {
      path: "/it/gesuche/gruppe/:id/beschreibung-angebot/land",
      component: _31e6967a,
      name: "gesuche-gruppe-id-beschreibung-angebot-land___it"
    }, {
      path: "/it/gesuche/gruppe/:id/beschreibung-angebot/ort",
      component: _c6f4129c,
      name: "gesuche-gruppe-id-beschreibung-angebot-ort___it"
    }, {
      path: "/it/gesuche/gruppe/:id/beschreibung-angebot/thema-gruppenaktivitaet",
      component: _7d7843fa,
      name: "gesuche-gruppe-id-beschreibung-angebot-thema-gruppenaktivitaet___it"
    }, {
      path: "/it/gesuche/gruppe/:id/beschreibung-angebot/zeitraum",
      component: _d20e50a8,
      name: "gesuche-gruppe-id-beschreibung-angebot-zeitraum___it"
    }, {
      path: "/it/gesuche/gruppe/:id/beschreibung-angebot/zielsetzung",
      component: _0d7c5401,
      name: "gesuche-gruppe-id-beschreibung-angebot-zielsetzung___it"
    }, {
      path: "/it/gesuche/gruppe/:id/budget/ausgaben",
      component: _2a242498,
      name: "gesuche-gruppe-id-budget-ausgaben___it"
    }, {
      path: "/it/gesuche/gruppe/:id/budget/defizit-gruppenaktivitaet",
      component: _38fea27f,
      name: "gesuche-gruppe-id-budget-defizit-gruppenaktivitaet___it"
    }, {
      path: "/it/gesuche/gruppe/:id/budget/defizit-sammelgesuch",
      component: _127187a8,
      name: "gesuche-gruppe-id-budget-defizit-sammelgesuch___it"
    }, {
      path: "/it/gesuche/gruppe/:id/budget/einnahmen",
      component: _4825d755,
      name: "gesuche-gruppe-id-budget-einnahmen___it"
    }, {
      path: "/it/gesuche/gruppe/:id/dam-bedingungen/bildnachweis",
      component: _73411519,
      name: "gesuche-gruppe-id-dam-bedingungen-bildnachweis___it"
    }, {
      path: "/it/gesuche/gruppe/:id/dam-bedingungen/hinweis-auf-unterstuetzung",
      component: _78ab0fbc,
      name: "gesuche-gruppe-id-dam-bedingungen-hinweis-auf-unterstuetzung___it"
    }, {
      path: "/it/gesuche/gruppe/:id/dam-bedingungen/inklusionskriterien",
      component: _5e9101aa,
      name: "gesuche-gruppe-id-dam-bedingungen-inklusionskriterien___it"
    }, {
      path: "/it/gesuche/gruppe/:id/dam-bedingungen/wirkung",
      component: _3499c729,
      name: "gesuche-gruppe-id-dam-bedingungen-wirkung___it"
    }, {
      path: "/it/gesuche/gruppe/:id/kontaktdaten/bemerkungen",
      component: _040f0088,
      name: "gesuche-gruppe-id-kontaktdaten-bemerkungen___it"
    }, {
      path: "/it/gesuche/gruppe/:id/kontaktdaten/kontaktdaten",
      component: _268d662b,
      name: "gesuche-gruppe-id-kontaktdaten-kontaktdaten___it"
    }, {
      path: "/it/gesuche/gruppe/:id/richtlinien-und-bedarf/abrechnung-von-sammelgesuchen",
      component: _1906c404,
      name: "gesuche-gruppe-id-richtlinien-und-bedarf-abrechnung-von-sammelgesuchen___it"
    }, {
      path: "/it/gesuche/gruppe/:id/richtlinien-und-bedarf/anzahl-gruppen",
      component: _442f3f1f,
      name: "gesuche-gruppe-id-richtlinien-und-bedarf-anzahl-gruppen___it"
    }, {
      path: "/it/gesuche/gruppe/:id/richtlinien-und-bedarf/anzahl-tage",
      component: _264853a2,
      name: "gesuche-gruppe-id-richtlinien-und-bedarf-anzahl-tage___it"
    }, {
      path: "/it/gesuche/gruppe/:id/richtlinien-und-bedarf/anzahl-teilnehmende",
      component: _aab4d844,
      name: "gesuche-gruppe-id-richtlinien-und-bedarf-anzahl-teilnehmende___it"
    }, {
      path: "/it/gesuche/gruppe/:id/richtlinien-und-bedarf/anzahl-teilnehmertage",
      component: _195f35a8,
      name: "gesuche-gruppe-id-richtlinien-und-bedarf-anzahl-teilnehmertage___it"
    }, {
      path: "/it/gesuche/gruppe/:id/richtlinien-und-bedarf/gesuchsart",
      component: _f9f86e14,
      name: "gesuche-gruppe-id-richtlinien-und-bedarf-gesuchsart___it"
    }, {
      path: "/it/gesuche/gruppe/:id/richtlinien-und-bedarf/gruppenzusammensetzung",
      component: _05fbf492,
      name: "gesuche-gruppe-id-richtlinien-und-bedarf-gruppenzusammensetzung___it"
    }, {
      path: "/it/gesuche/gruppe/:id/richtlinien-und-bedarf/richtlinien",
      component: _32f138c8,
      name: "gesuche-gruppe-id-richtlinien-und-bedarf-richtlinien___it"
    }, {
      path: "/it/gesuche/gruppe/:id/richtlinien-und-bedarf/sammelgesuche-download",
      component: _3c63a6aa,
      name: "gesuche-gruppe-id-richtlinien-und-bedarf-sammelgesuche-download___it"
    }, {
      path: "/it/gesuche/gruppe/:id/richtlinien-und-bedarf/sammelgesuche-upload",
      component: _c1681738,
      name: "gesuche-gruppe-id-richtlinien-und-bedarf-sammelgesuche-upload___it"
    }, {
      path: "/it/gesuche/gruppe/:id/richtlinien-und-bedarf/sammelgesuche-zeitraum",
      component: _f5ed3d80,
      name: "gesuche-gruppe-id-richtlinien-und-bedarf-sammelgesuche-zeitraum___it"
    }, {
      path: "/it/gesuche/gruppe/:id/richtlinien-und-bedarf/zielgruppe",
      component: _4ccd3ddc,
      name: "gesuche-gruppe-id-richtlinien-und-bedarf-zielgruppe___it"
    }]
  }, {
    path: "/it/gesuche/kontakte",
    component: _5299fcac,
    name: "gesuche-kontakte___it"
  }, {
    path: "/it/gesuche/projekte",
    component: _f609b94e,
    children: [{
      path: "/it/gesuche/projekte",
      component: _e2a7f9c8,
      name: "gesuche-projekte___it"
    }, {
      path: "/it/gesuche/projekte/email",
      component: _3d30b0e6,
      name: "gesuche-projekte-email___it"
    }, {
      path: "/it/gesuche/projekte/verifikation-email",
      component: _191e2288,
      name: "gesuche-projekte-verifikation-email___it"
    }, {
      path: "/it/gesuche/projekte/:id",
      component: _09539ff2,
      name: "gesuche-projekte-id___it"
    }, {
      path: "/it/gesuche/projekte/:id/abschluss",
      component: _9b531796,
      name: "gesuche-projekte-id-abschluss___it"
    }, {
      path: "/it/gesuche/projekte/:id/budget/absage-institutionen",
      component: _0dcefd60,
      name: "gesuche-projekte-id-budget-absage-institutionen___it"
    }, {
      path: "/it/gesuche/projekte/:id/budget/angefragte-institutionen",
      component: _04ffa5c1,
      name: "gesuche-projekte-id-budget-angefragte-institutionen___it"
    }, {
      path: "/it/gesuche/projekte/:id/budget/aufwand",
      component: _7f4c2c83,
      name: "gesuche-projekte-id-budget-aufwand___it"
    }, {
      path: "/it/gesuche/projekte/:id/budget/beitrag-stiftung-denk-an-mich",
      component: _6fffb516,
      name: "gesuche-projekte-id-budget-beitrag-stiftung-denk-an-mich___it"
    }, {
      path: "/it/gesuche/projekte/:id/budget/defizit",
      component: _6de7c4f2,
      name: "gesuche-projekte-id-budget-defizit___it"
    }, {
      path: "/it/gesuche/projekte/:id/budget/ertrag",
      component: _e7830de0,
      name: "gesuche-projekte-id-budget-ertrag___it"
    }, {
      path: "/it/gesuche/projekte/:id/budget/institutionelle-spenden",
      component: _97e792ba,
      name: "gesuche-projekte-id-budget-institutionelle-spenden___it"
    }, {
      path: "/it/gesuche/projekte/:id/dam-bedingungen/abschlussbericht-und-bildnachweis",
      component: _a149d030,
      name: "gesuche-projekte-id-dam-bedingungen-abschlussbericht-und-bildnachweis___it"
    }, {
      path: "/it/gesuche/projekte/:id/dam-bedingungen/hinweis-auf-unterstuetzung",
      component: _4b44b123,
      name: "gesuche-projekte-id-dam-bedingungen-hinweis-auf-unterstuetzung___it"
    }, {
      path: "/it/gesuche/projekte/:id/kontaktdaten/bemerkungen",
      component: _014e04a2,
      name: "gesuche-projekte-id-kontaktdaten-bemerkungen___it"
    }, {
      path: "/it/gesuche/projekte/:id/kontaktdaten/datenpruefung",
      component: _d27950f4,
      name: "gesuche-projekte-id-kontaktdaten-datenpruefung___it"
    }, {
      path: "/it/gesuche/projekte/:id/kontaktdaten/kontaktdaten",
      component: _d6f9e438,
      name: "gesuche-projekte-id-kontaktdaten-kontaktdaten___it"
    }, {
      path: "/it/gesuche/projekte/:id/projektdaten/anzahl-personen",
      component: _61e086ae,
      name: "gesuche-projekte-id-projektdaten-anzahl-personen___it"
    }, {
      path: "/it/gesuche/projekte/:id/projektdaten/eingabefrist",
      component: _405c63ec,
      name: "gesuche-projekte-id-projektdaten-eingabefrist___it"
    }, {
      path: "/it/gesuche/projekte/:id/projektdaten/inklusion",
      component: _36712282,
      name: "gesuche-projekte-id-projektdaten-inklusion___it"
    }, {
      path: "/it/gesuche/projekte/:id/projektdaten/konzept",
      component: _2f48d258,
      name: "gesuche-projekte-id-projektdaten-konzept___it"
    }, {
      path: "/it/gesuche/projekte/:id/projektdaten/projektbeschreibung",
      component: _60d3c48e,
      name: "gesuche-projekte-id-projektdaten-projektbeschreibung___it"
    }, {
      path: "/it/gesuche/projekte/:id/projektdaten/projektkategorie",
      component: _2337b783,
      name: "gesuche-projekte-id-projektdaten-projektkategorie___it"
    }, {
      path: "/it/gesuche/projekte/:id/projektdaten/projektlaufzeit",
      component: _98b62d70,
      name: "gesuche-projekte-id-projektdaten-projektlaufzeit___it"
    }, {
      path: "/it/gesuche/projekte/:id/projektdaten/projekttitel",
      component: _15407a4a,
      name: "gesuche-projekte-id-projektdaten-projekttitel___it"
    }, {
      path: "/it/gesuche/projekte/:id/projektdaten/wirkung",
      component: _a3038544,
      name: "gesuche-projekte-id-projektdaten-wirkung___it"
    }, {
      path: "/it/gesuche/projekte/:id/richtlinien-und-zielgruppe/gesuchsart",
      component: _fd86681c,
      name: "gesuche-projekte-id-richtlinien-und-zielgruppe-gesuchsart___it"
    }, {
      path: "/it/gesuche/projekte/:id/richtlinien-und-zielgruppe/kommerzieller-hintergrund",
      component: _1cce2ba4,
      name: "gesuche-projekte-id-richtlinien-und-zielgruppe-kommerzieller-hintergrund___it"
    }, {
      path: "/it/gesuche/projekte/:id/richtlinien-und-zielgruppe/projektleitung",
      component: _8d6dacd4,
      name: "gesuche-projekte-id-richtlinien-und-zielgruppe-projektleitung___it"
    }, {
      path: "/it/gesuche/projekte/:id/richtlinien-und-zielgruppe/richtlinien",
      component: _084ed568,
      name: "gesuche-projekte-id-richtlinien-und-zielgruppe-richtlinien___it"
    }, {
      path: "/it/gesuche/projekte/:id/richtlinien-und-zielgruppe/zielgruppe",
      component: _505b37e4,
      name: "gesuche-projekte-id-richtlinien-und-zielgruppe-zielgruppe___it"
    }]
  }, {
    path: "/fr/gesuche/kurzcheck/einzelperson",
    component: _27fbcc2a,
    name: "gesuche-kurzcheck-einzelperson___fr"
  }, {
    path: "/fr/gesuche/kurzcheck/gruppe",
    component: _a9b45b8e,
    name: "gesuche-kurzcheck-gruppe___fr"
  }, {
    path: "/fr/gesuche/kurzcheck/projekte",
    component: _6ffc3d1c,
    name: "gesuche-kurzcheck-projekte___fr"
  }, {
    path: "/it/gesuche/kurzcheck/einzelperson",
    component: _27fbcc2a,
    name: "gesuche-kurzcheck-einzelperson___it"
  }, {
    path: "/it/gesuche/kurzcheck/gruppe",
    component: _a9b45b8e,
    name: "gesuche-kurzcheck-gruppe___it"
  }, {
    path: "/it/gesuche/kurzcheck/projekte",
    component: _6ffc3d1c,
    name: "gesuche-kurzcheck-projekte___it"
  }, {
    path: "/fr/gesuche/einzelperson-abrechnung/:id",
    component: _770ff060,
    name: "gesuche-einzelperson-abrechnung-id___fr",
    children: [{
      path: "/fr/gesuche/einzelperson-abrechnung/:id/absage",
      component: _4c76c49c,
      name: "gesuche-einzelperson-abrechnung-id-absage___fr"
    }, {
      path: "/fr/gesuche/einzelperson-abrechnung/:id/kontaktdaten/adressat-auszahlung",
      component: _504c5988,
      name: "gesuche-einzelperson-abrechnung-id-kontaktdaten-adressat-auszahlung___fr"
    }, {
      path: "/fr/gesuche/einzelperson-abrechnung/:id/kontaktdaten/datenpruefung",
      component: _2b16bcba,
      name: "gesuche-einzelperson-abrechnung-id-kontaktdaten-datenpruefung___fr"
    }, {
      path: "/fr/gesuche/einzelperson-abrechnung/:id/kontaktdaten/drittperson",
      component: _279463d0,
      name: "gesuche-einzelperson-abrechnung-id-kontaktdaten-drittperson___fr"
    }, {
      path: "/fr/gesuche/einzelperson-abrechnung/:id/kontaktdaten/kontodaten",
      component: _17810d6b,
      name: "gesuche-einzelperson-abrechnung-id-kontaktdaten-kontodaten___fr"
    }, {
      path: "/fr/gesuche/einzelperson-abrechnung/:id/kontaktdaten/selbsteingabe",
      component: _22daf87e,
      name: "gesuche-einzelperson-abrechnung-id-kontaktdaten-selbsteingabe___fr"
    }, {
      path: "/fr/gesuche/einzelperson-abrechnung/:id/kontaktdaten/vertretung",
      component: _046756ee,
      name: "gesuche-einzelperson-abrechnung-id-kontaktdaten-vertretung___fr"
    }, {
      path: "/fr/gesuche/einzelperson-abrechnung/:id/kosten-einnahmen/abrechnungsbereich",
      component: _6ced91a2,
      name: "gesuche-einzelperson-abrechnung-id-kosten-einnahmen-abrechnungsbereich___fr"
    }, {
      path: "/fr/gesuche/einzelperson-abrechnung/:id/kosten-einnahmen/belege-hochladen",
      component: _51e07f3d,
      name: "gesuche-einzelperson-abrechnung-id-kosten-einnahmen-belege-hochladen___fr"
    }, {
      path: "/fr/gesuche/einzelperson-abrechnung/:id/kosten-einnahmen/bestaetigung-realisierung",
      component: _2ed3ce35,
      name: "gesuche-einzelperson-abrechnung-id-kosten-einnahmen-bestaetigung-realisierung___fr"
    }, {
      path: "/fr/gesuche/einzelperson-abrechnung/:id/kosten-einnahmen/bild-hochladen",
      component: _212702bc,
      name: "gesuche-einzelperson-abrechnung-id-kosten-einnahmen-bild-hochladen___fr"
    }, {
      path: "/fr/gesuche/einzelperson-abrechnung/:id/kosten-einnahmen/bildfreigabe",
      component: _30aab2a0,
      name: "gesuche-einzelperson-abrechnung-id-kosten-einnahmen-bildfreigabe___fr"
    }, {
      path: "/fr/gesuche/einzelperson-abrechnung/:id/kosten-einnahmen/defizit",
      component: _0066b22d,
      name: "gesuche-einzelperson-abrechnung-id-kosten-einnahmen-defizit___fr"
    }, {
      path: "/fr/gesuche/einzelperson-abrechnung/:id/kosten-einnahmen/einnahmen",
      component: _03cf75f7,
      name: "gesuche-einzelperson-abrechnung-id-kosten-einnahmen-einnahmen___fr"
    }, {
      path: "/fr/gesuche/einzelperson-abrechnung/:id/kosten-einnahmen/kosten-anschaffung",
      component: _6c2ed606,
      name: "gesuche-einzelperson-abrechnung-id-kosten-einnahmen-kosten-anschaffung___fr"
    }, {
      path: "/fr/gesuche/einzelperson-abrechnung/:id/kosten-einnahmen/kosten-ferienreise",
      component: _c7e34424,
      name: "gesuche-einzelperson-abrechnung-id-kosten-einnahmen-kosten-ferienreise___fr"
    }, {
      path: "/fr/gesuche/einzelperson-abrechnung/:id/kosten-einnahmen/kosten-freizeitaktivitaet",
      component: _5b3f8cff,
      name: "gesuche-einzelperson-abrechnung-id-kosten-einnahmen-kosten-freizeitaktivitaet___fr"
    }, {
      path: "/fr/gesuche/einzelperson-abrechnung/:id/kosten-einnahmen/verifikation-absage",
      component: _967f265a,
      name: "gesuche-einzelperson-abrechnung-id-kosten-einnahmen-verifikation-absage___fr"
    }, {
      path: "/fr/gesuche/einzelperson-abrechnung/:id/kosten-einnahmen/wirkung-anschaffung",
      component: _64b1a8ba,
      name: "gesuche-einzelperson-abrechnung-id-kosten-einnahmen-wirkung-anschaffung___fr"
    }, {
      path: "/fr/gesuche/einzelperson-abrechnung/:id/kosten-einnahmen/wirkung-ferien-freizeit",
      component: _6498ac76,
      name: "gesuche-einzelperson-abrechnung-id-kosten-einnahmen-wirkung-ferien-freizeit___fr"
    }]
  }, {
    path: "/fr/gesuche/gruppe-abrechnung/:id",
    component: _ee2dfebe,
    name: "gesuche-gruppe-abrechnung-id___fr",
    children: [{
      path: "/fr/gesuche/gruppe-abrechnung/:id/absage",
      component: _c75722aa,
      name: "gesuche-gruppe-abrechnung-id-absage___fr"
    }, {
      path: "/fr/gesuche/gruppe-abrechnung/:id/abrechnung-einzelgruppen/anzahl-tage",
      component: _d22c1712,
      name: "gesuche-gruppe-abrechnung-id-abrechnung-einzelgruppen-anzahl-tage___fr"
    }, {
      path: "/fr/gesuche/gruppe-abrechnung/:id/abrechnung-einzelgruppen/anzahl-teilnehmer",
      component: _34a2e369,
      name: "gesuche-gruppe-abrechnung-id-abrechnung-einzelgruppen-anzahl-teilnehmer___fr"
    }, {
      path: "/fr/gesuche/gruppe-abrechnung/:id/abrechnung-einzelgruppen/ausgaben",
      component: _7f9a426a,
      name: "gesuche-gruppe-abrechnung-id-abrechnung-einzelgruppen-ausgaben___fr"
    }, {
      path: "/fr/gesuche/gruppe-abrechnung/:id/abrechnung-einzelgruppen/belege-hochladen",
      component: _01d04f52,
      name: "gesuche-gruppe-abrechnung-id-abrechnung-einzelgruppen-belege-hochladen___fr"
    }, {
      path: "/fr/gesuche/gruppe-abrechnung/:id/abrechnung-einzelgruppen/defizit",
      component: _742cf310,
      name: "gesuche-gruppe-abrechnung-id-abrechnung-einzelgruppen-defizit___fr"
    }, {
      path: "/fr/gesuche/gruppe-abrechnung/:id/abrechnung-einzelgruppen/einnahmen",
      component: _73ee6382,
      name: "gesuche-gruppe-abrechnung-id-abrechnung-einzelgruppen-einnahmen___fr"
    }, {
      path: "/fr/gesuche/gruppe-abrechnung/:id/abrechnung-einzelgruppen/teilnehmerliste",
      component: _01771e01,
      name: "gesuche-gruppe-abrechnung-id-abrechnung-einzelgruppen-teilnehmerliste___fr"
    }, {
      path: "/fr/gesuche/gruppe-abrechnung/:id/abrechnung-sammelgesuch/abrechnung",
      component: _2dd6fab8,
      name: "gesuche-gruppe-abrechnung-id-abrechnung-sammelgesuch-abrechnung___fr"
    }, {
      path: "/fr/gesuche/gruppe-abrechnung/:id/abrechnung-sammelgesuch/defizit-schlussabrechnung",
      component: _3be336df,
      name: "gesuche-gruppe-abrechnung-id-abrechnung-sammelgesuch-defizit-schlussabrechnung___fr"
    }, {
      path: "/fr/gesuche/gruppe-abrechnung/:id/abrechnung-sammelgesuch/defizit-teilabrechnung",
      component: _d2286390,
      name: "gesuche-gruppe-abrechnung-id-abrechnung-sammelgesuch-defizit-teilabrechnung___fr"
    }, {
      path: "/fr/gesuche/gruppe-abrechnung/:id/abrechnung-sammelgesuch/schlussabrechnung",
      component: _1f73f5ea,
      name: "gesuche-gruppe-abrechnung-id-abrechnung-sammelgesuch-schlussabrechnung___fr"
    }, {
      path: "/fr/gesuche/gruppe-abrechnung/:id/abrechnung-sammelgesuch/teilabrechnung",
      component: _720b8f8c,
      name: "gesuche-gruppe-abrechnung-id-abrechnung-sammelgesuch-teilabrechnung___fr"
    }, {
      path: "/fr/gesuche/gruppe-abrechnung/:id/abrechnung-sammelgesuch/teilabrechnung-beenden",
      component: _d0fd75c0,
      name: "gesuche-gruppe-abrechnung-id-abrechnung-sammelgesuch-teilabrechnung-beenden___fr"
    }, {
      path: "/fr/gesuche/gruppe-abrechnung/:id/abrechnung-sammelgesuch/teilabrechnung-upload",
      component: _5340a6dc,
      name: "gesuche-gruppe-abrechnung-id-abrechnung-sammelgesuch-teilabrechnung-upload___fr"
    }, {
      path: "/fr/gesuche/gruppe-abrechnung/:id/abschluss/verifikation",
      component: _5288c1c0,
      name: "gesuche-gruppe-abrechnung-id-abschluss-verifikation___fr"
    }, {
      path: "/fr/gesuche/gruppe-abrechnung/:id/abschluss/vielen-dank",
      component: _e2351672,
      name: "gesuche-gruppe-abrechnung-id-abschluss-vielen-dank___fr"
    }, {
      path: "/fr/gesuche/gruppe-abrechnung/:id/bestaetigung-realisierung/bestaetigung-realisierung",
      component: _44922356,
      name: "gesuche-gruppe-abrechnung-id-bestaetigung-realisierung-bestaetigung-realisierung___fr"
    }, {
      path: "/fr/gesuche/gruppe-abrechnung/:id/bestaetigung-realisierung/gesuch-absagen",
      component: _3408d81d,
      name: "gesuche-gruppe-abrechnung-id-bestaetigung-realisierung-gesuch-absagen___fr"
    }, {
      path: "/fr/gesuche/gruppe-abrechnung/:id/bestaetigung-realisierung/unterlagen-abrechnung",
      component: _66d1079f,
      name: "gesuche-gruppe-abrechnung-id-bestaetigung-realisierung-unterlagen-abrechnung___fr"
    }, {
      path: "/fr/gesuche/gruppe-abrechnung/:id/bestaetigung-realisierung/zielgruppe",
      component: _d31f8c56,
      name: "gesuche-gruppe-abrechnung-id-bestaetigung-realisierung-zielgruppe___fr"
    }, {
      path: "/fr/gesuche/gruppe-abrechnung/:id/dam-bedingungen/bild-hochladen",
      component: _bc8a271c,
      name: "gesuche-gruppe-abrechnung-id-dam-bedingungen-bild-hochladen___fr"
    }, {
      path: "/fr/gesuche/gruppe-abrechnung/:id/dam-bedingungen/bilder-hochladen",
      component: _5d4b0b9f,
      name: "gesuche-gruppe-abrechnung-id-dam-bedingungen-bilder-hochladen___fr"
    }, {
      path: "/fr/gesuche/gruppe-abrechnung/:id/dam-bedingungen/bildfreigabe",
      component: _714b6f00,
      name: "gesuche-gruppe-abrechnung-id-dam-bedingungen-bildfreigabe___fr"
    }, {
      path: "/fr/gesuche/gruppe-abrechnung/:id/dam-bedingungen/inklusionsnachweis",
      component: _7472d493,
      name: "gesuche-gruppe-abrechnung-id-dam-bedingungen-inklusionsnachweis___fr"
    }, {
      path: "/fr/gesuche/gruppe-abrechnung/:id/dam-bedingungen/inklusuionskriterien",
      component: _f1ce2080,
      name: "gesuche-gruppe-abrechnung-id-dam-bedingungen-inklusionskriterien___fr"
    }, {
      path: "/fr/gesuche/gruppe-abrechnung/:id/dam-bedingungen/logo-antragsteller",
      component: _eeefb79c,
      name: "gesuche-gruppe-abrechnung-id-dam-bedingungen-logo-antragsteller___fr"
    }, {
      path: "/fr/gesuche/gruppe-abrechnung/:id/dam-bedingungen/nachweis-unterstuetzung",
      component: _3a269788,
      name: "gesuche-gruppe-abrechnung-id-dam-bedingungen-nachweis-unterstuetzung___fr"
    }, {
      path: "/fr/gesuche/gruppe-abrechnung/:id/dam-bedingungen/wirkung",
      component: _e894b382,
      name: "gesuche-gruppe-abrechnung-id-dam-bedingungen-wirkung___fr"
    }, {
      path: "/fr/gesuche/gruppe-abrechnung/:id/kontaktdaten/kontaktdaten-kontoinhaber",
      component: _68efb808,
      name: "gesuche-gruppe-abrechnung-id-kontaktdaten-kontaktdaten-kontoinhaber___fr"
    }, {
      path: "/fr/gesuche/gruppe-abrechnung/:id/kontaktdaten/kontodaten",
      component: _41f8e33c,
      name: "gesuche-gruppe-abrechnung-id-kontaktdaten-kontodaten___fr"
    }]
  }, {
    path: "/fr/gesuche/projekte-abrechnung/:id",
    component: _f62c03f0,
    name: "gesuche-projekte-abrechnung-id___fr",
    children: [{
      path: "/fr/gesuche/projekte-abrechnung/:id/abrechnung-projekte/effektiver-aufwand",
      component: _1c94ce06,
      name: "gesuche-projekte-abrechnung-id-abrechnung-projekte-effektiver-aufwand___fr"
    }, {
      path: "/fr/gesuche/projekte-abrechnung/:id/abrechnung-projekte/effektiver-ertrag",
      component: _172bc9a6,
      name: "gesuche-projekte-abrechnung-id-abrechnung-projekte-effektiver-ertrag___fr"
    }, {
      path: "/fr/gesuche/projekte-abrechnung/:id/abrechnung-projekte/effektives-defizit",
      component: _0c992614,
      name: "gesuche-projekte-abrechnung-id-abrechnung-projekte-effektives-defizit___fr"
    }, {
      path: "/fr/gesuche/projekte-abrechnung/:id/abrechnung-projekte/institutionelle-spenden",
      component: _04928e44,
      name: "gesuche-projekte-abrechnung-id-abrechnung-projekte-institutionelle-spenden___fr"
    }, {
      path: "/fr/gesuche/projekte-abrechnung/:id/abrechnung-projekte/kein-defizit",
      component: _66723c51,
      name: "gesuche-projekte-abrechnung-id-abrechnung-projekte-kein-defizit___fr"
    }, {
      path: "/fr/gesuche/projekte-abrechnung/:id/abrechnung-projekte/reichweite",
      component: _d32fc122,
      name: "gesuche-projekte-abrechnung-id-abrechnung-projekte-reichweite___fr"
    }, {
      path: "/fr/gesuche/projekte-abrechnung/:id/abschluss/absage",
      component: _4bbb38da,
      name: "gesuche-projekte-abrechnung-id-abschluss-absage___fr"
    }, {
      path: "/fr/gesuche/projekte-abrechnung/:id/abschluss/verifikation",
      component: _498e7dd9,
      name: "gesuche-projekte-abrechnung-id-abschluss-verifikation___fr"
    }, {
      path: "/fr/gesuche/projekte-abrechnung/:id/abschluss/vielen-dank",
      component: _694f5aee,
      name: "gesuche-projekte-abrechnung-id-abschluss-vielen-dank___fr"
    }, {
      path: "/fr/gesuche/projekte-abrechnung/:id/bestaetigung-realisierung/gesuch-absagen",
      component: _917e4ad4,
      name: "gesuche-projekte-abrechnung-id-bestaetigung-realisierung-gesuch-absagen___fr"
    }, {
      path: "/fr/gesuche/projekte-abrechnung/:id/bestaetigung-realisierung/projektabschluss",
      component: _af9fa268,
      name: "gesuche-projekte-abrechnung-id-bestaetigung-realisierung-projektabschluss___fr"
    }, {
      path: "/fr/gesuche/projekte-abrechnung/:id/bestaetigung-realisierung/unterlagen-abrechnung",
      component: _3060fb06,
      name: "gesuche-projekte-abrechnung-id-bestaetigung-realisierung-unterlagen-abrechnung___fr"
    }, {
      path: "/fr/gesuche/projekte-abrechnung/:id/dam-bedingungen/abschlussbericht",
      component: _28902cbc,
      name: "gesuche-projekte-abrechnung-id-dam-bedingungen-abschlussbericht___fr"
    }, {
      path: "/fr/gesuche/projekte-abrechnung/:id/dam-bedingungen/bilder-hochladen",
      component: _4434d1d0,
      name: "gesuche-projekte-abrechnung-id-dam-bedingungen-bilder-hochladen___fr"
    }, {
      path: "/fr/gesuche/projekte-abrechnung/:id/dam-bedingungen/bildfreigabe",
      component: _366f7879,
      name: "gesuche-projekte-abrechnung-id-dam-bedingungen-bildfreigabe___fr"
    }, {
      path: "/fr/gesuche/projekte-abrechnung/:id/dam-bedingungen/inklusionsnachweis",
      component: _389865cc,
      name: "gesuche-projekte-abrechnung-id-dam-bedingungen-inklusionsnachweis___fr"
    }, {
      path: "/fr/gesuche/projekte-abrechnung/:id/dam-bedingungen/logo-antragsteller",
      component: _4cadb56b,
      name: "gesuche-projekte-abrechnung-id-dam-bedingungen-logo-antragsteller___fr"
    }, {
      path: "/fr/gesuche/projekte-abrechnung/:id/dam-bedingungen/nachweis-der-unterstuetzung",
      component: _180e15aa,
      name: "gesuche-projekte-abrechnung-id-dam-bedingungen-nachweis-der-unterstuetzung___fr"
    }, {
      path: "/fr/gesuche/projekte-abrechnung/:id/kontaktdaten/bemerkungen",
      component: _cbf5ceaa,
      name: "gesuche-projekte-abrechnung-id-kontaktdaten-bemerkungen___fr"
    }, {
      path: "/fr/gesuche/projekte-abrechnung/:id/kontaktdaten/kontodaten",
      component: _2981dba3,
      name: "gesuche-projekte-abrechnung-id-kontaktdaten-kontodaten___fr"
    }, {
      path: "/fr/gesuche/projekte-abrechnung/:id/kontaktdaten/kontoinhaber",
      component: _70a940d4,
      name: "gesuche-projekte-abrechnung-id-kontaktdaten-kontoinhaber___fr"
    }]
  }, {
    path: "/it/gesuche/einzelperson-abrechnung/:id",
    component: _770ff060,
    name: "gesuche-einzelperson-abrechnung-id___it",
    children: [{
      path: "/it/gesuche/einzelperson-abrechnung/:id/absage",
      component: _4c76c49c,
      name: "gesuche-einzelperson-abrechnung-id-absage___it"
    }, {
      path: "/it/gesuche/einzelperson-abrechnung/:id/kontaktdaten/adressat-auszahlung",
      component: _504c5988,
      name: "gesuche-einzelperson-abrechnung-id-kontaktdaten-adressat-auszahlung___it"
    }, {
      path: "/it/gesuche/einzelperson-abrechnung/:id/kontaktdaten/datenpruefung",
      component: _2b16bcba,
      name: "gesuche-einzelperson-abrechnung-id-kontaktdaten-datenpruefung___it"
    }, {
      path: "/it/gesuche/einzelperson-abrechnung/:id/kontaktdaten/drittperson",
      component: _279463d0,
      name: "gesuche-einzelperson-abrechnung-id-kontaktdaten-drittperson___it"
    }, {
      path: "/it/gesuche/einzelperson-abrechnung/:id/kontaktdaten/kontodaten",
      component: _17810d6b,
      name: "gesuche-einzelperson-abrechnung-id-kontaktdaten-kontodaten___it"
    }, {
      path: "/it/gesuche/einzelperson-abrechnung/:id/kontaktdaten/selbsteingabe",
      component: _22daf87e,
      name: "gesuche-einzelperson-abrechnung-id-kontaktdaten-selbsteingabe___it"
    }, {
      path: "/it/gesuche/einzelperson-abrechnung/:id/kontaktdaten/vertretung",
      component: _046756ee,
      name: "gesuche-einzelperson-abrechnung-id-kontaktdaten-vertretung___it"
    }, {
      path: "/it/gesuche/einzelperson-abrechnung/:id/kosten-einnahmen/abrechnungsbereich",
      component: _6ced91a2,
      name: "gesuche-einzelperson-abrechnung-id-kosten-einnahmen-abrechnungsbereich___it"
    }, {
      path: "/it/gesuche/einzelperson-abrechnung/:id/kosten-einnahmen/belege-hochladen",
      component: _51e07f3d,
      name: "gesuche-einzelperson-abrechnung-id-kosten-einnahmen-belege-hochladen___it"
    }, {
      path: "/it/gesuche/einzelperson-abrechnung/:id/kosten-einnahmen/bestaetigung-realisierung",
      component: _2ed3ce35,
      name: "gesuche-einzelperson-abrechnung-id-kosten-einnahmen-bestaetigung-realisierung___it"
    }, {
      path: "/it/gesuche/einzelperson-abrechnung/:id/kosten-einnahmen/bild-hochladen",
      component: _212702bc,
      name: "gesuche-einzelperson-abrechnung-id-kosten-einnahmen-bild-hochladen___it"
    }, {
      path: "/it/gesuche/einzelperson-abrechnung/:id/kosten-einnahmen/bildfreigabe",
      component: _30aab2a0,
      name: "gesuche-einzelperson-abrechnung-id-kosten-einnahmen-bildfreigabe___it"
    }, {
      path: "/it/gesuche/einzelperson-abrechnung/:id/kosten-einnahmen/defizit",
      component: _0066b22d,
      name: "gesuche-einzelperson-abrechnung-id-kosten-einnahmen-defizit___it"
    }, {
      path: "/it/gesuche/einzelperson-abrechnung/:id/kosten-einnahmen/einnahmen",
      component: _03cf75f7,
      name: "gesuche-einzelperson-abrechnung-id-kosten-einnahmen-einnahmen___it"
    }, {
      path: "/it/gesuche/einzelperson-abrechnung/:id/kosten-einnahmen/kosten-anschaffung",
      component: _6c2ed606,
      name: "gesuche-einzelperson-abrechnung-id-kosten-einnahmen-kosten-anschaffung___it"
    }, {
      path: "/it/gesuche/einzelperson-abrechnung/:id/kosten-einnahmen/kosten-ferienreise",
      component: _c7e34424,
      name: "gesuche-einzelperson-abrechnung-id-kosten-einnahmen-kosten-ferienreise___it"
    }, {
      path: "/it/gesuche/einzelperson-abrechnung/:id/kosten-einnahmen/kosten-freizeitaktivitaet",
      component: _5b3f8cff,
      name: "gesuche-einzelperson-abrechnung-id-kosten-einnahmen-kosten-freizeitaktivitaet___it"
    }, {
      path: "/it/gesuche/einzelperson-abrechnung/:id/kosten-einnahmen/verifikation-absage",
      component: _967f265a,
      name: "gesuche-einzelperson-abrechnung-id-kosten-einnahmen-verifikation-absage___it"
    }, {
      path: "/it/gesuche/einzelperson-abrechnung/:id/kosten-einnahmen/wirkung-anschaffung",
      component: _64b1a8ba,
      name: "gesuche-einzelperson-abrechnung-id-kosten-einnahmen-wirkung-anschaffung___it"
    }, {
      path: "/it/gesuche/einzelperson-abrechnung/:id/kosten-einnahmen/wirkung-ferien-freizeit",
      component: _6498ac76,
      name: "gesuche-einzelperson-abrechnung-id-kosten-einnahmen-wirkung-ferien-freizeit___it"
    }]
  }, {
    path: "/it/gesuche/gruppe-abrechnung/:id",
    component: _ee2dfebe,
    name: "gesuche-gruppe-abrechnung-id___it",
    children: [{
      path: "/it/gesuche/gruppe-abrechnung/:id/absage",
      component: _c75722aa,
      name: "gesuche-gruppe-abrechnung-id-absage___it"
    }, {
      path: "/it/gesuche/gruppe-abrechnung/:id/abrechnung-einzelgruppen/anzahl-tage",
      component: _d22c1712,
      name: "gesuche-gruppe-abrechnung-id-abrechnung-einzelgruppen-anzahl-tage___it"
    }, {
      path: "/it/gesuche/gruppe-abrechnung/:id/abrechnung-einzelgruppen/anzahl-teilnehmer",
      component: _34a2e369,
      name: "gesuche-gruppe-abrechnung-id-abrechnung-einzelgruppen-anzahl-teilnehmer___it"
    }, {
      path: "/it/gesuche/gruppe-abrechnung/:id/abrechnung-einzelgruppen/ausgaben",
      component: _7f9a426a,
      name: "gesuche-gruppe-abrechnung-id-abrechnung-einzelgruppen-ausgaben___it"
    }, {
      path: "/it/gesuche/gruppe-abrechnung/:id/abrechnung-einzelgruppen/belege-hochladen",
      component: _01d04f52,
      name: "gesuche-gruppe-abrechnung-id-abrechnung-einzelgruppen-belege-hochladen___it"
    }, {
      path: "/it/gesuche/gruppe-abrechnung/:id/abrechnung-einzelgruppen/defizit",
      component: _742cf310,
      name: "gesuche-gruppe-abrechnung-id-abrechnung-einzelgruppen-defizit___it"
    }, {
      path: "/it/gesuche/gruppe-abrechnung/:id/abrechnung-einzelgruppen/einnahmen",
      component: _73ee6382,
      name: "gesuche-gruppe-abrechnung-id-abrechnung-einzelgruppen-einnahmen___it"
    }, {
      path: "/it/gesuche/gruppe-abrechnung/:id/abrechnung-einzelgruppen/teilnehmerliste",
      component: _01771e01,
      name: "gesuche-gruppe-abrechnung-id-abrechnung-einzelgruppen-teilnehmerliste___it"
    }, {
      path: "/it/gesuche/gruppe-abrechnung/:id/abrechnung-sammelgesuch/abrechnung",
      component: _2dd6fab8,
      name: "gesuche-gruppe-abrechnung-id-abrechnung-sammelgesuch-abrechnung___it"
    }, {
      path: "/it/gesuche/gruppe-abrechnung/:id/abrechnung-sammelgesuch/defizit-schlussabrechnung",
      component: _3be336df,
      name: "gesuche-gruppe-abrechnung-id-abrechnung-sammelgesuch-defizit-schlussabrechnung___it"
    }, {
      path: "/it/gesuche/gruppe-abrechnung/:id/abrechnung-sammelgesuch/defizit-teilabrechnung",
      component: _d2286390,
      name: "gesuche-gruppe-abrechnung-id-abrechnung-sammelgesuch-defizit-teilabrechnung___it"
    }, {
      path: "/it/gesuche/gruppe-abrechnung/:id/abrechnung-sammelgesuch/schlussabrechnung",
      component: _1f73f5ea,
      name: "gesuche-gruppe-abrechnung-id-abrechnung-sammelgesuch-schlussabrechnung___it"
    }, {
      path: "/it/gesuche/gruppe-abrechnung/:id/abrechnung-sammelgesuch/teilabrechnung",
      component: _720b8f8c,
      name: "gesuche-gruppe-abrechnung-id-abrechnung-sammelgesuch-teilabrechnung___it"
    }, {
      path: "/it/gesuche/gruppe-abrechnung/:id/abrechnung-sammelgesuch/teilabrechnung-beenden",
      component: _d0fd75c0,
      name: "gesuche-gruppe-abrechnung-id-abrechnung-sammelgesuch-teilabrechnung-beenden___it"
    }, {
      path: "/it/gesuche/gruppe-abrechnung/:id/abrechnung-sammelgesuch/teilabrechnung-upload",
      component: _5340a6dc,
      name: "gesuche-gruppe-abrechnung-id-abrechnung-sammelgesuch-teilabrechnung-upload___it"
    }, {
      path: "/it/gesuche/gruppe-abrechnung/:id/abschluss/verifikation",
      component: _5288c1c0,
      name: "gesuche-gruppe-abrechnung-id-abschluss-verifikation___it"
    }, {
      path: "/it/gesuche/gruppe-abrechnung/:id/abschluss/vielen-dank",
      component: _e2351672,
      name: "gesuche-gruppe-abrechnung-id-abschluss-vielen-dank___it"
    }, {
      path: "/it/gesuche/gruppe-abrechnung/:id/bestaetigung-realisierung/bestaetigung-realisierung",
      component: _44922356,
      name: "gesuche-gruppe-abrechnung-id-bestaetigung-realisierung-bestaetigung-realisierung___it"
    }, {
      path: "/it/gesuche/gruppe-abrechnung/:id/bestaetigung-realisierung/gesuch-absagen",
      component: _3408d81d,
      name: "gesuche-gruppe-abrechnung-id-bestaetigung-realisierung-gesuch-absagen___it"
    }, {
      path: "/it/gesuche/gruppe-abrechnung/:id/bestaetigung-realisierung/unterlagen-abrechnung",
      component: _66d1079f,
      name: "gesuche-gruppe-abrechnung-id-bestaetigung-realisierung-unterlagen-abrechnung___it"
    }, {
      path: "/it/gesuche/gruppe-abrechnung/:id/bestaetigung-realisierung/zielgruppe",
      component: _d31f8c56,
      name: "gesuche-gruppe-abrechnung-id-bestaetigung-realisierung-zielgruppe___it"
    }, {
      path: "/it/gesuche/gruppe-abrechnung/:id/dam-bedingungen/bild-hochladen",
      component: _bc8a271c,
      name: "gesuche-gruppe-abrechnung-id-dam-bedingungen-bild-hochladen___it"
    }, {
      path: "/it/gesuche/gruppe-abrechnung/:id/dam-bedingungen/bilder-hochladen",
      component: _5d4b0b9f,
      name: "gesuche-gruppe-abrechnung-id-dam-bedingungen-bilder-hochladen___it"
    }, {
      path: "/it/gesuche/gruppe-abrechnung/:id/dam-bedingungen/bildfreigabe",
      component: _714b6f00,
      name: "gesuche-gruppe-abrechnung-id-dam-bedingungen-bildfreigabe___it"
    }, {
      path: "/it/gesuche/gruppe-abrechnung/:id/dam-bedingungen/inklusionsnachweis",
      component: _7472d493,
      name: "gesuche-gruppe-abrechnung-id-dam-bedingungen-inklusionsnachweis___it"
    }, {
      path: "/it/gesuche/gruppe-abrechnung/:id/dam-bedingungen/inklusuionskriterien",
      component: _f1ce2080,
      name: "gesuche-gruppe-abrechnung-id-dam-bedingungen-inklusionskriterien___it"
    }, {
      path: "/it/gesuche/gruppe-abrechnung/:id/dam-bedingungen/logo-antragsteller",
      component: _eeefb79c,
      name: "gesuche-gruppe-abrechnung-id-dam-bedingungen-logo-antragsteller___it"
    }, {
      path: "/it/gesuche/gruppe-abrechnung/:id/dam-bedingungen/nachweis-unterstuetzung",
      component: _3a269788,
      name: "gesuche-gruppe-abrechnung-id-dam-bedingungen-nachweis-unterstuetzung___it"
    }, {
      path: "/it/gesuche/gruppe-abrechnung/:id/dam-bedingungen/wirkung",
      component: _e894b382,
      name: "gesuche-gruppe-abrechnung-id-dam-bedingungen-wirkung___it"
    }, {
      path: "/it/gesuche/gruppe-abrechnung/:id/kontaktdaten/kontaktdaten-kontoinhaber",
      component: _68efb808,
      name: "gesuche-gruppe-abrechnung-id-kontaktdaten-kontaktdaten-kontoinhaber___it"
    }, {
      path: "/it/gesuche/gruppe-abrechnung/:id/kontaktdaten/kontodaten",
      component: _41f8e33c,
      name: "gesuche-gruppe-abrechnung-id-kontaktdaten-kontodaten___it"
    }]
  }, {
    path: "/it/gesuche/projekte-abrechnung/:id",
    component: _f62c03f0,
    name: "gesuche-projekte-abrechnung-id___it",
    children: [{
      path: "/it/gesuche/projekte-abrechnung/:id/abrechnung-projekte/effektiver-aufwand",
      component: _1c94ce06,
      name: "gesuche-projekte-abrechnung-id-abrechnung-projekte-effektiver-aufwand___it"
    }, {
      path: "/it/gesuche/projekte-abrechnung/:id/abrechnung-projekte/effektiver-ertrag",
      component: _172bc9a6,
      name: "gesuche-projekte-abrechnung-id-abrechnung-projekte-effektiver-ertrag___it"
    }, {
      path: "/it/gesuche/projekte-abrechnung/:id/abrechnung-projekte/effektives-defizit",
      component: _0c992614,
      name: "gesuche-projekte-abrechnung-id-abrechnung-projekte-effektives-defizit___it"
    }, {
      path: "/it/gesuche/projekte-abrechnung/:id/abrechnung-projekte/institutionelle-spenden",
      component: _04928e44,
      name: "gesuche-projekte-abrechnung-id-abrechnung-projekte-institutionelle-spenden___it"
    }, {
      path: "/it/gesuche/projekte-abrechnung/:id/abrechnung-projekte/kein-defizit",
      component: _66723c51,
      name: "gesuche-projekte-abrechnung-id-abrechnung-projekte-kein-defizit___it"
    }, {
      path: "/it/gesuche/projekte-abrechnung/:id/abrechnung-projekte/reichweite",
      component: _d32fc122,
      name: "gesuche-projekte-abrechnung-id-abrechnung-projekte-reichweite___it"
    }, {
      path: "/it/gesuche/projekte-abrechnung/:id/abschluss/absage",
      component: _4bbb38da,
      name: "gesuche-projekte-abrechnung-id-abschluss-absage___it"
    }, {
      path: "/it/gesuche/projekte-abrechnung/:id/abschluss/verifikation",
      component: _498e7dd9,
      name: "gesuche-projekte-abrechnung-id-abschluss-verifikation___it"
    }, {
      path: "/it/gesuche/projekte-abrechnung/:id/abschluss/vielen-dank",
      component: _694f5aee,
      name: "gesuche-projekte-abrechnung-id-abschluss-vielen-dank___it"
    }, {
      path: "/it/gesuche/projekte-abrechnung/:id/bestaetigung-realisierung/gesuch-absagen",
      component: _917e4ad4,
      name: "gesuche-projekte-abrechnung-id-bestaetigung-realisierung-gesuch-absagen___it"
    }, {
      path: "/it/gesuche/projekte-abrechnung/:id/bestaetigung-realisierung/projektabschluss",
      component: _af9fa268,
      name: "gesuche-projekte-abrechnung-id-bestaetigung-realisierung-projektabschluss___it"
    }, {
      path: "/it/gesuche/projekte-abrechnung/:id/bestaetigung-realisierung/unterlagen-abrechnung",
      component: _3060fb06,
      name: "gesuche-projekte-abrechnung-id-bestaetigung-realisierung-unterlagen-abrechnung___it"
    }, {
      path: "/it/gesuche/projekte-abrechnung/:id/dam-bedingungen/abschlussbericht",
      component: _28902cbc,
      name: "gesuche-projekte-abrechnung-id-dam-bedingungen-abschlussbericht___it"
    }, {
      path: "/it/gesuche/projekte-abrechnung/:id/dam-bedingungen/bilder-hochladen",
      component: _4434d1d0,
      name: "gesuche-projekte-abrechnung-id-dam-bedingungen-bilder-hochladen___it"
    }, {
      path: "/it/gesuche/projekte-abrechnung/:id/dam-bedingungen/bildfreigabe",
      component: _366f7879,
      name: "gesuche-projekte-abrechnung-id-dam-bedingungen-bildfreigabe___it"
    }, {
      path: "/it/gesuche/projekte-abrechnung/:id/dam-bedingungen/inklusionsnachweis",
      component: _389865cc,
      name: "gesuche-projekte-abrechnung-id-dam-bedingungen-inklusionsnachweis___it"
    }, {
      path: "/it/gesuche/projekte-abrechnung/:id/dam-bedingungen/logo-antragsteller",
      component: _4cadb56b,
      name: "gesuche-projekte-abrechnung-id-dam-bedingungen-logo-antragsteller___it"
    }, {
      path: "/it/gesuche/projekte-abrechnung/:id/dam-bedingungen/nachweis-der-unterstuetzung",
      component: _180e15aa,
      name: "gesuche-projekte-abrechnung-id-dam-bedingungen-nachweis-der-unterstuetzung___it"
    }, {
      path: "/it/gesuche/projekte-abrechnung/:id/kontaktdaten/bemerkungen",
      component: _cbf5ceaa,
      name: "gesuche-projekte-abrechnung-id-kontaktdaten-bemerkungen___it"
    }, {
      path: "/it/gesuche/projekte-abrechnung/:id/kontaktdaten/kontodaten",
      component: _2981dba3,
      name: "gesuche-projekte-abrechnung-id-kontaktdaten-kontodaten___it"
    }, {
      path: "/it/gesuche/projekte-abrechnung/:id/kontaktdaten/kontoinhaber",
      component: _70a940d4,
      name: "gesuche-projekte-abrechnung-id-kontaktdaten-kontoinhaber___it"
    }]
  }, {
    path: "/fr/login/:code",
    component: _5b014400,
    name: "login-code___fr"
  }, {
    path: "/gesuche/einzelperson-abrechnung/:id",
    component: _770ff060,
    name: "gesuche-einzelperson-abrechnung-id___de",
    children: [{
      path: "/gesuche/einzelperson-abrechnung/:id/absage",
      component: _4c76c49c,
      name: "gesuche-einzelperson-abrechnung-id-absage___de"
    }, {
      path: "/gesuche/einzelperson-abrechnung/:id/kontaktdaten/adressat-auszahlung",
      component: _504c5988,
      name: "gesuche-einzelperson-abrechnung-id-kontaktdaten-adressat-auszahlung___de"
    }, {
      path: "/gesuche/einzelperson-abrechnung/:id/kontaktdaten/datenpruefung",
      component: _2b16bcba,
      name: "gesuche-einzelperson-abrechnung-id-kontaktdaten-datenpruefung___de"
    }, {
      path: "/gesuche/einzelperson-abrechnung/:id/kontaktdaten/drittperson",
      component: _279463d0,
      name: "gesuche-einzelperson-abrechnung-id-kontaktdaten-drittperson___de"
    }, {
      path: "/gesuche/einzelperson-abrechnung/:id/kontaktdaten/kontodaten",
      component: _17810d6b,
      name: "gesuche-einzelperson-abrechnung-id-kontaktdaten-kontodaten___de"
    }, {
      path: "/gesuche/einzelperson-abrechnung/:id/kontaktdaten/selbsteingabe",
      component: _22daf87e,
      name: "gesuche-einzelperson-abrechnung-id-kontaktdaten-selbsteingabe___de"
    }, {
      path: "/gesuche/einzelperson-abrechnung/:id/kontaktdaten/vertretung",
      component: _046756ee,
      name: "gesuche-einzelperson-abrechnung-id-kontaktdaten-vertretung___de"
    }, {
      path: "/gesuche/einzelperson-abrechnung/:id/kosten-einnahmen/abrechnungsbereich",
      component: _6ced91a2,
      name: "gesuche-einzelperson-abrechnung-id-kosten-einnahmen-abrechnungsbereich___de"
    }, {
      path: "/gesuche/einzelperson-abrechnung/:id/kosten-einnahmen/belege-hochladen",
      component: _51e07f3d,
      name: "gesuche-einzelperson-abrechnung-id-kosten-einnahmen-belege-hochladen___de"
    }, {
      path: "/gesuche/einzelperson-abrechnung/:id/kosten-einnahmen/bestaetigung-realisierung",
      component: _2ed3ce35,
      name: "gesuche-einzelperson-abrechnung-id-kosten-einnahmen-bestaetigung-realisierung___de"
    }, {
      path: "/gesuche/einzelperson-abrechnung/:id/kosten-einnahmen/bild-hochladen",
      component: _212702bc,
      name: "gesuche-einzelperson-abrechnung-id-kosten-einnahmen-bild-hochladen___de"
    }, {
      path: "/gesuche/einzelperson-abrechnung/:id/kosten-einnahmen/bildfreigabe",
      component: _30aab2a0,
      name: "gesuche-einzelperson-abrechnung-id-kosten-einnahmen-bildfreigabe___de"
    }, {
      path: "/gesuche/einzelperson-abrechnung/:id/kosten-einnahmen/defizit",
      component: _0066b22d,
      name: "gesuche-einzelperson-abrechnung-id-kosten-einnahmen-defizit___de"
    }, {
      path: "/gesuche/einzelperson-abrechnung/:id/kosten-einnahmen/einnahmen",
      component: _03cf75f7,
      name: "gesuche-einzelperson-abrechnung-id-kosten-einnahmen-einnahmen___de"
    }, {
      path: "/gesuche/einzelperson-abrechnung/:id/kosten-einnahmen/kosten-anschaffung",
      component: _6c2ed606,
      name: "gesuche-einzelperson-abrechnung-id-kosten-einnahmen-kosten-anschaffung___de"
    }, {
      path: "/gesuche/einzelperson-abrechnung/:id/kosten-einnahmen/kosten-ferienreise",
      component: _c7e34424,
      name: "gesuche-einzelperson-abrechnung-id-kosten-einnahmen-kosten-ferienreise___de"
    }, {
      path: "/gesuche/einzelperson-abrechnung/:id/kosten-einnahmen/kosten-freizeitaktivitaet",
      component: _5b3f8cff,
      name: "gesuche-einzelperson-abrechnung-id-kosten-einnahmen-kosten-freizeitaktivitaet___de"
    }, {
      path: "/gesuche/einzelperson-abrechnung/:id/kosten-einnahmen/verifikation-absage",
      component: _967f265a,
      name: "gesuche-einzelperson-abrechnung-id-kosten-einnahmen-verifikation-absage___de"
    }, {
      path: "/gesuche/einzelperson-abrechnung/:id/kosten-einnahmen/wirkung-anschaffung",
      component: _64b1a8ba,
      name: "gesuche-einzelperson-abrechnung-id-kosten-einnahmen-wirkung-anschaffung___de"
    }, {
      path: "/gesuche/einzelperson-abrechnung/:id/kosten-einnahmen/wirkung-ferien-freizeit",
      component: _6498ac76,
      name: "gesuche-einzelperson-abrechnung-id-kosten-einnahmen-wirkung-ferien-freizeit___de"
    }]
  }, {
    path: "/gesuche/gruppe-abrechnung/:id",
    component: _ee2dfebe,
    name: "gesuche-gruppe-abrechnung-id___de",
    children: [{
      path: "/gesuche/gruppe-abrechnung/:id/absage",
      component: _c75722aa,
      name: "gesuche-gruppe-abrechnung-id-absage___de"
    }, {
      path: "/gesuche/gruppe-abrechnung/:id/abrechnung-einzelgruppen/anzahl-tage",
      component: _d22c1712,
      name: "gesuche-gruppe-abrechnung-id-abrechnung-einzelgruppen-anzahl-tage___de"
    }, {
      path: "/gesuche/gruppe-abrechnung/:id/abrechnung-einzelgruppen/anzahl-teilnehmer",
      component: _34a2e369,
      name: "gesuche-gruppe-abrechnung-id-abrechnung-einzelgruppen-anzahl-teilnehmer___de"
    }, {
      path: "/gesuche/gruppe-abrechnung/:id/abrechnung-einzelgruppen/ausgaben",
      component: _7f9a426a,
      name: "gesuche-gruppe-abrechnung-id-abrechnung-einzelgruppen-ausgaben___de"
    }, {
      path: "/gesuche/gruppe-abrechnung/:id/abrechnung-einzelgruppen/belege-hochladen",
      component: _01d04f52,
      name: "gesuche-gruppe-abrechnung-id-abrechnung-einzelgruppen-belege-hochladen___de"
    }, {
      path: "/gesuche/gruppe-abrechnung/:id/abrechnung-einzelgruppen/defizit",
      component: _742cf310,
      name: "gesuche-gruppe-abrechnung-id-abrechnung-einzelgruppen-defizit___de"
    }, {
      path: "/gesuche/gruppe-abrechnung/:id/abrechnung-einzelgruppen/einnahmen",
      component: _73ee6382,
      name: "gesuche-gruppe-abrechnung-id-abrechnung-einzelgruppen-einnahmen___de"
    }, {
      path: "/gesuche/gruppe-abrechnung/:id/abrechnung-einzelgruppen/teilnehmerliste",
      component: _01771e01,
      name: "gesuche-gruppe-abrechnung-id-abrechnung-einzelgruppen-teilnehmerliste___de"
    }, {
      path: "/gesuche/gruppe-abrechnung/:id/abrechnung-sammelgesuch/abrechnung",
      component: _2dd6fab8,
      name: "gesuche-gruppe-abrechnung-id-abrechnung-sammelgesuch-abrechnung___de"
    }, {
      path: "/gesuche/gruppe-abrechnung/:id/abrechnung-sammelgesuch/defizit-schlussabrechnung",
      component: _3be336df,
      name: "gesuche-gruppe-abrechnung-id-abrechnung-sammelgesuch-defizit-schlussabrechnung___de"
    }, {
      path: "/gesuche/gruppe-abrechnung/:id/abrechnung-sammelgesuch/defizit-teilabrechnung",
      component: _d2286390,
      name: "gesuche-gruppe-abrechnung-id-abrechnung-sammelgesuch-defizit-teilabrechnung___de"
    }, {
      path: "/gesuche/gruppe-abrechnung/:id/abrechnung-sammelgesuch/schlussabrechnung",
      component: _1f73f5ea,
      name: "gesuche-gruppe-abrechnung-id-abrechnung-sammelgesuch-schlussabrechnung___de"
    }, {
      path: "/gesuche/gruppe-abrechnung/:id/abrechnung-sammelgesuch/teilabrechnung",
      component: _720b8f8c,
      name: "gesuche-gruppe-abrechnung-id-abrechnung-sammelgesuch-teilabrechnung___de"
    }, {
      path: "/gesuche/gruppe-abrechnung/:id/abrechnung-sammelgesuch/teilabrechnung-beenden",
      component: _d0fd75c0,
      name: "gesuche-gruppe-abrechnung-id-abrechnung-sammelgesuch-teilabrechnung-beenden___de"
    }, {
      path: "/gesuche/gruppe-abrechnung/:id/abrechnung-sammelgesuch/teilabrechnung-upload",
      component: _5340a6dc,
      name: "gesuche-gruppe-abrechnung-id-abrechnung-sammelgesuch-teilabrechnung-upload___de"
    }, {
      path: "/gesuche/gruppe-abrechnung/:id/abschluss/verifikation",
      component: _5288c1c0,
      name: "gesuche-gruppe-abrechnung-id-abschluss-verifikation___de"
    }, {
      path: "/gesuche/gruppe-abrechnung/:id/abschluss/vielen-dank",
      component: _e2351672,
      name: "gesuche-gruppe-abrechnung-id-abschluss-vielen-dank___de"
    }, {
      path: "/gesuche/gruppe-abrechnung/:id/bestaetigung-realisierung/bestaetigung-realisierung",
      component: _44922356,
      name: "gesuche-gruppe-abrechnung-id-bestaetigung-realisierung-bestaetigung-realisierung___de"
    }, {
      path: "/gesuche/gruppe-abrechnung/:id/bestaetigung-realisierung/gesuch-absagen",
      component: _3408d81d,
      name: "gesuche-gruppe-abrechnung-id-bestaetigung-realisierung-gesuch-absagen___de"
    }, {
      path: "/gesuche/gruppe-abrechnung/:id/bestaetigung-realisierung/unterlagen-abrechnung",
      component: _66d1079f,
      name: "gesuche-gruppe-abrechnung-id-bestaetigung-realisierung-unterlagen-abrechnung___de"
    }, {
      path: "/gesuche/gruppe-abrechnung/:id/bestaetigung-realisierung/zielgruppe",
      component: _d31f8c56,
      name: "gesuche-gruppe-abrechnung-id-bestaetigung-realisierung-zielgruppe___de"
    }, {
      path: "/gesuche/gruppe-abrechnung/:id/dam-bedingungen/bild-hochladen",
      component: _bc8a271c,
      name: "gesuche-gruppe-abrechnung-id-dam-bedingungen-bild-hochladen___de"
    }, {
      path: "/gesuche/gruppe-abrechnung/:id/dam-bedingungen/bilder-hochladen",
      component: _5d4b0b9f,
      name: "gesuche-gruppe-abrechnung-id-dam-bedingungen-bilder-hochladen___de"
    }, {
      path: "/gesuche/gruppe-abrechnung/:id/dam-bedingungen/bildfreigabe",
      component: _714b6f00,
      name: "gesuche-gruppe-abrechnung-id-dam-bedingungen-bildfreigabe___de"
    }, {
      path: "/gesuche/gruppe-abrechnung/:id/dam-bedingungen/inklusionsnachweis",
      component: _7472d493,
      name: "gesuche-gruppe-abrechnung-id-dam-bedingungen-inklusionsnachweis___de"
    }, {
      path: "/gesuche/gruppe-abrechnung/:id/dam-bedingungen/inklusuionskriterien",
      component: _f1ce2080,
      name: "gesuche-gruppe-abrechnung-id-dam-bedingungen-inklusionskriterien___de"
    }, {
      path: "/gesuche/gruppe-abrechnung/:id/dam-bedingungen/logo-antragsteller",
      component: _eeefb79c,
      name: "gesuche-gruppe-abrechnung-id-dam-bedingungen-logo-antragsteller___de"
    }, {
      path: "/gesuche/gruppe-abrechnung/:id/dam-bedingungen/nachweis-unterstuetzung",
      component: _3a269788,
      name: "gesuche-gruppe-abrechnung-id-dam-bedingungen-nachweis-unterstuetzung___de"
    }, {
      path: "/gesuche/gruppe-abrechnung/:id/dam-bedingungen/wirkung",
      component: _e894b382,
      name: "gesuche-gruppe-abrechnung-id-dam-bedingungen-wirkung___de"
    }, {
      path: "/gesuche/gruppe-abrechnung/:id/kontaktdaten/kontaktdaten-kontoinhaber",
      component: _68efb808,
      name: "gesuche-gruppe-abrechnung-id-kontaktdaten-kontaktdaten-kontoinhaber___de"
    }, {
      path: "/gesuche/gruppe-abrechnung/:id/kontaktdaten/kontodaten",
      component: _41f8e33c,
      name: "gesuche-gruppe-abrechnung-id-kontaktdaten-kontodaten___de"
    }]
  }, {
    path: "/gesuche/projekte-abrechnung/:id",
    component: _f62c03f0,
    name: "gesuche-projekte-abrechnung-id___de",
    children: [{
      path: "/gesuche/projekte-abrechnung/:id/abrechnung-projekte/effektiver-aufwand",
      component: _1c94ce06,
      name: "gesuche-projekte-abrechnung-id-abrechnung-projekte-effektiver-aufwand___de"
    }, {
      path: "/gesuche/projekte-abrechnung/:id/abrechnung-projekte/effektiver-ertrag",
      component: _172bc9a6,
      name: "gesuche-projekte-abrechnung-id-abrechnung-projekte-effektiver-ertrag___de"
    }, {
      path: "/gesuche/projekte-abrechnung/:id/abrechnung-projekte/effektives-defizit",
      component: _0c992614,
      name: "gesuche-projekte-abrechnung-id-abrechnung-projekte-effektives-defizit___de"
    }, {
      path: "/gesuche/projekte-abrechnung/:id/abrechnung-projekte/institutionelle-spenden",
      component: _04928e44,
      name: "gesuche-projekte-abrechnung-id-abrechnung-projekte-institutionelle-spenden___de"
    }, {
      path: "/gesuche/projekte-abrechnung/:id/abrechnung-projekte/kein-defizit",
      component: _66723c51,
      name: "gesuche-projekte-abrechnung-id-abrechnung-projekte-kein-defizit___de"
    }, {
      path: "/gesuche/projekte-abrechnung/:id/abrechnung-projekte/reichweite",
      component: _d32fc122,
      name: "gesuche-projekte-abrechnung-id-abrechnung-projekte-reichweite___de"
    }, {
      path: "/gesuche/projekte-abrechnung/:id/abschluss/absage",
      component: _4bbb38da,
      name: "gesuche-projekte-abrechnung-id-abschluss-absage___de"
    }, {
      path: "/gesuche/projekte-abrechnung/:id/abschluss/verifikation",
      component: _498e7dd9,
      name: "gesuche-projekte-abrechnung-id-abschluss-verifikation___de"
    }, {
      path: "/gesuche/projekte-abrechnung/:id/abschluss/vielen-dank",
      component: _694f5aee,
      name: "gesuche-projekte-abrechnung-id-abschluss-vielen-dank___de"
    }, {
      path: "/gesuche/projekte-abrechnung/:id/bestaetigung-realisierung/gesuch-absagen",
      component: _917e4ad4,
      name: "gesuche-projekte-abrechnung-id-bestaetigung-realisierung-gesuch-absagen___de"
    }, {
      path: "/gesuche/projekte-abrechnung/:id/bestaetigung-realisierung/projektabschluss",
      component: _af9fa268,
      name: "gesuche-projekte-abrechnung-id-bestaetigung-realisierung-projektabschluss___de"
    }, {
      path: "/gesuche/projekte-abrechnung/:id/bestaetigung-realisierung/unterlagen-abrechnung",
      component: _3060fb06,
      name: "gesuche-projekte-abrechnung-id-bestaetigung-realisierung-unterlagen-abrechnung___de"
    }, {
      path: "/gesuche/projekte-abrechnung/:id/dam-bedingungen/abschlussbericht",
      component: _28902cbc,
      name: "gesuche-projekte-abrechnung-id-dam-bedingungen-abschlussbericht___de"
    }, {
      path: "/gesuche/projekte-abrechnung/:id/dam-bedingungen/bilder-hochladen",
      component: _4434d1d0,
      name: "gesuche-projekte-abrechnung-id-dam-bedingungen-bilder-hochladen___de"
    }, {
      path: "/gesuche/projekte-abrechnung/:id/dam-bedingungen/bildfreigabe",
      component: _366f7879,
      name: "gesuche-projekte-abrechnung-id-dam-bedingungen-bildfreigabe___de"
    }, {
      path: "/gesuche/projekte-abrechnung/:id/dam-bedingungen/inklusionsnachweis",
      component: _389865cc,
      name: "gesuche-projekte-abrechnung-id-dam-bedingungen-inklusionsnachweis___de"
    }, {
      path: "/gesuche/projekte-abrechnung/:id/dam-bedingungen/logo-antragsteller",
      component: _4cadb56b,
      name: "gesuche-projekte-abrechnung-id-dam-bedingungen-logo-antragsteller___de"
    }, {
      path: "/gesuche/projekte-abrechnung/:id/dam-bedingungen/nachweis-der-unterstuetzung",
      component: _180e15aa,
      name: "gesuche-projekte-abrechnung-id-dam-bedingungen-nachweis-der-unterstuetzung___de"
    }, {
      path: "/gesuche/projekte-abrechnung/:id/kontaktdaten/bemerkungen",
      component: _cbf5ceaa,
      name: "gesuche-projekte-abrechnung-id-kontaktdaten-bemerkungen___de"
    }, {
      path: "/gesuche/projekte-abrechnung/:id/kontaktdaten/kontodaten",
      component: _2981dba3,
      name: "gesuche-projekte-abrechnung-id-kontaktdaten-kontodaten___de"
    }, {
      path: "/gesuche/projekte-abrechnung/:id/kontaktdaten/kontoinhaber",
      component: _70a940d4,
      name: "gesuche-projekte-abrechnung-id-kontaktdaten-kontoinhaber___de"
    }]
  }, {
    path: "/it/login/:code",
    component: _5b014400,
    name: "login-code___it"
  }, {
    path: "/login/:code",
    component: _5b014400,
    name: "login-code___de"
  }, {
    path: "/it/*",
    component: _5022c88e,
    name: "all___it"
  }, {
    path: "/fr/*",
    component: _5022c88e,
    name: "all___fr"
  }, {
    path: "/*",
    component: _5022c88e,
    name: "all___de"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
