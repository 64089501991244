import { render, staticRenderFns } from "./LogoButton.vue?vue&type=template&id=7be74123&scoped=true&"
import script from "./LogoButton.vue?vue&type=script&lang=js&"
export * from "./LogoButton.vue?vue&type=script&lang=js&"
import style0 from "./LogoButton.vue?vue&type=style&index=0&id=7be74123&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7be74123",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Logo: require('/vercel/path0/components/ui/Logo.vue').default})
