import jwtDecode from 'jwt-decode'

export const state = () => ({
  token: null,
  user: null,
})

export const mutations = {
  setToken(state, token) {
    state.token = token
  },

  setUser(state, user) {
    state.user = user
  },
}

export const actions = {
  async setToken({ commit, dispatch }, { accessToken }) {
    commit('setToken', accessToken)

    await dispatch('fetchUser')
  },

  async fetchUser({ commit }) {
    try {
      const { data: user } = await this.$axios.$get('/applicants/me')

      commit('setUser', user)
    } catch (error) {
      if (error.response?.status === 401) {
        commit('setToken', null)
        commit('setUser', null)
      }
    }
  },

  async logout({ dispatch }) {
    await dispatch('setToken', { accessToken: null })
  },

  async stopImpersonation({ dispatch }) {
    const { token } = await this.$axios.$post('/admin/stop-impersonation')

    await dispatch('setToken', token)
  },
}

export const getters = {
  isImpersonating(state) {
    try {
      return !!jwtDecode(state.token)?.impersonator
    } catch (error) {
      return false
    }
  },
}
