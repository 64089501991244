import { configure } from 'vee-validate'

export default function ({ $axios, app, error }) {
  configure({
    defaultMessage: (field, values) => {
      values._field_ = app.i18n.t(`${field}`)
      return app.i18n.t(`validation.${values._rule_}`, values)
    },
  })

  $axios.onRequest((config) => {
    // const localeRegex = new RegExp(
    //   `^/(${app.i18n.locales.map((l) => l.code).join('|')})(?:/|$)`
    // )

    // if (localeRegex.test(config.url)) {
    //   config.url = config.url.substring(3)
    // }

    if (app.i18n) {
      config.headers['accept-language'] = app.i18n.locale
    }
  })
}
