export function trackReads(object) {
  return new Proxy(object, {
    get(target, prop, receiver) {
      if (!(target._reads instanceof Map)) {
        target._reads = new Map()
      }

      if (prop === '_reads') {
        return Array.from(target._reads.keys())
      }

      target._reads.set(prop, Date.now())

      return Reflect.get(...arguments)
    },
  })
}

export function objectFullyPresentIn(object, wrappingObject) {
  if (!object || typeof object !== 'object') {
    return false
  }

  return Object.entries(object).every(
    ([key, value]) => value === wrappingObject[key]
  )
}

export class FilterBase {
  // Determines if a given question should be shown by calling the corresponding show function
  shouldShowQuestion(question, data, state, context) {
    // If the given question has a show function which returns false, it should not be shown
    return this[`show${question.id}`]?.(data, state, context) ?? true
  }

  // Finds out what questions a given question depends on
  questionDependsOn(question, data, state, context) {
    const trackingData = trackReads(JSON.parse(JSON.stringify(data)))

    this.shouldShowQuestion(question, trackingData, state, context)

    return trackingData._reads
  }
}

export class GatewayBase {
  out(questionId, data, context) {
    return this[`out${questionId}`]?.(data, context) ?? data
  }

  in(questionId, data, context) {
    return this[`in${questionId}`]?.(data, context) ?? data
  }
}

export class StatusBase {
  questionStatus(question, data, state) {
    const questionSpecificStatus = this[question.id]?.(data, state) ?? {}
    const status = {
      isCompleted:
        questionSpecificStatus.isCompleted ??
        (!data ||
          Object.entries(data).every(([key, value]) =>
            Array.isArray(value)
              ? value.length
              : !(value === undefined || value === null)
          )),
      isEmpty:
        questionSpecificStatus.isEmpty ??
        (!data || Object.keys(data).length === 0),
      isLocked: questionSpecificStatus.isLocked ?? false,
    }

    return { ...status, isTouched: status.isCompleted && !status.isEmpty }
  }
}
