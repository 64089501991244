import { StoreBase } from '~/utils/applicationForms/base'

class Store extends StoreBase {
  stateGenerator() {
    return {
      ...super.stateGenerator(),

      steps: [
        {
          titleKey: 'group.kurzcheck.finances-adults',
          questions: [
            {
              id: 'CG100',
              titleKey: 'group.CG100.title',
              component: 'GruppeKurzcheckFinanzenErwachsene',
            },
          ],
        },
        {
          titleKey: 'group.kurzcheck.finances-children',
          questions: [
            {
              id: 'CG110',
              titleKey: 'group.CG110.title',
              component: 'GruppeKurzcheckFinanzenKinder',
            },
          ],
        },
        {
          titleKey: 'group.kurzcheck.reason-for-request',
          questions: [
            {
              id: 'CG120',
              titleKey: 'group.CG120.title',
              component: 'GruppeKurzcheckGesuchsgrund',
            },
          ],
        },
        {
          titleKey: 'group.kurzcheck.entry-deadline',
          questions: [
            {
              id: 'CG130',
              titleKey: 'group.CG130.title',
              component: 'GruppeKurzcheckEingabefrist',
            },
          ],
        },
      ],

      data: {
        CG100: { value: null },
        CG110: { value: null },
        CG120: { value: null },
        CG130: { value: null },
      },

      popup: {
        show: false,
        success: false,
        text: '',
      },
    }
  }

  get mutations() {
    return {
      ...super.mutations,

      resetError(state) {
        state.popup.show = false
      },

      setFinalPopupIfCheckFailed(state) {
        if (
          (state.data.CG100.value === 1 || state.data.CG110.value === 1) &&
          (state.data.CG120.value === 1 || state.data.CG120.value === 2) &&
          state.data.CG130.value === 1
        ) {
          state.popup.show = true
          state.popup.success = true
          state.popup.text = this.$i18n.t('group.kurzcheck.popup1.text')
        } else if (
          state.data.CG100.value === 2 &&
          state.data.CG110.value === 2
        ) {
          state.popup.show = true
          state.popup.success = false
          state.popup.text = this.$i18n.t('group.kurzcheck.popup2.text')
        } else if (state.data.CG120.value === 99) {
          state.popup.show = true
          state.popup.success = false
          state.popup.text = this.$i18n.t('group.kurzcheck.popup3.text')
        } else if (state.data.CG130.value >= 97) {
          state.popup.show = true
          state.popup.success = false
          state.popup.text = this.$i18n.t('group.kurzcheck.popup4.text')
        }
      },
    }
  }

  get actions() {
    return {
      ...super.actions,

      setQuestionData({ commit, dispatch, state }, payload) {
        commit('setQuestionData', payload)
        dispatch('resetDependingQuestions', payload)

        commit('setFinalPopupIfCheckFailed')

        // Return false (stop wizard) if the popup is shown
        return !state.popup.show
      },
    }
  }
}

export default new Store()
