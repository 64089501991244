import Vue from 'vue'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {
  faCaretDown,
  faHeart as faSolidHeart,
} from '@fortawesome/free-solid-svg-icons'
import {
  faCheck,
  faInfoCircle,
  faHeart,
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/pro-light-svg-icons'
import { faTimes } from '@fortawesome/pro-solid-svg-icons'

config.autoAddCss = false

library.add(
  faCheck,
  faCaretDown,
  faTimes,
  faInfoCircle,
  faHeart,
  faSolidHeart,
  faChevronLeft,
  faChevronRight
)

Vue.component('FontAwesomeIcon', FontAwesomeIcon)
