
import { mapState, mapMutations } from 'vuex'
export default {
  components: {},

  props: {},

  data() {
    return {}
  },

  computed: {
    ...mapState('navigation', ['isOpen']),
  },

  methods: {
    ...mapMutations('navigation', ['toggleNav', 'closeNav']),
  },
}
