import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'
import GmapCluster from 'vue2-google-maps/dist/components/cluster'

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.NUXT_ENV_GMAPS_KEY,
    region: 'CH',
    language: 'de',
    // libraries: 'cluster',
  },
})

Vue.component('GmapCluster', GmapCluster)
