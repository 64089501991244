
import throttle from 'lodash/throttle'
import { mapState } from 'vuex'

const NEWSLETTER_POPUP_DELAY = 15000

export default {
  components: {},

  props: {},

  data() {
    return {
      scrolled: false,
      analyticsAccepted: true,
      showNewsletterPopup: false,
      forceWhiteBackground: false,
    }
  },

  computed: {
    ...mapState('navigation', ['isOpen']),
    ...mapState('content', ['globalLinks']),
    ...mapState('page', ['page']),
    isHome() {
      return this.$route.path === '/'
    },
    isChristmasCampagnePage() {
      return this.$route.path.includes('weihnachtsaktion-2023')
    },
    showDonationBar() {
      return this.scrolled && this.page && this.page.show_donation_bar
    },
    hasWhiteBackground() {
      return (
        ((this.page && this.page.image) ||
          this.isHome ||
          this.forceWhiteBackground) &&
        !(this.page && this.page.items && this.page.items.length === 0)
      )
    },

    slotData() {
      return {
        isHome: this.isHome,
        scrolled: this.scrolled,
        showDonationBar: this.showDonationBar,
        hasWhiteBackground: this.hasWhiteBackground,
      }
    },
  },

  beforeMount() {
    this.scrollHandler()
    window.addEventListener('scroll', this.scrollHandler)
    setTimeout(() => {
      this.displayNewsletterPopup()
    }, NEWSLETTER_POPUP_DELAY)

    this.$nuxt.$on('set-white-background', (isWhite) => {
      this.forceWhiteBackground = isWhite
    })
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollHandler)
  },

  mounted() {
    this.analyticsAccepted = JSON.parse(
      localStorage.getItem('analyticsAccepted')
    )
  },

  methods: {
    scrollHandler: throttle(function () {
      requestAnimationFrame(() => {
        this.scrolled = window.scrollY > 100
      })

      if (window.scrollY > 1500) {
        this.displayNewsletterPopup()
      }
    }, 100),

    displayNewsletterPopup() {
      if (
        !localStorage.getItem('preventNewsletterPopup') &&
        this.page.show_newsletter_popup &&
        !this.page.isPreview
      ) {
        this.showNewsletterPopup = true
        localStorage.setItem('preventNewsletterPopup', true)
      }
    },
  },
}
