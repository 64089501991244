
export default {
  components: {},

  props: {
    isMenu: {
      type: Boolean,
      default: false,
    },

    fullWidth: {
      type: Boolean,
      default: false,
    },

    smallWidth: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {}
  },

  computed: {},

  methods: {},
}
