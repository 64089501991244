import {
  FilterBase,
  GatewayBase,
  StatusBase,
} from '~/utils/applicationForms/utils'
import { StatementStoreBase } from '~/utils/applicationForms/base'
import { formatIban } from '~/utils'

class Filter extends FilterBase {
  showBE210(data) {
    return data.BE200.realizationStatus === 'cancelled'
  }

  showBE220(data) {
    return !data.BE210.confirmCancellation
  }

  showBE230(data, state) {
    return (
      state.application.reasonForRequest === 'travel' &&
      !data.BE210.confirmCancellation
    )
  }

  showBE240(data, state) {
    return (
      state.application.reasonForRequest === 'leisure' &&
      !data.BE210.confirmCancellation
    )
  }

  showBE250(data, state) {
    return (
      state.application.reasonForRequest === 'procurement' &&
      !data.BE210.confirmCancellation
    )
  }

  showBE260(data, state) {
    return !data.BE210.confirmCancellation
  }

  showBE270(data, state) {
    return !data.BE210.confirmCancellation
  }

  showBE280(data, state) {
    return !data.BE210.confirmCancellation
  }

  showBE290(data, state) {
    return (
      ['travel', 'leisure'].includes(state.application.reasonForRequest) &&
      !data.BE210.confirmCancellation
    )
  }

  showBE300(data, state) {
    return (
      state.application.reasonForRequest === 'procurement' &&
      !data.BE210.confirmCancellation
    )
  }

  showBE310(data, state) {
    return !data.BE210.confirmCancellation
  }

  showBE320(data) {
    return (
      Array.isArray(data.BE310.images) &&
      data.BE310.images.length > 0 &&
      !data.BE210.confirmCancellation
    )
  }

  showBE400(data, state) {
    return !data.BE210.confirmCancellation
  }

  showBE410(data) {
    return (
      data.BE400.paymentRecipientType === 'contact' &&
      !data.BE210.confirmCancellation
    )
  }

  showBE420(data) {
    return (
      data.BE400.paymentRecipientType === 'stand-in' &&
      !data.BE210.confirmCancellation
    )
  }

  showBE430(data) {
    return (
      data.BE400.paymentRecipientType === 'other' &&
      !data.BE210.confirmCancellation
    )
  }

  showBE440(data, state) {
    return !!state.statement.paymentRecipient && !data.BE210.confirmCancellation
  }

  showBE450(data, state) {
    return !data.BE210.confirmCancellation
  }

  showBE500(data, state) {
    return !!data.BE210.confirmCancellation
  }
}

class Gateway extends GatewayBase {
  inBE220(data) {
    return { read: Array.isArray(data.expenses) }
  }

  outBE260(data) {
    return {
      documentIds: (data.receipts ?? [])
        .filter((receipt) => receipt?.id)
        .map(({ id }) => id),
    }
  }

  inBE280(data) {
    return {
      correct: data.review ? true : null,
    }
  }

  outBE310(data) {
    return {
      imageIds:
        data.images?.filter((image) => image?.id).map(({ id }) => id) ?? [],
    }
  }

  inBE400(data, context) {
    const application =
      context.state.applicationForms.einzelperson.abrechnung.application
    if (!data.paymentRecipient) {
      return { paymentRecipientType: null }
    }

    if (data.paymentRecipient.id === application.contact.id) {
      return { paymentRecipientType: 'contact' }
    }

    if (data.paymentRecipient.id === application.representation?.id) {
      return { paymentRecipientType: 'stand-in' }
    }

    return {
      paymentRecipientType: 'other',
    }
  }

  inBE410(data, context) {
    return {
      contact:
        context.state.applicationForms.einzelperson.abrechnung.application
          .contact,
    }
  }

  outBE410(data) {
    if (data.contact?.id) {
      return {
        id: data.contact.id,
        type: 'paymentRecipient',
      }
    }
    return { type: 'paymentRecipient' }
  }

  inBE420(data, context) {
    return {
      contact:
        context.state.applicationForms.einzelperson.abrechnung.application
          .representation,
    }
  }

  outBE420(data) {
    if (Object.keys(data).length <= 0) {
      return {
        type: 'paymentRecipient',
      }
    }

    return {
      id: data.contact.id,
      type: 'paymentRecipient',
    }
  }

  inBE430(data) {
    return data.paymentRecipient
  }

  inBE440(data, { $axios }) {
    const iban = data?.paymentRecipient?.bankDetails?.iban
    return { iban: iban ? formatIban(iban) : '' }
  }

  outBE440(data) {
    return {
      iban: data.iban?.replaceAll(' ', ''),
    }
  }

  async outBE430(data, { $axios }) {
    if (Object.keys(data).length <= 0) {
      return {
        type: 'paymentRecipient',
      }
    }

    if (!data.id) {
      data = (await $axios.$post('/contacts', data)).data
    }

    return {
      id: data.id,
      type: 'paymentRecipient',
    }
  }
}

class Status extends StatusBase {
  BE210(data, state) {
    return { isCompleted: !!state.statement.expenses }
  }

  BE430(data, state) {
    return { isCompleted: !!state.statement.paymentRecipient }
  }

  BE310(data, state) {
    return { isCompleted: !!state.statement.images }
  }
}

class Store extends StatementStoreBase {
  filter = new Filter()
  gateway = new Gateway()
  status = new Status()

  buildUrl(id, path = '') {
    if (path === '/contacts' || path === '/cancel') {
      return `applications/${id}${path}`
    }
    return `/applications/${id}/statement${path}`
  }

  stateGenerator() {
    return {
      ...super.stateGenerator(),

      steps: [
        {
          titleKey: 'single-person.costs',
          questions: [
            {
              id: 'BE200',
              titleKey: 'single-person.BE200.title',
              route: {
                name: 'gesuche-einzelperson-abrechnung-id-kosten-einnahmen-bestaetigung-realisierung',
              },
              setQuestionDataEndpoint: '/realization-status',
            },
            {
              id: 'BE210',
              titleKey: 'single-person.BE210.title',
              route: {
                name: 'gesuche-einzelperson-abrechnung-id-kosten-einnahmen-verifikation-absage',
              },
            },
            {
              id: 'BE220',
              titleKey: 'single-person.BE220.title',
              route: {
                name: 'gesuche-einzelperson-abrechnung-id-kosten-einnahmen-abrechnungsbereich',
              },
            },
            {
              id: 'BE230',
              titleKey: 'single-person.BE230.title',
              route: {
                name: 'gesuche-einzelperson-abrechnung-id-kosten-einnahmen-kosten-ferienreise',
              },
              setQuestionDataEndpoint: '/expenses',
            },
            {
              id: 'BE240',
              titleKey: 'single-person.BE240.title',
              route: {
                name: 'gesuche-einzelperson-abrechnung-id-kosten-einnahmen-kosten-freizeitaktivitaet',
              },
              setQuestionDataEndpoint: '/expenses',
            },
            {
              id: 'BE250',
              titleKey: 'single-person.BE250.title',
              route: {
                name: 'gesuche-einzelperson-abrechnung-id-kosten-einnahmen-kosten-anschaffung',
              },
              setQuestionDataEndpoint: '/expenses',
            },
            {
              id: 'BE260',
              titleKey: 'single-person.BE260.title',
              route: {
                name: 'gesuche-einzelperson-abrechnung-id-kosten-einnahmen-belege-hochladen',
              },
              setQuestionDataEndpoint: '/receipts',
            },
            {
              id: 'BE270',
              titleKey: 'single-person.BE270.title',
              route: {
                name: 'gesuche-einzelperson-abrechnung-id-kosten-einnahmen-einnahmen',
              },
              setQuestionDataEndpoint: '/budget',
            },
            {
              id: 'BE280',
              titleKey: 'single-person.BE280.title',
              route: {
                name: 'gesuche-einzelperson-abrechnung-id-kosten-einnahmen-defizit',
              },
            },
            {
              id: 'BE290',
              titleKey: 'single-person.BE290.title',
              route: {
                name: 'gesuche-einzelperson-abrechnung-id-kosten-einnahmen-wirkung-ferien-freizeit',
              },
              setQuestionDataEndpoint: '/review',
            },
            {
              id: 'BE300',
              titleKey: 'single-person.BE300.title',
              route: {
                name: 'gesuche-einzelperson-abrechnung-id-kosten-einnahmen-wirkung-anschaffung',
              },
              setQuestionDataEndpoint: '/review',
            },
            {
              id: 'BE310',
              titleKey: 'single-person.BE310.title',
              route: {
                name: 'gesuche-einzelperson-abrechnung-id-kosten-einnahmen-bild-hochladen',
              },
              setQuestionDataEndpoint: '/images',
            },
            {
              id: 'BE320',
              titleKey: 'single-person.BE320.title',
              route: {
                name: 'gesuche-einzelperson-abrechnung-id-kosten-einnahmen-bildfreigabe',
              },
              setQuestionDataEndpoint: '/allow-image-use',
            },
          ],
        },
        {
          titleKey: 'single-person.contact',
          questions: [
            {
              id: 'BE400',
              titleKey: 'single-person.BE400.title',
              route: {
                name: 'gesuche-einzelperson-abrechnung-id-kontaktdaten-adressat-auszahlung',
              },
            },
            {
              id: 'BE410',
              titleKey: 'single-person.BE410.title',
              route: {
                name: 'gesuche-einzelperson-abrechnung-id-kontaktdaten-selbsteingabe',
              },
              setQuestionDataEndpoint: '/contacts',
            },
            {
              id: 'BE420',
              titleKey: 'single-person.BE420.title',
              route: {
                name: 'gesuche-einzelperson-abrechnung-id-kontaktdaten-vertretung',
              },
              setQuestionDataEndpoint: '/contacts',
            },
            {
              id: 'BE430',
              titleKey: 'single-person.BE430.title',
              route: {
                name: 'gesuche-einzelperson-abrechnung-id-kontaktdaten-drittperson',
              },
              setQuestionDataEndpoint: '/contacts',
            },
            {
              id: 'BE440',
              titleKey: 'single-person.BE440.title',
              route: {
                name: 'gesuche-einzelperson-abrechnung-id-kontaktdaten-kontodaten',
              },
              setQuestionDataEndpoint: '/bank-details',
            },
            {
              id: 'BE450',
              titleKey: 'single-person.BE450.title',
              route: {
                name: 'gesuche-einzelperson-abrechnung-id-kontaktdaten-datenpruefung',
              },
            },
          ],
        },
        {
          titleKey: 'single-person.cancelation',
          questions: [
            {
              id: 'BE500',
              titleKey: 'single-person.BE500.title',
              route: {
                name: 'gesuche-einzelperson-abrechnung-id-absage',
              },
            },
          ],
        },
      ],

      data: {
        BE200: { realizationStatus: null },
        BE210: { confirmCancellation: null },
        BE220: { read: null },
        BE230: { expenses: [] },
        BE240: { expenses: [] },
        BE250: { expenses: [] },
        BE260: { receipts: [] },
        BE270: { budget: [] },
        BE280: { correct: null },
        BE290: { review: null },
        BE300: { review: null },
        BE310: { images: [] },
        BE320: { allowImageUse: null },
        BE400: { paymentRecipientType: null },
        BE410: {
          contact: null,
        },
        BE420: {
          contact: null,
        },
        BE430: {
          id: null,
          organization: null,
          salutCode: null,
          firstname: null,
          lastname: null,
          street: null,
          houseNumber: null,
          zipCode: null,
          location: null,
          phone: null,
          email: null,
          bankDetails: {
            iban: null,
          },
        },
        BE440: {
          iban: null,
        },
      },
    }
  }
}

export default new Store()
