import { StoreBase } from '~/utils/applicationForms/base'

class Store extends StoreBase {
  stateGenerator() {
    return {
      ...super.stateGenerator(),

      steps: [
        {
          titleKey: 'project.kurzcheck.target-group',
          questions: [
            {
              id: 'CP100',
              titleKey: 'project.CP100.title',
              component: 'ProjekteKurzcheckZielgruppe',
            },
          ],
        },
        {
          titleKey: 'project.kurzcheck.project-lead',
          questions: [
            {
              id: 'CP110',
              titleKey: 'project.CP110.title',
              component: 'ProjekteKurzcheckProjektleitung',
            },
          ],
        },
        {
          titleKey: 'project.kurzcheck.project-area',
          questions: [
            {
              id: 'CP120',
              titleKey: 'project.CP120.title',
              component: 'ProjekteKurzcheckProjektbereich',
            },
          ],
        },
        {
          titleKey: 'project.kurzcheck.entry-deadline',
          questions: [
            {
              id: 'CP130',
              titleKey: 'project.CP130.title',
              component: 'ProjekteKurzcheckEingabefrist',
            },
          ],
        },
      ],

      data: {
        CP100: { value: null },
        CP110: { value: null },
        CP120: { value: null },
        CP130: { value: null },
      },

      popup: {
        show: false,
        success: false,
        text: '',
      },
    }
  }

  get mutations() {
    return {
      ...super.mutations,

      resetError(state) {
        state.popup.show = false
      },

      setFinalPopupIfCheckFailed(state) {
        if (
          (state.data.CP100.value === 1 || state.data.CP110.value === 1) &&
          state.data.CP120.value === 1 &&
          state.data.CP130.value === 1
        ) {
          state.popup.show = true
          state.popup.success = true
          state.popup.text = this.$i18n.t('project.kurzcheck.popup1.text')
        } else if (
          state.data.CP100.value === 2 &&
          state.data.CP110.value === 2
        ) {
          state.popup.show = true
          state.popup.success = false
          state.popup.text = this.$i18n.t('project.kurzcheck.popup2.text')
        } else if (state.data.CP120.value === 99) {
          state.popup.show = true
          state.popup.success = false
          state.popup.text = this.$i18n.t('project.kurzcheck.popup3.text')
        } else if (
          state.data.CP130.value === 2 ||
          state.data.CP130.value === 3
        ) {
          state.popup.show = true
          state.popup.success = false
          state.popup.text = this.$i18n.t('project.kurzcheck.popup4.text')
        }
      },
    }
  }

  get actions() {
    return {
      ...super.actions,

      setQuestionData({ commit, dispatch, state }, payload) {
        commit('setQuestionData', payload)
        dispatch('resetDependingQuestions', payload)

        commit('setFinalPopupIfCheckFailed')

        // Return false (stop wizard) if the popup is shown
        return !state.popup.show
      },
    }
  }
}

export default new Store()
