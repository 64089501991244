// import Vue from 'vue'
import {
  FilterBase,
  GatewayBase,
  StatusBase,
} from '~/utils/applicationForms/utils'
import { StoreBase } from '~/utils/applicationForms/base'

class Filter extends FilterBase {
  showFP230(data, state, globalState) {
    return !(data.FP220.targetGroup ?? []).includes('disabled')
  }

  showFP420(data) {
    const institutionalDonations =
      (data.FP410.budgetAvailable ?? []).find(
        (item) => item.type === 'institutionalDonations'
      )?.amount || 0
    return institutionalDonations > 0
  }
}

class Gateway extends GatewayBase {
  inFP200(data) {
    return { read: data.subtype ? true : null }
  }

  inFP310(data) {
    return {
      start: data.timePeriod?.start,
      end: data.timePeriod?.end,
    }
  }

  inFP360(data) {
    return {
      concept: data.projectConceptDocument,
    }
  }

  outFP360(data) {
    return {
      projectConceptDocumentId: data.concept?.id,
    }
  }

  inFP430(data) {
    return {
      correct:
        data.institutionsRequested && Array.isArray(data.institutionsRequested),
    }
  }

  inFP440(data) {
    return {
      institutionsRequested: data.institutionsRequested,
      noPendingRequests:
        data.institutionsRequested &&
        Array.isArray(data.institutionsRequested) &&
        data.institutionsRequested.length === 0,
    }
  }

  outFP440(data) {
    return {
      institutionsRequested: data.institutionsRequested,
    }
  }

  inFP450(data) {
    return {
      institutionsRejected: data.institutionsRejected,
      noPendingRequests:
        data.institutionsRejected &&
        Array.isArray(data.institutionsRejected) &&
        data.institutionsRejected.length === 0,
    }
  }

  outFP450(data) {
    return {
      institutionsRejected: data.institutionsRejected,
    }
  }

  inFP510(data) {
    return {
      accept: !!data.contact,
    }
  }

  inFP600(data) {
    return data.contact
  }

  async outFP600(data, { $axios, $router }) {
    if (Object.keys(data).length <= 0) {
      return {
        type: 'individual',
      }
    }

    if (!data.id) {
      data = (await $axios.$post('/contacts', data)).data
    }

    return {
      id: data.id,
      type: 'individual',
    }
  }
}

class Status extends StatusBase {
  FP440(data) {
    return {
      isCompleted: data.institutionsRequested !== null,
    }
  }

  FP450(data) {
    return {
      isCompleted: data.institutionsRejected !== null,
    }
  }
}

class Store extends StoreBase {
  filter = new Filter()
  gateway = new Gateway()
  status = new Status()

  stateGenerator() {
    return {
      ...super.stateGenerator(),

      steps: [
        {
          titleKey: 'project.input',
          questions: [
            {
              id: 'FP000',
              title: '',
              route: { name: 'gesuche-projekte' },
              ignoreInQuestionCount: true,
            },
            {
              id: 'FP100',
              titleKey: 'project.FP100.title',
              route: { name: 'gesuche-projekte-email' },
            },
            {
              id: 'FP110',
              titleKey: 'project.FP110.title',
              route: { name: 'gesuche-projekte-verifikation-email' },
            },
            {
              id: 'FP120',
              titleKey: 'project.FP120.title',
              route: { name: 'gesuche-projekte-id' },
            },
          ],
        },
        {
          titleKey: 'project.guidelines',
          questions: [
            {
              id: 'FP200',
              titleKey: 'project.FP200.title',
              route: {
                name: 'gesuche-projekte-id-richtlinien-und-zielgruppe-richtlinien',
              },
            },
            {
              id: 'FP210',
              titleKey: 'project.FP210.title',
              route: {
                name: 'gesuche-projekte-id-richtlinien-und-zielgruppe-gesuchsart',
              },
              setQuestionDataEndpoint: '/subtype',
            },
            {
              id: 'FP220',
              titleKey: 'project.FP220.title',
              route: {
                name: 'gesuche-projekte-id-richtlinien-und-zielgruppe-zielgruppe',
              },
              setQuestionDataEndpoint: '/target-group',
            },
            {
              id: 'FP230',
              titleKey: 'project.FP230.title',
              route: {
                name: 'gesuche-projekte-id-richtlinien-und-zielgruppe-projektleitung',
              },
              setQuestionDataEndpoint: '/project-lead-disability-status',
            },
            {
              id: 'FP240',
              titleKey: 'project.FP240.title',
              route: {
                name: 'gesuche-projekte-id-richtlinien-und-zielgruppe-kommerzieller-hintergrund',
              },
              setQuestionDataEndpoint: '/non-profit',
            },
          ],
        },
        {
          titleKey: 'project.project-dates',
          questions: [
            {
              id: 'FP300',
              titleKey: 'project.FP300.title',
              route: {
                name: 'gesuche-projekte-id-projektdaten-eingabefrist',
              },
              setQuestionDataEndpoint: '/project-status',
            },
            {
              id: 'FP310',
              titleKey: 'project.FP310.title',
              route: {
                name: 'gesuche-projekte-id-projektdaten-projektlaufzeit',
              },
              setQuestionDataEndpoint: '/time-period',
            },
            {
              id: 'FP320',
              titleKey: 'project.FP320.title',
              route: {
                name: 'gesuche-projekte-id-projektdaten-projektkategorie',
              },
              setQuestionDataEndpoint: '/project-category',
            },
            {
              id: 'FP330',
              titleKey: 'project.FP330.title',
              route: {
                name: 'gesuche-projekte-id-projektdaten-projekttitel',
              },
              setQuestionDataEndpoint: '/project-title',
            },
            {
              id: 'FP340',
              titleKey: 'project.FP340.title',
              route: {
                name: 'gesuche-projekte-id-projektdaten-projektbeschreibung',
              },
              setQuestionDataEndpoint: '/project-description',
            },
            {
              id: 'FP350',
              titleKey: 'project.FP350.title',
              route: {
                name: 'gesuche-projekte-id-projektdaten-anzahl-personen',
              },
              setQuestionDataEndpoint: '/number-of-disabled-persons',
            },
            {
              id: 'FP360',
              titleKey: 'project.FP360.title',
              route: {
                name: 'gesuche-projekte-id-projektdaten-konzept',
              },
              setQuestionDataEndpoint: '/project-concept-document',
            },
            {
              id: 'FP370',
              titleKey: 'project.FP370.title',
              route: {
                name: 'gesuche-projekte-id-projektdaten-wirkung',
              },
              setQuestionDataEndpoint: '/appeal',
            },
            {
              id: 'FP380',
              titleKey: 'project.FP380.title',
              route: {
                name: 'gesuche-projekte-id-projektdaten-inklusion',
              },
              setQuestionDataEndpoint: '/inclusion-criteria',
            },
          ],
        },
        {
          titleKey: 'project.budget',
          questions: [
            {
              id: 'FP400',
              titleKey: 'project.FP400.title',
              route: {
                name: 'gesuche-projekte-id-budget-aufwand',
              },
              setQuestionDataEndpoint: '/expenses',
            },
            {
              id: 'FP410',
              titleKey: 'project.FP410.title',
              route: {
                name: 'gesuche-projekte-id-budget-ertrag',
              },
              setQuestionDataEndpoint: '/budget-available',
            },
            {
              id: 'FP420',
              titleKey: 'project.FP420.title',
              route: {
                name: 'gesuche-projekte-id-budget-institutionelle-spenden',
              },
              setQuestionDataEndpoint: '/donations',
            },
            {
              id: 'FP430',
              titleKey: 'project.FP430.title',
              route: {
                name: 'gesuche-projekte-id-budget-defizit',
              },
            },
            {
              id: 'FP440',
              titleKey: 'project.FP440.title',
              route: {
                name: 'gesuche-projekte-id-budget-angefragte-institutionen',
              },
              setQuestionDataEndpoint: '/institutions-requested',
            },
            {
              id: 'FP450',
              titleKey: 'project.FP450.title',
              route: {
                name: 'gesuche-projekte-id-budget-absage-institutionen',
              },
              setQuestionDataEndpoint: '/institutions-rejected',
            },
            {
              id: 'FP460',
              titleKey: 'project.FP460.title',
              route: {
                name: 'gesuche-projekte-id-budget-beitrag-stiftung-denk-an-mich',
              },
              setQuestionDataEndpoint: '/requested-amount',
            },
          ],
        },
        {
          titleKey: 'project.dam-conditions',
          questions: [
            {
              id: 'FP500',
              titleKey: 'project.FP500.title',
              route: {
                name: 'gesuche-projekte-id-dam-bedingungen-hinweis-auf-unterstuetzung',
              },
              setQuestionDataEndpoint: '/visibility',
            },
            {
              id: 'FP510',
              titleKey: 'project.FP510.title',
              route: {
                name: 'gesuche-projekte-id-dam-bedingungen-abschlussbericht-und-bildnachweis',
              },
            },
          ],
        },
        {
          titleKey: 'project.contact',
          questions: [
            {
              id: 'FP600',
              titleKey: 'project.FP600.title',
              route: {
                name: 'gesuche-projekte-id-kontaktdaten-kontaktdaten',
              },
              setQuestionDataEndpoint: '/contacts',
            },
            {
              id: 'FP620',
              titleKey: 'project.FP620.title',
              route: {
                name: 'gesuche-projekte-id-kontaktdaten-bemerkungen',
              },
              setQuestionDataEndpoint: '/comments',
            },
            {
              id: 'FP610',
              titleKey: 'project.FP610.title',
              route: {
                name: 'gesuche-projekte-id-kontaktdaten-datenpruefung',
              },
            },
          ],
        },
        {
          titleKey: 'project.conclusion',
          questions: [
            {
              id: 'FP700',
              titleKey: 'project.FP700.title',
              route: {
                name: 'gesuche-projekte-id-abschluss',
              },
            },
          ],
        },
      ],

      data: {
        FP200: {
          read: null,
        },
        FP210: {
          subtype: null,
        },
        FP220: {
          targetGroup: [],
        },
        FP230: {
          projectLeadDisabilityStatus: null,
        },
        FP240: {
          nonProfit: null,
        },
        FP300: {
          projectStatus: null,
        },
        FP310: { start: null, end: null },
        FP320: { projectCategory: null },
        FP330: { projectTitle: null },
        FP340: { projectDescription: null },
        FP350: { numberOfDisabledPersons: null },
        FP360: { concept: null },
        FP370: { appeal: null },
        FP380: { inclusionCriteria: [] },
        FP400: { expenses: null },
        FP410: { budgetAvailable: null },
        FP420: {
          donations: [],
        },
        FP430: {
          correct: null,
        },
        FP440: {
          institutionsRequested: [],
          noPendingRequests: null,
        },
        FP450: {
          institutionsRejected: [],
          noPendingRequests: null,
        },
        FP460: {
          requestedAmount: null,
        },
        FP500: {
          visibility: [],
        },
        FP510: {
          accept: null,
        },
        FP600: {
          id: null,
          salutCode: null,
          firstname: null,
          lastname: null,
          street: null,
          houseNumber: null,
          zipCode: null,
          location: null,
          phone: null,
          email: null,
        },
        FP620: {
          comments: null,
        },
      },
    }
  }
}

export default new Store()
