import { render, staticRenderFns } from "./QuickLinks.vue?vue&type=template&id=4c1fe4c2&scoped=true&"
import script from "./QuickLinks.vue?vue&type=script&lang=js&"
export * from "./QuickLinks.vue?vue&type=script&lang=js&"
import style0 from "./QuickLinks.vue?vue&type=style&index=0&id=4c1fe4c2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c1fe4c2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppLink: require('/vercel/path0/components/ui/AppLink.vue').default,Like: require('/vercel/path0/components/icons/Like.vue').default})
