import { render, staticRenderFns } from "./AppLayoutBase.vue?vue&type=template&id=e9363db4&scoped=true&"
import script from "./AppLayoutBase.vue?vue&type=script&lang=js&"
export * from "./AppLayoutBase.vue?vue&type=script&lang=js&"
import style0 from "./AppLayoutBase.vue?vue&type=style&index=0&id=e9363db4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e9363db4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Like: require('/vercel/path0/components/icons/Like.vue').default,AppLink: require('/vercel/path0/components/ui/AppLink.vue').default,Twint: require('/vercel/path0/components/icons/Twint.vue').default,StickyBar: require('/vercel/path0/components/componentloader/StickyBar.vue').default,SimpleCookieBannerLoader: require('/vercel/path0/components/ui/cookie/SimpleCookieBannerLoader.vue').default,NewsletterPopup: require('/vercel/path0/components/ui/newsletter-popup/NewsletterPopup.vue').default})
