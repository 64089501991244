import Vue from 'vue'
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize,
  setInteractionMode,
} from 'vee-validate'
import de from 'vee-validate/dist/locale/de.json'
import {
  required,
  min,
  max,
  is,
  // eslint-disable-next-line camelcase
  min_value,
  // eslint-disable-next-line camelcase
  max_value,
  email,
  ext,
  confirmed,
  numeric,
  size,
} from 'vee-validate/dist/rules'
import { differenceInMilliseconds, format, parse } from 'date-fns'

// Install components globally
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

Vue.config.productionTip = false

extend('required', {
  ...required,
  message: '{_field_} ist ein Pflichtfeld.',
})
extend('min', {
  ...min,
  params: ['length'],
  message: '{_field_} muss min. {length} Zeichen lang sein.',
})
extend('max', {
  ...max,
  params: ['length'],
  message: '{_field_} kann max. {length} Zeichen lang sein.',
})
extend('min_value', {
  // eslint-disable-next-line camelcase
  ...min_value,
  params: ['min'],
  message: '{_field_} muss min. {min} sein.',
})
extend('max_value', {
  // eslint-disable-next-line camelcase
  ...max_value,
  params: ['max'],
  message: '{_field_} kann max. {max} sein.',
})
extend('email', {
  ...email,
  message: '{_field_} muss eine gültige E-Mail-Adresse beinhalten.',
})
extend('ext', {
  ...ext,
  message: '{_field_} muss eine gültige Datei sein.',
})
extend('confirmed', {
  ...confirmed,
})
extend('numeric', {
  ...numeric,
  message: '{_field_} muss eine Zahl sein.',
})
extend('size', {
  ...size,
  params: ['size'],
  message: (name, { size }) =>
    `${name} darf max. ${
      size < 1024 ? `${size} KB` : `${size / 1024} MB`
    } gross sein.`,
})
extend('is', {
  ...is,
  params: ['other'],
  message: '{_field_} muss {other} sein.',
})

function parseValidDate(input) {
  if (
    !/^([0-9]|[0-2][0-9]|3[0-1])\.([0-9]|0[0-9]|1[0-2])\.([0-2][0-9]{3})$/.test(
      input
    )
  ) {
    return false
  }

  const date = parse(input, 'd.MM.yyyy', new Date())
  if (!(date instanceof Date && !isNaN(date))) {
    return false
  }

  return date
}
extend('decimal', {
  validate: (value, { decimals = '*', separator = '.' } = {}) => {
    if (value === null || value === undefined || value === '') {
      return {
        valid: false,
      }
    }
    if (Number(decimals) === 0) {
      return {
        valid: /^-?\d*$/.test(value),
      }
    }
    const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`
    const regex = new RegExp(
      `^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`
    )

    return {
      valid: regex.test(value),
    }
  },
  message: '{_field_} muss eine ganze Zahl oder eine Dezimalzahl sein.',
})
extend('date', {
  validate: (value) => {
    return !!parseValidDate(value)
  },
  message:
    '{_field_} muss ein gültiges Datum sein (dd.mm.jjjj). Beispielsweise 15.06.2000.',
})
extend('before', {
  params: ['before'],
  validate: (value, { before }) => {
    if (typeof before === 'string') {
      before = parseValidDate(before)
    }

    if (!(before instanceof Date && !isNaN(before))) {
      throw new TypeError('Before must be a valid date')
    }

    const date = parseValidDate(value)

    return differenceInMilliseconds(before, date) > 0
  },
  message: (name, { before }) => {
    if (typeof before === 'string') {
      before = parseValidDate(before)
    }

    if (!(before instanceof Date && !isNaN(before))) {
      throw new TypeError('Before must be a valid date')
    }

    return `${name} muss vor ${format(before, 'd.MM.yyyy')} sein.`
  },
})
extend('after', {
  params: ['after'],
  validate: (value, { after }) => {
    if (typeof after === 'string') {
      after = parseValidDate(after)
    }

    if (!(after instanceof Date && !isNaN(after))) {
      throw new TypeError('After must be a valid date')
    }

    const date = parseValidDate(value)

    return differenceInMilliseconds(date, after) > 0
  },
  message: (name, { after }) => {
    if (typeof after === 'string') {
      after = parseValidDate(after)
    }

    if (!(after instanceof Date && !isNaN(after))) {
      throw new TypeError('After must be a valid date')
    }

    return `${name} muss nach ${format(after, 'd.MM.yyyy')} sein.`
  },
})
extend('sameOrAfter', {
  params: ['sameOrAfter'],
  validate: (value, { sameOrAfter }) => {
    if (typeof sameOrAfter === 'string') {
      sameOrAfter = parseValidDate(sameOrAfter)
    }

    if (!(sameOrAfter instanceof Date && !isNaN(sameOrAfter))) {
      throw new TypeError('Reference Date must be a valid date')
    }

    const date = parseValidDate(value)

    return differenceInMilliseconds(date, sameOrAfter) >= 0
  },
  message: (name, { after }) => {
    if (typeof after === 'string') {
      after = parseValidDate(after)
    }

    if (!(after instanceof Date && !isNaN(after))) {
      throw new TypeError('After must be a valid date')
    }

    return `${name} muss nach ${format(after, 'd.MM.yyyy')} sein.`
  },
})
extend('minDate', {
  params: ['minDate'],
  validate: (value, { minDate }) => {
    if (typeof minDate === 'string') {
      minDate = parseValidDate(minDate)
    }

    if (!(minDate instanceof Date && !isNaN(minDate))) {
      throw new TypeError('MinDate must be a valid date')
    }

    const date = parseValidDate(value)

    return differenceInMilliseconds(date, minDate) >= 0
  },
  message: (name, { minDate }) => {
    if (typeof minDate === 'string') {
      minDate = parseValidDate(minDate)
    }

    if (!(minDate instanceof Date && !isNaN(minDate))) {
      throw new TypeError('MinDate must be a valid date')
    }

    return `${name} muss nach ${format(minDate, 'd.MM.yyyy')} sein.`
  },
})

setInteractionMode('lazy')

extend('phone', {
  validate: (value) => {
    // https://regex101.com/r/ooKq2J/1
    return /^(\+|0|00)([0-9]){9,}$/.test(value.replace(/\s/g, ''))
  },
  message: '{_field_} muss eine gültige Telefonnummer sein.',
})

localize('de', de)
