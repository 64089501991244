import { render, staticRenderFns } from "./SiteFooter.vue?vue&type=template&id=d719cd1c&scoped=true&"
import script from "./SiteFooter.vue?vue&type=script&lang=js&"
export * from "./SiteFooter.vue?vue&type=script&lang=js&"
import style0 from "./SiteFooter.vue?vue&type=style&index=0&id=d719cd1c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d719cd1c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MobileNavBar: require('/vercel/path0/components/navigation/MobileNavBar.vue').default,SrfLogo: require('/vercel/path0/components/icons/SrfLogo.vue').default,Container: require('/vercel/path0/components/ui/Container.vue').default})
