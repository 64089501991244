export const state = () => ({
  applications: [],
})

export const mutations = {
  setApplications(state, applications) {
    state.applications = applications
  },

  updateApplication(state, application) {
    const index = state.applications.findIndex(
      ({ id }) => id === application.id
    )
    state.applications.splice(index, 1, application)
  },

  removeApplication(state, application) {
    const index = state.applications.findIndex(
      ({ id }) => id === application.id
    )
    state.applications.splice(index, 1)
  },
}

export const actions = {
  async fetchAll({ commit, state }) {
    const { data } = await this.$axios.$get('/applications')

    commit('setApplications', data)

    return state.applications
  },

  async cancelApplication({ commit }, { id }) {
    const { data } = await this.$axios.$post(`/applications/${id}/cancel`)

    commit('updateApplication', data)

    return data
  },

  async archiveApplication({ commit }, { id }) {
    const { data } = await this.$axios.$post(`/applications/${id}/archive`)

    commit('removeApplication', data)

    return data
  },

  async deleteApplication({ commit }, application) {
    await this.$axios.$delete(`/applications/${application.id}`)

    commit('removeApplication', application)
  },

  async reassignApplication({ dispatch }, { application, email }) {
    await this.$axios.$post(
      `/admin/applications/${application.id}/reassign-to/${email}`
    )

    await dispatch('fetchAll')
  },

  async createStatement({ commit }, application) {
    await this.$axios.$post(`/applications/${application.id}/statement`)
  },

  async getApplicationSummary(_, { id }) {
    const data = await this.$axios.$get(`/applications/${id}/summary`)

    return data
  },

  async getGroupCollectionListDocument(_, { id }) {
    const data = await this.$axios.$get(
      `/applications/${id}/group-collection-list-document`
    )

    return data
  },
}
