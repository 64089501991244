import { AllHtmlEntities } from 'html-entities'

export const getYoutubeVideoId = (url) => {
  const match = url.match(
    /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-_]*)(&(amp;)?‌[\w?‌=]*)?/
  )
  return match[1]
}

export const getBreadCrumbsBySlug = (navigation, slug, page) => {
  if (slug.charAt(0) !== '/') {
    slug = `/${slug}`
  }
  let topNav = navigation.find((p) => p.url === slug)
  let subNav = null
  if (!topNav) {
    topNav = navigation.find(
      (n) => n.children && n.children.some((p) => p.url === slug)
    )
    if (topNav) {
      subNav = topNav.children.find((p) => p.url === slug)
    }
  }
  const breadcrumbs = [
    {
      title: 'Startseite',
      url: '/',
    },
  ]

  if (page.collection === 'journal' && page.parent) {
    breadcrumbs.push({
      title: page.parent.title,
      url: page.parent.url,
    })

    breadcrumbs.push({
      title: page.title,
    })
  }

  if (topNav) breadcrumbs.push(topNav)
  if (subNav) breadcrumbs.push(subNav)

  if (breadcrumbs.length === 1) {
    breadcrumbs.push({
      title: page.title,
    })
  }

  return breadcrumbs
}

export const getTextFromHtml = (html) => {
  if (!html) return null

  const htmlWithoutlineBreaks = html.replace(/<(\/?br|\/p)>/g, ' ')
  return AllHtmlEntities.decode(htmlWithoutlineBreaks.replace(/<[^<>]*>/g, ''))
}

export const removePTags = (html) => {
  if (!html) return null
  return html.replace(/<\/?p>/g, '')
}

export const getHeadForPage = (page) => {
  const head = {
    meta: [
      {
        property: 'og:title',
        content: getTextFromHtml(page.page_title) || page.title,
      },

      {
        property: 'og:url',
        content: process.env.appUrl + page.url,
      },
      {
        property: 'og:type',
        content: page.blueprint === 'blog' ? 'blog' : 'website',
      },
      {
        hid: 'og:image',
        property: 'og:image',
        content: page.image
          ? page.image.permalink
          : `${process.env.appUrl}/favicon.png`,
      },
      {
        property: 'og:image:alt',
        content: page.image ? page.image.alt : 'Denk an mich Logo',
      },
    ],
  }

  if (page.image) {
    head.meta.push({
      name: 'twitter:card',
      content: 'summary_large_image',
    })
  } else {
    head.meta.push({
      name: 'twitter:card',
      content: 'summary',
    })
  }
  if (page.seo_hidden) {
    head.meta.push({ hid: 'robots', name: 'robots', content: 'noindex' })
  }
  if (page.title || page.seo_title) {
    head.title =
      (page.seo_title || page.title) +
      (page.blueprint !== 'landingpage' ? ' | Denk an mich' : '')
  }

  const description = getTextFromHtml(page.text)
  if (description || page.seo_description) {
    head.meta.push({
      hid: 'description',
      name: 'description',
      content: page.seo_description || description,
    })
    head.meta.push({
      property: 'og:description',
      content: page.seo_description || page.intro_text || description,
    })
  }
  return head
}

export const formatIban = (iban) => {
  return iban
    .replace(/[^\dA-Z]/g, '')
    .replace(/(.{4})/g, '$1 ')
    .trim()
}
