
export default {
  props: {
    withSpot: {
      type: Boolean,
      default: false,
    },
    spotColorClass: {
      type: String,
      default: 'text-yellow-500',
    },
  },
}
