
export default {
  components: {},

  props: {},

  data() {
    return {}
  },

  computed: {},

  methods: {},
}
