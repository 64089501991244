export default function ({ $axios, store }) {
  $axios.onRequest((config) => {
    if (store.state.applicationForms.user.token) {
      config.headers.Authorization = `Bearer ${store.state.applicationForms.user.token}`
    }
  })

  $axios.onRequest((config) => {
    config.meta = config.meta || {}
    config.meta.requestStartedAt = new Date().getTime()

    return config
  })
}
