export default function (adminOnly = false) {
  return ({ store, error }) => {
    if (adminOnly && !store.state.applicationForms.user.user.isAdmin) {
      error({
        statusCode: 403,
        message: 'Forbidden',
      })
    }
  }
}
