import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_plugin_1a84675c from 'nuxt_plugin_plugin_1a84675c' // Source: ./components/plugin.js (mode: 'all')
import nuxt_plugin_sentryserver_485e2b94 from 'nuxt_plugin_sentryserver_485e2b94' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_761778ae from 'nuxt_plugin_sentryclient_761778ae' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_vuescrollto_771bc557 from 'nuxt_plugin_vuescrollto_771bc557' // Source: ./vue-scrollto.js (mode: 'client')
import nuxt_plugin_pluginutils_3a8ae409 from 'nuxt_plugin_pluginutils_3a8ae409' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginrouting_79629314 from 'nuxt_plugin_pluginrouting_79629314' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_29c150db from 'nuxt_plugin_pluginmain_29c150db' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_gtm_dfaa1070 from 'nuxt_plugin_gtm_dfaa1070' // Source: ./gtm.js (mode: 'all')
import nuxt_plugin_axios_db3c871c from 'nuxt_plugin_axios_db3c871c' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_portalvue_365f2721 from 'nuxt_plugin_portalvue_365f2721' // Source: ./portal-vue.js (mode: 'all')
import nuxt_plugin_templatesplugin76cc3897_34541580 from 'nuxt_plugin_templatesplugin76cc3897_34541580' // Source: ./templates.plugin.76cc3897.js (mode: 'client')
import nuxt_plugin_datefns_be925fcc from 'nuxt_plugin_datefns_be925fcc' // Source: ./date-fns.js (mode: 'all')
import nuxt_plugin_i18n_6a80ea94 from 'nuxt_plugin_i18n_6a80ea94' // Source: ../plugins/i18n (mode: 'all')
import nuxt_plugin_livepreview_10b24b0c from 'nuxt_plugin_livepreview_10b24b0c' // Source: ../plugins/live-preview.js (mode: 'all')
import nuxt_plugin_filter_68021324 from 'nuxt_plugin_filter_68021324' // Source: ../plugins/filter (mode: 'all')
import nuxt_plugin_fontawesome_47952b5b from 'nuxt_plugin_fontawesome_47952b5b' // Source: ../plugins/font-awesome (mode: 'all')
import nuxt_plugin_mixins_9ca86422 from 'nuxt_plugin_mixins_9ca86422' // Source: ../plugins/mixins.js (mode: 'all')
import nuxt_plugin_veevalidate_6e5ad03a from 'nuxt_plugin_veevalidate_6e5ad03a' // Source: ../plugins/vee-validate (mode: 'all')
import nuxt_plugin_axios_3566aa80 from 'nuxt_plugin_axios_3566aa80' // Source: ../plugins/axios (mode: 'all')
import nuxt_plugin_persistedstate_56792181 from 'nuxt_plugin_persistedstate_56792181' // Source: ../plugins/persisted-state (mode: 'all')
import nuxt_plugin_focustrapvue_63a6c728 from 'nuxt_plugin_focustrapvue_63a6c728' // Source: ../plugins/focus-trap-vue.client (mode: 'client')
import nuxt_plugin_router_3e5c7b82 from 'nuxt_plugin_router_3e5c7b82' // Source: ../plugins/router (mode: 'all')
import nuxt_plugin_replaceLineBreaks_34004cf4 from 'nuxt_plugin_replaceLineBreaks_34004cf4' // Source: ../plugins/replaceLineBreaks (mode: 'all')
import nuxt_plugin_removehtmltags_07a05330 from 'nuxt_plugin_removehtmltags_07a05330' // Source: ../plugins/remove-html-tags (mode: 'all')
import nuxt_plugin_objectfitimages_78f21368 from 'nuxt_plugin_objectfitimages_78f21368' // Source: ../plugins/object-fit-images (mode: 'client')
import nuxt_plugin_vclickoutside_661f0b13 from 'nuxt_plugin_vclickoutside_661f0b13' // Source: ../plugins/v-click-outside (mode: 'client')
import nuxt_plugin_vueyoutubeembed_1b7c021c from 'nuxt_plugin_vueyoutubeembed_1b7c021c' // Source: ../plugins/vue-youtube-embed (mode: 'client')
import nuxt_plugin_vueselect_0c6a19ce from 'nuxt_plugin_vueselect_0c6a19ce' // Source: ../plugins/vue-select (mode: 'client')
import nuxt_plugin_vue2googlemaps_94a82780 from 'nuxt_plugin_vue2googlemaps_94a82780' // Source: ../plugins/vue2-google-maps (mode: 'client')
import nuxt_plugin_vuepdf_16ca6c3c from 'nuxt_plugin_vuepdf_16ca6c3c' // Source: ../plugins/vue-pdf.js (mode: 'client')
import nuxt_plugin_gtmloader_48d84556 from 'nuxt_plugin_gtmloader_48d84556' // Source: ../plugins/gtm-loader (mode: 'client')
import nuxt_plugin_activecampaign_62767898 from 'nuxt_plugin_activecampaign_62767898' // Source: ../plugins/active-campaign.client (mode: 'client')
import nuxt_plugin_facebooktracking_d3c4915e from 'nuxt_plugin_facebooktracking_d3c4915e' // Source: ../plugins/facebook-tracking.client (mode: 'client')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root.$options.$nuxt
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext, config)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"Denk an mich","titleTemplate":"%s | Denk an mich","htmlAttrs":{"lang":"de-CH"},"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.svg"}],"script":[{"src":"https:\u002F\u002Fpolyfill.io\u002Fv3\u002Fpolyfill.min.js?features=default"},{"hid":"gtm-script","innerHTML":"if(!window._gtm_init){window._gtm_init=1;(function(w,n,d,m,e,p){w[d]=(w[d]==1||n[d]=='yes'||n[d]==1||n[m]==1||(w[e]&&w[e][p]&&w[e][p]()))?1:0})(window,navigator,'doNotTrack','msDoNotTrack','external','msTrackingProtectionEnabled');(function(w,d,s,l,x,y){w[x]={};w._gtm_inject=function(i){if(w.doNotTrack||w[x][i])return;w[x][i]=1;w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s);j.async=true;j.src='https:\u002F\u002Fwww.googletagmanager.com\u002Fgtm.js?id='+i;f.parentNode.insertBefore(j,f);}})(window,document,'script','dataLayer','_gtm_ids','_gtm_inject')}"}],"style":[],"noscript":[{"hid":"gtm-noscript","pbody":true,"innerHTML":""}],"__dangerouslyDisableSanitizersByTagID":{"gtm-script":["innerHTML"],"gtm-noscript":["innerHTML"]}},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_1a84675c === 'function') {
    await nuxt_plugin_plugin_1a84675c(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_sentryserver_485e2b94 === 'function') {
    await nuxt_plugin_sentryserver_485e2b94(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_761778ae === 'function') {
    await nuxt_plugin_sentryclient_761778ae(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuescrollto_771bc557 === 'function') {
    await nuxt_plugin_vuescrollto_771bc557(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_3a8ae409 === 'function') {
    await nuxt_plugin_pluginutils_3a8ae409(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_79629314 === 'function') {
    await nuxt_plugin_pluginrouting_79629314(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_29c150db === 'function') {
    await nuxt_plugin_pluginmain_29c150db(app.context, inject)
  }

  if (typeof nuxt_plugin_gtm_dfaa1070 === 'function') {
    await nuxt_plugin_gtm_dfaa1070(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_db3c871c === 'function') {
    await nuxt_plugin_axios_db3c871c(app.context, inject)
  }

  if (typeof nuxt_plugin_portalvue_365f2721 === 'function') {
    await nuxt_plugin_portalvue_365f2721(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_templatesplugin76cc3897_34541580 === 'function') {
    await nuxt_plugin_templatesplugin76cc3897_34541580(app.context, inject)
  }

  if (typeof nuxt_plugin_datefns_be925fcc === 'function') {
    await nuxt_plugin_datefns_be925fcc(app.context, inject)
  }

  if (typeof nuxt_plugin_i18n_6a80ea94 === 'function') {
    await nuxt_plugin_i18n_6a80ea94(app.context, inject)
  }

  if (typeof nuxt_plugin_livepreview_10b24b0c === 'function') {
    await nuxt_plugin_livepreview_10b24b0c(app.context, inject)
  }

  if (typeof nuxt_plugin_filter_68021324 === 'function') {
    await nuxt_plugin_filter_68021324(app.context, inject)
  }

  if (typeof nuxt_plugin_fontawesome_47952b5b === 'function') {
    await nuxt_plugin_fontawesome_47952b5b(app.context, inject)
  }

  if (typeof nuxt_plugin_mixins_9ca86422 === 'function') {
    await nuxt_plugin_mixins_9ca86422(app.context, inject)
  }

  if (typeof nuxt_plugin_veevalidate_6e5ad03a === 'function') {
    await nuxt_plugin_veevalidate_6e5ad03a(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_3566aa80 === 'function') {
    await nuxt_plugin_axios_3566aa80(app.context, inject)
  }

  if (typeof nuxt_plugin_persistedstate_56792181 === 'function') {
    await nuxt_plugin_persistedstate_56792181(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_focustrapvue_63a6c728 === 'function') {
    await nuxt_plugin_focustrapvue_63a6c728(app.context, inject)
  }

  if (typeof nuxt_plugin_router_3e5c7b82 === 'function') {
    await nuxt_plugin_router_3e5c7b82(app.context, inject)
  }

  if (typeof nuxt_plugin_replaceLineBreaks_34004cf4 === 'function') {
    await nuxt_plugin_replaceLineBreaks_34004cf4(app.context, inject)
  }

  if (typeof nuxt_plugin_removehtmltags_07a05330 === 'function') {
    await nuxt_plugin_removehtmltags_07a05330(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_objectfitimages_78f21368 === 'function') {
    await nuxt_plugin_objectfitimages_78f21368(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vclickoutside_661f0b13 === 'function') {
    await nuxt_plugin_vclickoutside_661f0b13(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vueyoutubeembed_1b7c021c === 'function') {
    await nuxt_plugin_vueyoutubeembed_1b7c021c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vueselect_0c6a19ce === 'function') {
    await nuxt_plugin_vueselect_0c6a19ce(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vue2googlemaps_94a82780 === 'function') {
    await nuxt_plugin_vue2googlemaps_94a82780(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuepdf_16ca6c3c === 'function') {
    await nuxt_plugin_vuepdf_16ca6c3c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_gtmloader_48d84556 === 'function') {
    await nuxt_plugin_gtmloader_48d84556(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_activecampaign_62767898 === 'function') {
    await nuxt_plugin_activecampaign_62767898(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_facebooktracking_d3c4915e === 'function') {
    await nuxt_plugin_facebooktracking_d3c4915e(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
