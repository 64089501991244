import {
  FilterBase,
  GatewayBase,
  objectFullyPresentIn,
  StatusBase,
} from '~/utils/applicationForms/utils'

export class StoreBase {
  filter = new FilterBase()
  gateway = new GatewayBase()
  status = new StatusBase()

  buildUrl(id, path = '') {
    return `/applications/${id}${path}`
  }

  stateGenerator() {
    return {
      steps: [],

      data: {},

      application: {},
    }
  }

  get state() {
    return this.stateGenerator.bind(this)
  }

  get mutations() {
    const self = this

    return {
      applyRemoteData(state, data) {
        /*
         * Applies the data fetched from the api to the state following it's structure.
         * Eg. { age: 21, name: 'Johnny Mc Chicken Fries' }
         * becomes
         * { data: { FEXXX: { age: 21 }, FEYYY: { name: 'Johnny Mc Chicken Fries' } }
         */
        Object.keys(state.data).forEach((key) => {
          if (state.data[key] && typeof state.data[key] === 'object') {
            const mappedData = self.gateway.in(key, data, this)
            Object.keys(state.data[key]).forEach((prop) => {
              state.data[key][prop] = mappedData[prop] ?? state.data[key][prop]
            })
          }
        })
      },

      setData(state, data) {
        state.data = data
      },

      applyResponseData(state, data) {
        state.application = data
      },

      setQuestionData(state, { id, data }) {
        Object.keys(state.data[id] || {}).forEach((prop) => {
          state.data[id][prop] =
            data[prop] ?? self.stateGenerator().data[id][prop]
        })
      },
    }
  }

  get actions() {
    const self = this

    return {
      reset({ commit }) {
        const { data, application } = self.stateGenerator()

        commit('setData', data)
        commit('applyResponseData', application)
      },

      async init({ commit }, { route }) {
        if (!route.params.id) {
          commit('applyResponseData', {})
          commit('applyRemoteData', {})

          return
        }

        const url = self.buildUrl(route.params.id)

        const { data } = await this.$axios.$get(url)

        commit('applyResponseData', data)
        commit('applyRemoteData', data)
      },

      async setQuestionData({ state, getters, commit, dispatch }, payload) {
        const question = getters.questions.find(
          (question) => question.id === payload.id
        )

        let questionData = payload.data
        // Only set question data and reset depending questions if the data has changed
        if (objectFullyPresentIn(state.data[question.id], questionData)) {
          return
        }

        if (question.setQuestionDataEndpoint) {
          const currentRoute = this.$router.currentRoute
          const url = self.buildUrl(
            currentRoute.params.id,
            question.setQuestionDataEndpoint
          )

          const { data } = await this.$axios.$put(
            url,
            await self.gateway.out(question.id, payload.data, this)
          )
          questionData = self.gateway.in(question.id, data, this)

          commit('applyResponseData', data)
        }
        commit('setQuestionData', {
          id: question.id,
          data: questionData,
        })

        await dispatch('resetDependingQuestions', { id: question.id })
      },

      async resetDependingQuestions(
        { getters, state, commit, dispatch },
        { id }
      ) {
        const dependingQuestions = getters.questions.filter((question) =>
          self.filter
            .questionDependsOn(question, state.data, state, this.state)
            .includes(id)
        )

        await Promise.all(
          dependingQuestions.map(async (dependingQuestion) => {
            if (
              dependingQuestion.setQuestionDataEndpoint &&
              dependingQuestion.isTouched
            ) {
              const currentRoute = this.$router.currentRoute
              const url = self.buildUrl(
                currentRoute.params.id,
                dependingQuestion.setQuestionDataEndpoint
              )
              const { data } = await this.$axios.$delete(url, {
                data: await self.gateway.out(dependingQuestion.id, {}, this),
              })

              commit('setQuestionData', {
                id: dependingQuestion.id,
                data: self.gateway.in(dependingQuestion.id, data, this),
              })
            } else {
              commit('setQuestionData', {
                id: dependingQuestion.id,
                data: {},
              })
            }

            await dispatch('resetDependingQuestions', {
              id: dependingQuestion.id,
            })
          })
        )
      },

      async submit({ state, commit }) {
        const url = self.buildUrl(state.application.id, '/submit')
        const { data } = await this.$axios.$post(url)

        commit('applyResponseData', data)
      },
      async submitStatement({ state, commit }) {
        const url = self.buildUrl(state.application.id, '/statement/submit')
        const { data } = await this.$axios.$post(url)
        commit('applyResponseData', data)
      },
      async cancel({ state, commit }) {
        const url = self.buildUrl(state.application.id, '/cancel')
        const { data } = await this.$axios.$post(url)
        commit('applyResponseData', data)
      },
    }
  }

  get getters() {
    return {
      steps: (state, _, globalState) => {
        return state.steps.map((step) => {
          const questions = step.questions.map((question) => ({
            ...question,

            ...this.status.questionStatus(
              question,
              state.data[question.id],
              state
            ),
            shouldSkip: !this.filter.shouldShowQuestion(
              question,
              state.data,
              state,
              globalState
            ),
          }))

          return {
            ...step,
            questions,

            isTouched: questions.some((q) => !q.isEmpty && q.isCompleted),
            isCompleted: questions
              .filter((q) => !q.shouldSkip)
              .every((q) => q.isCompleted),
          }
        })
      },

      questions: (_, getters) =>
        getters.steps.flatMap((step) => step.questions),

      filteredSteps: (_, getters) =>
        getters.steps
          .map((step) => ({
            ...step,
            questions: step.questions.filter(
              (question) => !question.shouldSkip
            ),
          }))
          .filter((step) => step.questions.length),
    }
  }
}

export class StatementStoreBase extends StoreBase {
  buildUrl(id, path = '') {
    return `/applications/${id}/statement${path}`
  }

  buildApplicationUrl(id, path = '') {
    return super.buildUrl(id, path)
  }

  stateGenerator() {
    return {
      ...super.stateGenerator(),

      statement: {},
    }
  }

  get mutations() {
    return {
      ...super.mutations,

      setApplication(state, data) {
        state.application = data
      },

      applyResponseData(state, data) {
        state.statement = data
      },
    }
  }

  get actions() {
    const self = this

    return {
      ...super.actions,

      async init({ commit }, { route }) {
        if (!route.params.id) {
          commit('setApplication', {})
          commit('applyResponseData', {})
          commit('applyRemoteData', {})

          return
        }

        const url = self.buildApplicationUrl(route.params.id)

        const {
          data: { statement, ...application },
        } = await this.$axios.$get(url)
        commit('setApplication', application)
        commit('applyResponseData', statement)
        commit('applyRemoteData', statement)
      },
    }
  }
}
