import { render, staticRenderFns } from "./DashboardNavigation.vue?vue&type=template&id=29518fcb&scoped=true&"
import script from "./DashboardNavigation.vue?vue&type=script&lang=js&"
export * from "./DashboardNavigation.vue?vue&type=script&lang=js&"
import style0 from "./DashboardNavigation.vue?vue&type=style&index=0&id=29518fcb&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29518fcb",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LogoButton: require('/vercel/path0/components/ui/LogoButton.vue').default,Triangle: require('/vercel/path0/components/icons/Triangle.vue').default,AppLink: require('/vercel/path0/components/ui/AppLink.vue').default,LanguageSwitcher: require('/vercel/path0/components/LanguageSwitcher.vue').default,Container: require('/vercel/path0/components/ui/Container.vue').default})
