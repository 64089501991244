
export default {
  props: {},

  data() {
    return {
      labels: {
        de: 'Sprache auf Deutsch wechseln',
        fr: 'Changer la langue en français',
        it: 'Cambia lingua in italiano',
      },
    }
  },

  computed: {
    availableLocales() {
      return this.$i18n.locales.filter((i) => i.code !== this.$i18n.locale)
    },

    currentLocale() {
      return this.$i18n.locale
    },
  },

  mounted() {},

  methods: {},
}
