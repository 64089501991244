
import { mapState } from 'vuex'

export default {
  components: {},

  data() {
    return {
      acceptAnalytics: true,
    }
  },

  computed: {
    ...mapState('content', ['cookieBanner']),
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    close() {
      this.$emit('closed')
    },
  },
}
