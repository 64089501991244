
export default {
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: null,
    },
    rules: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    instructions: {
      type: String,
      default: null,
    },
    border: {
      type: Boolean,
      default: false,
    },
    noSpacingBottom: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      internalValue: this.value,
    }
  },
  watch: {
    internalValue() {
      this.$emit('input', this.internalValue)
    },
    value() {
      this.internalValue = this.value
    },
  },

  mounted() {
    this.$refs.input.value = this.value
  },

  methods: {
    focus() {
      this.$refs.input.focus()
    },
  },
}
