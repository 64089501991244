
export default {
  data() {
    return {
      form: {},
      isLoading: false,
      submissionState: null,
      genders: [
        {
          label: 'Herr',
          value: 'm',
        },
        {
          label: 'Frau',
          value: 'f',
        },
        {
          label: 'Neutrale Anrede',
          value: 'n',
        },
      ],
    }
  },
  mounted() {
    document.addEventListener('keyup', this.handleEscape)
    const top = `-${window.scrollY}px`
    document.body.style.overflowY = 'hidden'
    document.body.style.position = 'fixed'
    document.body.style.top = top
    document.body.style.left = 0
    document.body.style.right = 0
  },
  beforeDestroy() {
    const body = document.body
    const scrollY = document.body.style.top
    body.style.overflowY = 'auto'
    body.style.position = ''
    body.style.top = ''
    body.style.left = ''
    body.style.right = ''
    window.scrollTo(0, parseInt(scrollY || '0') * -1)
    document.removeEventListener('keyup', this.handleEscape)
  },
  methods: {
    subscribe() {
      if (!this.isLoading) {
        this.isLoading = true
        this.$axios
          .$post('newsletter/subscribe', this.form)
          .then(() => {
            localStorage.setItem('preventNewsletterPopup', true)
            this.submissionState = 'success'

            this.$fb.fbq('track', 'CompleteRegistration')
          })
          .catch(() => {
            this.submissionState = 'error'
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },
    handleDismiss() {
      this.$emit('dismiss')
    },
    handleEscape(evt) {
      if (evt.key === 'Escape' && this.handleDismiss) {
        this.handleDismiss()
      }
    },
  },
}
