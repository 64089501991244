function preventScrolling(yes) {
  const body = document.getElementsByTagName('body')[0]
  if (yes) {
    body.style.overflowY = 'hidden'
  } else {
    body.style.overflowY = 'auto'
  }
}

export const state = () => ({
  isOpen: false,
  navigation: [],
  pages: [],
})

export const actions = {
  async load({ commit }) {
    const { data } = await this.$axios.$get('navigation')
    commit('setNavigation', data)
  },
}

export const mutations = {
  openNav(state) {
    state.isOpen = true
    preventScrolling(state.isOpen)
  },

  closeNav(state) {
    state.isOpen = false
    preventScrolling(state.isOpen)
  },

  toggleNav(state) {
    state.isOpen = !state.isOpen
    preventScrolling(state.isOpen)
  },

  setNavigation(state, value) {
    state.pages = value
  },
}
