import {
  FilterBase,
  GatewayBase,
  StatusBase,
} from '~/utils/applicationForms/utils'
import { StoreBase } from '~/utils/applicationForms/base'

class Filter extends FilterBase {
  showFE120(data, state, globalState) {
    return !globalState.applicationForms.user.user
  }

  showFE130(data, state, globalState) {
    return !globalState.applicationForms.user.user
  }

  showFE160(data) {
    return data.FE150.identification === 'stand-in'
  }

  showFE220(data) {
    // Only show if public support is received
    return [
      data.FE210.disabilityInsurance,
      data.FE210.supplementaryBenefits,
      data.FE210.socialAid,
    ].includes(true)
  }

  showFE230(data) {
    return data.FE210.supplementaryBenefits === false
  }

  showFE240(data) {
    return typeof data.FE230.taxableIncome === 'number'
  }

  showFE310(data) {
    return data.FE300.reasonForRequest === 'travel'
  }

  showFE320(data) {
    return data.FE310.organizationalForm === 'independent-travel'
  }

  showFE330(data) {
    return (
      data.FE310.organizationalForm === 'independent-travel' &&
      data.FE320.numberOfPeople > 1
    )
  }

  showFE340(data) {
    return (
      ['camp', 'organized-travel'].includes(data.FE310.organizationalForm) ||
      data.FE300.reasonForRequest === 'leisure'
    )
  }

  showFE350(data) {
    return (
      data.FE340.companionRequired === true ||
      data.FE330.companions.includes('companion')
    )
  }

  showFE360(data) {
    return ['travel', 'leisure'].includes(data.FE300.reasonForRequest)
  }

  showFE370(data) {
    return ['travel', 'leisure'].includes(data.FE300.reasonForRequest)
  }

  showFE380(data) {
    return data.FE370.homeCountry === true
  }

  showFE390(data) {
    return data.FE370.homeCountry === false
  }

  showFE400(data) {
    return data.FE300.reasonForRequest === 'procurement'
  }

  showFE410(data) {
    return data.FE300.reasonForRequest === 'procurement'
  }

  showFE500(data) {
    return data.FE300.reasonForRequest === 'travel'
  }

  showFE510(data) {
    return data.FE300.reasonForRequest === 'leisure'
  }

  showFE520(data) {
    return data.FE300.reasonForRequest === 'procurement'
  }

  showFE600(data) {
    return data.FE150.identification === 'self-reliant'
  }

  showFE610(data) {
    return data.FE150.identification === 'stand-in'
  }

  showFE620(data) {
    return data.FE150.identification === 'stand-in'
  }
}

class Gateway extends GatewayBase {
  inFE100(data) {
    return { read: !!data.id }
  }

  inFE120(data, context) {
    return {
      email: context.state.applicationForms.user.user?.email,
      emailConfirmation: context.state.applicationForms.user.user?.email,
    }
  }

  inFE210(data) {
    return data.welfareAid
  }

  inFE220(data) {
    return data.welfareAidDocuments
  }

  outFE220(data) {
    return Object.fromEntries(
      Object.entries(data)
        .filter(([key, value]) => value?.id)
        .map(([key, value]) => [`${key}DocumentId`, value.id])
    )
  }

  outFE240({ taxableIncomeDocument }) {
    if (taxableIncomeDocument?.id) {
      return {
        taxableIncomeDocumentId: taxableIncomeDocument?.id,
      }
    }

    return {}
  }

  inFE340(data) {
    return {
      companionRequired: data.travelCompanionRequired,
    }
  }

  inFE350(data) {
    return {
      reasons: data.travelCompanionReasons,
    }
  }

  inFE360(data) {
    return {
      start: data.timePeriod?.start,
      end: data.timePeriod?.end,
    }
  }

  inFE370(data) {
    if (!data.destinationCountryCode) {
      return { homeCountry: null }
    }
    return {
      homeCountry:
        data.destinationCountryCode && data.destinationCountryCode === 193,
    }
  }

  inFE380(data) {
    return { destinationLocation: data.destinationLocation }
  }

  outFE380(data) {
    return {
      destinationCountryCode: 193, // Switzerland
      destinationLocation: data.destinationLocation,
    }
  }

  inFE390(data) {
    return {
      destinationCountryCode: data.destinationCountryCode,
      destinationLocation: data.destinationLocation,
    }
  }

  inFE400(data) {
    return {
      object: data.procurement?.object,
    }
  }

  inFE410(data) {
    return {
      date: data.timePeriod?.start,
    }
  }

  outFE410(data) {
    return {
      start: data.date,
    }
  }

  // TODO: Update for future contact structure
  inFE540(data) {
    return {
      correct: data.contact ? true : null,
    }
  }

  inFE600(data) {
    return data.contact
  }

  async outFE600(data, { $axios, $router }) {
    if (Object.keys(data).length <= 0) {
      return {
        type: 'individual',
      }
    }

    if (!data.id) {
      data = (await $axios.$post('/contacts', data)).data
    }

    return {
      id: data.id,
      type: 'individual',
    }
  }

  inFE610() {
    return this.inFE600(...arguments)
  }

  outFE610() {
    return this.outFE600(...arguments)
  }

  inFE620(data) {
    return data.representation
  }

  async outFE620(data, { $axios, $router }) {
    if (Object.keys(data).length <= 0) {
      return {
        type: 'representation',
      }
    }

    if (!data.id) {
      data = (await $axios.$post('/contacts', data)).data
    }

    return {
      id: data.id,
      type: 'representation',
    }
  }
}

class Status extends StatusBase {
  FE100(data, state) {
    return {
      isCompleted: data.read === true,
      isLocked: !!state.application.id,
    }
  }

  FE120(data, state) {
    return {
      isLocked: !!state.application.id,
    }
  }

  FE130(data, state) {
    return {
      isLocked: !!state.application.id,
    }
  }

  FE220(data, state) {
    return {
      // Is completed if all documents required are given
      isCompleted:
        (state.data.FE210.disabilityInsurance === false ||
          (state.data.FE210.disabilityInsurance === true &&
            state.data.FE220.disabilityInsurance)) &&
        (state.data.FE210.supplementaryBenefits === false ||
          (state.data.FE210.supplementaryBenefits === true &&
            state.data.FE220.supplementaryBenefits)) &&
        (state.data.FE210.socialAid === false ||
          (state.data.FE210.socialAid === true && state.data.FE220.socialAid)),
    }
  }

  FE630(data, state) {
    return {
      isCompleted: state.application.status !== 'preparing',
    }
  }

  FE640(data, state) {
    return {
      isCompleted: state.application.status !== 'preparing',
    }
  }

  FE620(data, state) {
    return {
      isCompleted: state.application.representation,
    }
  }
}

class Store extends StoreBase {
  filter = new Filter()
  gateway = new Gateway()
  status = new Status()

  stateGenerator() {
    return {
      ...super.stateGenerator(),

      steps: [
        {
          titleKey: 'single-person.input',
          questions: [
            {
              id: 'FE120',
              titleKey: 'single-person.FE120.title',
              route: { name: 'gesuche-einzelperson-email' },
            },
            {
              id: 'FE130',
              titleKey: 'single-person.FE130.title',
              route: {
                name: 'gesuche-einzelperson-verifikation-email',
              },
            },
            {
              id: 'FE100',
              titleKey: 'single-person.FE100.title',
              route: { name: 'gesuche-einzelperson-gesuch' },
            },
            {
              id: 'FE140',
              titleKey: 'single-person.FE140.title',
              route: { name: 'gesuche-einzelperson-id-eingabe-einstieg' },
            },
            {
              id: 'FE150',
              titleKey: 'single-person.FE150.title',
              route: {
                name: 'gesuche-einzelperson-id-eingabe-identifikation',
              },
              setQuestionDataEndpoint: '/identification',
            },
            {
              id: 'FE160',
              titleKey: 'single-person.FE160.title',
              route: {
                name: 'gesuche-einzelperson-id-eingabe-information-fremdausfueller',
              },
            },
          ],
        },
        {
          titleKey: 'single-person.qualification',
          questions: [
            {
              id: 'FE200',
              titleKey: 'single-person.FE200.title',
              route: {
                name: 'gesuche-einzelperson-id-qualifikation-geburtsdatum',
              },
              setQuestionDataEndpoint: '/birthday',
            },
            {
              id: 'FE210',
              titleKey: 'single-person.FE210.title',
              route: {
                name: 'gesuche-einzelperson-id-qualifikation-einkommensbasis',
              },
              setQuestionDataEndpoint: '/welfare-aid',
            },
            {
              id: 'FE220',
              titleKey: 'single-person.FE220.title',
              route: {
                name: 'gesuche-einzelperson-id-qualifikation-belege-unterstuetzung',
              },
              setQuestionDataEndpoint: '/welfare-aid-documents',
            },
            {
              id: 'FE230',
              titleKey: 'single-person.FE230.title',
              route: {
                name: 'gesuche-einzelperson-id-qualifikation-steuerbares-einkommen',
              },
              setQuestionDataEndpoint: '/taxable-income',
            },
            {
              id: 'FE240',
              titleKey: 'single-person.FE240.title',
              route: {
                name: 'gesuche-einzelperson-id-qualifikation-beleg-steuerbares-einkommen',
              },
              setQuestionDataEndpoint: '/taxable-income-document',
            },
          ],
        },
        {
          titleKey: 'single-person.intended-use',
          questions: [
            {
              id: 'FE300',
              titleKey: 'single-person.FE300.title',
              route: {
                name: 'gesuche-einzelperson-id-verwendungszweck-gesuchsgrund',
              },
              setQuestionDataEndpoint: '/reason-for-request',
            },
            {
              id: 'FE310',
              titleKey: 'single-person.FE310.title',
              route: {
                name: 'gesuche-einzelperson-id-verwendungszweck-organisationsform',
              },
              setQuestionDataEndpoint: '/organizational-form',
            },
            {
              id: 'FE320',
              titleKey: 'single-person.FE320.title',
              route: {
                name: 'gesuche-einzelperson-id-verwendungszweck-anzahl-personen',
              },
              setQuestionDataEndpoint: '/number-of-people',
            },
            {
              id: 'FE330',
              titleKey: 'single-person.FE330.title',
              route: {
                name: 'gesuche-einzelperson-id-verwendungszweck-begleitpersonen',
              },
              setQuestionDataEndpoint: '/travel-companions',
            },
            {
              id: 'FE340',
              titleKey: 'single-person.FE340.title',
              route: {
                name: 'gesuche-einzelperson-id-verwendungszweck-begleitperson',
              },
              setQuestionDataEndpoint: '/travel-companion-required',
            },
            {
              id: 'FE350',
              titleKey: 'single-person.FE350.title',
              route: {
                name: 'gesuche-einzelperson-id-verwendungszweck-begruendung-begleitperson',
              },
              setQuestionDataEndpoint: '/travel-companion-reasons',
            },
            {
              id: 'FE360',
              titleKey: 'single-person.FE360.title',
              route: {
                name: 'gesuche-einzelperson-id-verwendungszweck-datum-reise-freizeitaktivitaet',
              },
              setQuestionDataEndpoint: '/time-period',
            },
            {
              id: 'FE370',
              titleKey: 'single-person.FE370.title',
              route: {
                name: 'gesuche-einzelperson-id-verwendungszweck-inland-ausland',
              },
            },
            {
              id: 'FE380',
              titleKey: 'single-person.FE380.title',
              route: {
                name: 'gesuche-einzelperson-id-verwendungszweck-ort',
              },
              setQuestionDataEndpoint: '/destination',
            },
            {
              id: 'FE390',
              titleKey: 'single-person.FE390.title',
              route: {
                name: 'gesuche-einzelperson-id-verwendungszweck-land-ort',
              },
              setQuestionDataEndpoint: '/destination',
            },
            {
              id: 'FE400',
              titleKey: 'single-person.FE400.title',
              route: {
                name: 'gesuche-einzelperson-id-verwendungszweck-anschaffungsgegenstand',
              },
              setQuestionDataEndpoint: '/procurement',
            },
            {
              id: 'FE410',
              titleKey: 'single-person.FE410.title',
              route: {
                name: 'gesuche-einzelperson-id-verwendungszweck-anschaffungsdatum',
              },
              setQuestionDataEndpoint: '/time-period',
            },
            {
              id: 'FE420',
              titleKey: 'single-person.FE420.title',
              route: {
                name: 'gesuche-einzelperson-id-verwendungszweck-motivation',
              },
              setQuestionDataEndpoint: '/motivation',
            },
          ],
        },
        {
          titleKey: 'single-person.budget',
          questions: [
            {
              id: 'FE500',
              titleKey: 'single-person.FE500.title',
              route: {
                name: 'gesuche-einzelperson-id-budget-ausgaben-ferienreise',
              },
              setQuestionDataEndpoint: '/expenses',
            },
            {
              id: 'FE510',
              titleKey: 'single-person.FE510.title',
              route: {
                name: 'gesuche-einzelperson-id-budget-ausgaben-freizeitaktivitaet',
              },
              setQuestionDataEndpoint: '/expenses',
            },
            {
              id: 'FE520',
              titleKey: 'single-person.FE520.title',
              route: {
                name: 'gesuche-einzelperson-id-budget-ausgaben-anschaffung',
              },
              setQuestionDataEndpoint: '/expenses',
            },
            {
              id: 'FE530',
              titleKey: 'single-person.FE530.title',
              route: {
                name: 'gesuche-einzelperson-id-budget-einnahmen',
              },
              setQuestionDataEndpoint: '/budget-available',
            },
            {
              id: 'FE540',
              titleKey: 'single-person.FE540.title',
              route: {
                name: 'gesuche-einzelperson-id-budget-defizit',
              },
            },
          ],
        },
        {
          titleKey: 'single-person.contact',
          questions: [
            {
              id: 'FE600',
              titleKey: 'single-person.FE600.title',
              route: {
                name: 'gesuche-einzelperson-id-kontaktdaten-selbsteingabe',
              },
              setQuestionDataEndpoint: '/contacts',
            },
            {
              id: 'FE610',
              titleKey: 'single-person.FE610.title',
              route: {
                name: 'gesuche-einzelperson-id-kontaktdaten-fremdeingabe',
              },
              setQuestionDataEndpoint: '/contacts',
            },
            {
              id: 'FE620',
              titleKey: 'single-person.FE620.title',
              route: {
                name: 'gesuche-einzelperson-id-kontaktdaten-vertretung',
              },
              setQuestionDataEndpoint: '/contacts',
            },
            {
              id: 'FE630',
              titleKey: 'single-person.FE630.title',
              route: {
                name: 'gesuche-einzelperson-id-datenpruefung',
              },
              ignoreInQuestionCount: true,
            },
            {
              id: 'FE640',
              titleKey: 'single-person.FE640.title',
              route: {
                name: 'gesuche-einzelperson-id-abschluss',
              },
              ignoreInQuestionCount: true,
            },
          ],
        },
      ],

      data: {
        FE100: { read: false },
        FE120: { email: null, emailConfirmation: null },
        FE140: {},
        FE150: { identification: null },
        FE200: { birthday: null },
        FE210: {
          disabilityInsurance: null,
          supplementaryBenefits: null,
          socialAid: null,
          others: null,
        },
        FE220: {
          disabilityInsurance: null,
          supplementaryBenefits: null,
          socialAid: null,
        },
        FE230: { taxableIncome: null },
        FE240: { taxableIncomeDocument: null },
        FE300: { reasonForRequest: null },
        FE310: { organizationalForm: null },
        FE320: { numberOfPeople: null },
        FE330: { companions: [] },
        FE340: { companionRequired: null },
        FE350: { reasons: [] },
        FE360: { start: null, end: null },
        FE370: { homeCountry: null },
        FE380: { destinationLocation: null },
        FE390: { destinationCountryCode: null, destinationLocation: null },
        FE400: { object: null },
        FE410: { date: null },
        FE420: { motivation: null },
        FE500: { expenses: [] },
        FE510: { expenses: [] },
        FE520: { expenses: [] },
        FE530: { budgetAvailable: [] },
        FE540: { correct: null },
        FE600: {
          id: null,
          salutCode: null,
          firstname: null,
          lastname: null,
          street: null,
          houseNumber: null,
          zipCode: null,
          location: null,
          phone: null,
          email: null,
        },
        FE610: {
          id: null,
          salutCode: null,
          firstname: null,
          lastname: null,
          street: null,
          houseNumber: null,
          zipCode: null,
          location: null,
          phone: null,
          email: null,
        },
        FE620: {
          id: null,
          organization: null,
          salutCode: null,
          firstname: null,
          lastname: null,
          street: null,
          houseNumber: null,
          zipCode: null,
          location: null,
          phone: null,
          email: null,
        },
      },
    }
  }
}

export default new Store()
