import { StoreBase } from '~/utils/applicationForms/base'

class Store extends StoreBase {
  stateGenerator() {
    return {
      ...super.stateGenerator(),

      steps: [
        {
          titleKey: 'single-person.kurzcheck.finances',
          questions: [
            {
              id: 'CE100',
              titleKey: 'single-person.CE100.title',
              component: 'EinzelpersonKurzcheckFinanzen',
            },
          ],
        },
        {
          titleKey: 'single-person.kurzcheck.taxable-income',
          questions: [
            {
              id: 'CE110',
              titleKey: 'single-person.CE110.title',
              component: 'EinzelpersonKurzcheckSteuerbaresEinkommen',
            },
          ],
        },
        {
          titleKey: 'single-person.kurzcheck.reason-for-request',
          questions: [
            {
              id: 'CE120',
              titleKey: 'single-person.CE120.title',
              component: 'EinzelpersonKurzcheckGesuchsgrund',
            },
          ],
        },
        {
          titleKey: 'single-person.kurzcheck.entry-deadline',
          questions: [
            {
              id: 'CE130',
              titleKey: 'single-person.CE130.title',
              component: 'EinzelpersonKurzcheckEingabefrist',
            },
          ],
        },
      ],

      data: {
        CE100: { finances: [] },
        CE110: { taxableIncome: null },
        CE120: { reasonForRequest: null },
        CE130: { deadline: null },
      },

      popup: {
        show: false,
        success: false,
        text: '',
      },
    }
  }

  get mutations() {
    return {
      ...super.mutations,
      resetError(state) {
        state.popup.show = false
      },
      setFinalPopupIfCheckFailed(state) {
        if (
          state.data.CE100.finances.length > 0 &&
          state.data.CE100.finances.includes(99)
        ) {
          state.popup.show = true
          state.popup.success = false
          state.popup.text = this.$i18n.t('single-person.kurzcheck.popup2.text')
        } else if (
          state.data.CE110.taxableIncome &&
          state.data.CE110.taxableIncome >= 99
        ) {
          state.popup.show = true
          state.popup.success = false
          state.popup.text = this.$i18n.t('single-person.kurzcheck.popup3.text')
        } else if (
          state.data.CE120.reasonForRequest &&
          state.data.CE120.reasonForRequest >= 99
        ) {
          state.popup.show = true
          state.popup.success = false
          state.popup.text = this.$i18n.t('single-person.kurzcheck.popup4.text')
        } else if (
          state.data.CE130.deadline &&
          state.data.CE130.deadline >= 97
        ) {
          state.popup.show = true
          state.popup.success = false
          state.popup.text = this.$i18n.t('single-person.kurzcheck.popup5.text')
        } else if (
          state.data.CE100.finances.length > 0 &&
          state.data.CE110.taxableIncome &&
          state.data.CE120.reasonForRequest &&
          state.data.CE130.deadline
        ) {
          state.popup.show = true
          state.popup.success = true
          state.popup.text = this.$i18n.t('single-person.kurzcheck.popup1.text')
        } else {
          state.popup.show = false
        }
      },
    }
  }

  get actions() {
    return {
      ...super.actions,

      setQuestionData({ commit, dispatch, state }, payload) {
        commit('setQuestionData', payload)
        dispatch('resetDependingQuestions', payload)

        commit('setFinalPopupIfCheckFailed')

        // Return false (stop wizard) if the popup is shown
        return !state.popup.show
      },
    }
  }
}

export default new Store()
