
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('navigation', ['pages']),
    ...mapState('content', ['globalLinks']),

    selectedLinks() {
      return this.pages.slice(0, 3)
    },
    footerNavigation() {
      const columns = []
      this.pages.forEach((page) => {
        if (columns.length < 4) {
          columns.push(this.getNavItems(page))
        } else {
          const columnWithLeastChilds = columns.reduce((prev, current) => {
            if (prev && prev.length < current.length) return prev
            return current
          })
          columnWithLeastChilds.push(...this.getNavItems(page))
        }
      })
      return columns
    },
  },
  methods: {
    getNavItems(page) {
      const items = [
        {
          ...page,
          bold: true,
        },
      ]
      if (page.children) {
        items.push(...page.children)
      }
      return items
    },
  },
}
