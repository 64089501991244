
import { mapState, mapMutations } from 'vuex'

export default {
  components: {},

  props: {},

  data() {
    return {
      openSubmenu: '',
    }
  },

  computed: {
    ...mapState('navigation', ['isOpen', 'pages']),
  },
  watch: {
    isOpen(newVal, oldVal) {
      this.openSubnav()
    },
  },
  methods: {
    ...mapMutations('navigation', ['toggleNav', 'closeNav']),

    openSubnav() {
      if (this.isOpen === false) {
        this.openSubmenu = null
      } else {
        const currentPage = this.pages.find(
          (page) =>
            page.children &&
            page.children.some((child) => child.url === this.$route.path)
        )

        if (currentPage) {
          this.openSubmenu = this.pages.indexOf(currentPage)
        }
      }
    },
  },
}
