
import snarkdown from 'snarkdown'

export default {
  props: {
    label: {
      type: String,
      default: null,
    },
    rules: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    border: {
      type: Boolean,
      default: false,
    },
    noSpacingBottom: {
      type: Boolean,
      default: false,
    },
    instructions: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      isVisible: false,
    }
  },

  mounted() {
    this.targetBlankAttribute()
  },

  methods: {
    snarkdown,

    async toggleVisibility() {
      this.isVisible = !this.isVisible

      if (this.isVisible) {
        await this.targetBlankAttribute()
      }
    },

    async targetBlankAttribute() {
      await this.$nextTick()
      const links = this.$el.querySelectorAll('a')

      links.forEach((link) => {
        link.setAttribute('target', '_blank')
      })
    },
  },
}
