import { render, staticRenderFns } from "./SiteNavigation.vue?vue&type=template&id=2dd291d5&scoped=true&"
import script from "./SiteNavigation.vue?vue&type=script&lang=js&"
export * from "./SiteNavigation.vue?vue&type=script&lang=js&"
import style0 from "./SiteNavigation.vue?vue&type=style&index=0&id=2dd291d5&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2dd291d5",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LogoButton: require('/vercel/path0/components/ui/LogoButton.vue').default,QuickLinks: require('/vercel/path0/components/navigation/QuickLinks.vue').default,BurgerButton: require('/vercel/path0/components/navigation/BurgerButton.vue').default,Container: require('/vercel/path0/components/ui/Container.vue').default,MobileNavBar: require('/vercel/path0/components/navigation/MobileNavBar.vue').default,NavBar: require('/vercel/path0/components/navigation/NavBar.vue').default})
