// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/svg/cloud.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/images/png/cloud.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".logo-button[data-v-29518fcb]:after{content:\"\";top:50%;left:50%;transform:translate(-50%,-50%);width:15rem;height:10.75rem;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");transition:all .6s ease;display:block;position:absolute;z-index:40;background-position:50%;background-repeat:no-repeat;background-size:contain;opacity:0}@media (min-width:1024px){.logo-button[data-v-29518fcb]:after{width:22.5rem;height:14rem}}.logo-button.is-not-discrete[data-v-29518fcb]:after{opacity:1}@media (-ms-high-contrast:active),(-ms-high-contrast:none){.logo-button.is-not-discrete[data-v-29518fcb]:after{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")!important}.ie-logo[data-v-29518fcb]{height:65px}}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
