import { render, staticRenderFns } from "./NewsletterPopup.vue?vue&type=template&id=6a10d27c&scoped=true&"
import script from "./NewsletterPopup.vue?vue&type=script&lang=js&"
export * from "./NewsletterPopup.vue?vue&type=script&lang=js&"
import style0 from "./NewsletterPopup.vue?vue&type=style&index=0&id=6a10d27c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a10d27c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Close: require('/vercel/path0/components/icons/Close.vue').default,TextField: require('/vercel/path0/components/ui/forms/TextField.vue').default,SelectField: require('/vercel/path0/components/ui/forms/SelectField.vue').default,FormBox: require('/vercel/path0/components/ui/FormBox.vue').default,PaperPlane: require('/vercel/path0/components/icons/PaperPlane.vue').default,ModalLayout: require('/vercel/path0/components/ui/modal/ModalLayout.vue').default,ModalBackdrop: require('/vercel/path0/components/ui/modal/ModalBackdrop.vue').default})
